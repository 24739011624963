import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {Link} from 'react-router-dom';
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {Box, FormControl, FormControlLabel, FormLabel, Modal, Radio, RadioGroup, Typography,} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {cpf} from "cpf-cnpj-validator";

import Title from '../Title/Title';
import TextFieldTrainMe from '../TextField/TextField';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import CustomInput from '../../Pages/SignUp/Components/CustomInput/CustomInput';

import {GetStudent, PostStudentManual} from "../../Services/Students/index";

import './ModalAddStudent.scss';

const phoneRegExp = /^\(\d{2}\) \d{4,5}-\d{4}$/gi;
const schema = yup.object().shape({
    name: yup.string().required("Nome é obrigatório!").nullable(),
    birthDate: yup.string()
        .required("O campo de data de nascimento é obrigatório!")
        .nullable()
        .test('birthDate', 'Data inválida', (value) => {
            var dateParts = value.split("/");
            if (Number(dateParts[0]) > 31) return false;
            if (Number(dateParts[1]) > 12) return false;
            if (Number(dateParts[2]) > new Date().getFullYear() || Number(dateParts[2]) < new Date().getFullYear() - 100) return false;
            return true;
        }),
    phone: yup.string()
        .required("O campo de telefone é obrigatório!")
        .matches(phoneRegExp, 'Celular inválido, formato correto: (11) 12345-6789'),
    cpf: yup.string()
        .max(14, 'Número máximo de digitos atingido')
        .nullable(),
    email: yup.string()
        .required("O campo de email é obrigatório!")
        .email('Formato de email incorreto!'),
});

const ModalAddStudent = ({open, handleModal, onSubmit}) => {
    const [, setOpenModal] = useState(open);
    const [student, setStudent] = useState(null);
    const [gender, setGender] = useState('M');
    const [error, setErr] = useState(false);
    const [msg, setMsg] = useState('');

    const {control, handleSubmit, formState: {errors}, reset, setValue, getValues, register, setError} = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: '',
            email: '',
            phone: '',
            cpf: '',
            birthDate: '',
            protocol: '',
        },
    });

    const handleCloseModal = () => {
        reset();
        setStudent(null);
        setOpenModal(false);
        handleModal(false);
        setErr(false);
        setMsg('');
    };

    const onSubmitHandler = async (data) => {
        const isCpfValid = data.cpf !== '' ? cpf.isValid(data.cpf.replaceAll('.', '').replaceAll('-', '')) : true;

        if (!isCpfValid) {
            setError('cpf', {message: 'CPF Inválido.'});
            return;
        }

        const dateParts = data.birthDate.split("/");
        const birth = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
        const consultant = JSON.parse(sessionStorage.getItem('consultant'));

        const payload = {
            name: data.name,
            email: data.email,
            phone: data.phone,
            gender: gender,
            document: data.cpf.replaceAll('.', '').replaceAll('-', ''),
            birthDate: birth,
            id: consultant.id,
            newUser: student === null
        };
        try {
            const response = await PostStudentManual(payload);
            onSubmit(student?.id, response);
            handleCloseModal(true);
            reset();
        } catch (ex) {
            if (ex && ex.error) {
                let message = 'Campos com erros na validação.';
                if (typeof ex.error === 'string') {
                    message = ex.error;
                }
                setMsg(message);
            } else {
                setErr(true);
            }
        }
    };

    const getActiveStudents = async (email) => {
        const response = await GetStudent(email);

        if (response.data && response.status) {
            setStudent(response.data);
            setValue('name', response.data.name);
            setValue('email', response.data.email);
            setValue('phone', response.data.phone);
            setValue('cpf', response.data.document);
            setGender(response.data.gender);
            const date = new Date(response.data.birthDate);
            const day = String(date.getUTCDate()).padStart(2, '0');
            const month = String(date.getUTCMonth() + 1).padStart(2, '0');
            const year = date.getUTCFullYear();
            const formattedDate = `${day}/${month}/${year}`;
            setValue('birthDate', formattedDate);
        } else {
            setStudent(null);
            setValue('phone', '');
            setValue('cpf', '');
            setValue('birthDate', '');
            setValue('name', '');
            setGender('M');
        }
    };

    return (
        <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="Adicionar aluno"
            aria-describedby="Adicionar aluno"
        >
            <div className='form-add-student'>
                <div className='titles'>
                    <div>
                        <div className='div-image'>
                            <PersonAddIcon/>
                        </div>
                        <div className='div-title'>
                            <Title title="Adicionar novo aluno"/>
                            <p>Insira as informações abaixo </p>
                        </div>
                    </div>

                    <img src={process.env.REACT_APP_PUBLIC_URL + "icon-close.svg"} alt={"icon-close"}
                         onClick={handleCloseModal}/>
                </div>

                {error && <Box>
                    <Typography>Você já atingiu o limite de alunos com protocolos ativos no seu plano.</Typography>
                    <Link to="/upgrade" variant="body2">
                        Clique aqui para alterar seu plano
                    </Link>
                </Box>}
                {!error && <form className='modal-add-student' id='modal-add-student'>
                    <Box>
                        <div className='new-quiz'>
                            <div className='input'>
                                <TextFieldTrainMe
                                    control={control}
                                    defaultValue=""
                                    name="email"
                                    label="Email"
                                    fullWidth
                                    onBlur={(e) => getActiveStudents(e.target.value)}
                                    errors={errors.email}
                                />
                            </div>
                        </div>
                        <div className='new-quiz'>
                            <div className='input'>
                                <TextFieldTrainMe
                                    control={control}
                                    defaultValue=""
                                    name="name"
                                    label="Nome Completo"
                                    fullWidth
                                    disabled={student !== null}
                                    errors={errors.name}
                                />
                            </div>
                        </div>
                        <div className='new-quiz'>
                            <div className='input'>
                                <CustomInput
                                    label="Celular"
                                    name="phone"
                                    mask="(00) 00000-0000"
                                    errors={errors.phone}
                                    register={register}
                                    disabled={student !== null}
                                    value={student !== null ? student.phone : undefined}
                                    onChange={(e) => setValue('phone', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='new-quiz'>
                            <div className='input'>
                                <CustomInput
                                    label="Data de nascimento"
                                    name="birthDate"
                                    mask="00/00/0000"
                                    errors={errors.birthDate}
                                    register={register}
                                    disabled={student !== null}
                                    value={student !== null ? getValues('birthDate') : undefined}
                                    onChange={(e) => setValue('birthDate', e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='new-quiz'>
                            <div className='input'>
                                <CustomInput
                                    label="CPF (opcional)"
                                    name="cpf"
                                    mask="000.000.000-00"
                                    errors={errors.cpf}
                                    register={register}
                                    disabled={student !== null}
                                    value={student !== null ? student.document : undefined}
                                    onChange={(e) => setValue('cpf', e.target.value)}
                                />
                            </div>
                        </div>
                        <Box sx={{mx: 3, mt: 1}}>
                            <FormControl>
                                <FormLabel id="radio-buttons-group-label">
                                    <Typography component="h3" variant="subtitle1" color="#000" fontWeight="600"
                                                sx={{mb: 1}}>
                                        Gênero
                                    </Typography>
                                </FormLabel>
                                <RadioGroup
                                    aria-labelledby="radio-buttons-group-label"
                                    defaultValue="M"
                                    name="radio-buttons-group"
                                    value={gender}
                                    sx={{display: 'flex', flexDirection: 'row'}}
                                    onChange={(e) => setGender(e.target.value)}
                                >
                                    <FormControlLabel value="M" control={<Radio disabled={student !== null}/>}
                                                      label="Masculino"/>
                                    <FormControlLabel value="F" control={<Radio disabled={student !== null}/>}
                                                      label="Feminino"/>
                                </RadioGroup>
                            </FormControl>
                        </Box>

                        {msg !== '' && (<>
                            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', mx: 2}}>
                                <Typography color="red">{msg}</Typography>
                            </Box>
                        </>)}
                        <Box sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <PrimaryButton text="Adicionar aluno" onClick={handleSubmit(onSubmitHandler)}/>
                        </Box>
                    </Box>
                </form>}
            </div>
        </Modal>
    );
};

export default ModalAddStudent;