import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

import Subtitle from '../../../../Components/Subtitle/Subtitle';
import Title from '../../../../Components/Title/Title';
import TextFieldTrainMe from '../../../../Components/TextField/TextField';
import PrimaryButton from '../../../../Components/PrimaryButton/PrimaryButton';
import Feedback from '../../../../Components/Feedback/Feedback';

import {UpdateQuestionnaire} from "../../../../Services/Questionnares/index";

import './ModalEditNameForm.scss';

const schema = yup.object().shape({
    questionnaireName: yup.string().required("O campo nome do questionário é obrigatório!"),
});

const ModalEditNameForm = ({handleModal, questionnaire, setQuestionnaire}) => {
    const {control, setValue, handleSubmit, formState: {errors}, reset} = useForm({
        resolver: yupResolver(schema)
    });
    const [success, setSucess] = useState(false);
    const access_token = localStorage.getItem('access_token');

    const verifyElementClicked = (e) => {
        if (e.target.id === 'modal-edit-name-container') {
            handleCloseModal();
        }
    };

    const handleCloseModal = () => {
        handleModal();
    };

    const onSubmitHandler = async (data) => {
        await UpdateQuestionnaire(access_token, questionnaire.id, data.questionnaireName);
        setQuestionnaire({...questionnaire, questionnaireName: data.questionnaireName});

        setSucess(true);
        setTimeout(() => {
            setSucess(false);
            handleCloseModal();
            reset();
        }, 2500);
    };

    useEffect(() => {
        if (questionnaire) {
            setValue('questionnaireName', questionnaire.questionnaireName);
        }
    }, [setValue, questionnaire]);

    return (
        <div className='modal-add-container' id='modal-edit-name-container' onClick={e => verifyElementClicked(e)}>
            <form className='modal-add' id='form'>
                <div className='titles'>
                    <div>
                        <div className='div-image'>
                            <img src={process.env.REACT_APP_PUBLIC_URL + "icon-modal-quiz.svg"} alt="icon-modal-quiz"/>
                        </div>
                        <div className='div-title'>
                            <Title title={'Editar nome do formulário'}/>
                            <p>Insira o novo nome do questionário</p>
                        </div>
                    </div>

                    <img src={process.env.REACT_APP_PUBLIC_URL + "icon-close.svg"} alt={"icon-close"}
                         onClick={handleCloseModal}/>
                </div>

                {success && (
                    <Feedback severity="success" text="Questionário salvo com sucesso"/>
                )}

                <div className='new-quiz'>
                    <Subtitle subtitle="Nome do questionário:"/>

                    <div className='input'>
                        <TextFieldTrainMe
                            control={control}
                            name={'questionnaireName'}
                            defaultValue={questionnaire.questionnaireName}
                            label={`Questionário de ${questionnaire.questionnaireName}`}
                            fullWidth
                            errors={errors.questionnaireName}
                        />
                    </div>
                </div>

                <div className='button'>
                    <PrimaryButton text="Salvar" onClick={handleSubmit(onSubmitHandler)}/>
                </div>
            </form>
        </div>
    );
};

export default ModalEditNameForm;