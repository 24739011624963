import {createContext} from 'react';

export const ChatContext = createContext(
    {
        totalMessages: 0,
        setTotalMessages: () => null,
        messages: [],
        setChatId: () => null,
        chatId: '',
        setMessages: () => null,
        userInfo: {},
        setUserInfo: () => null,
    });