import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {CommonLoading} from 'react-loadingg';
import {Box, Button} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import PageWrapper from "../../Components/PageWrapper/PageWrapper";
import Header from "../../Components/Header/Header";
import PlanItem from "./components/PlanItem/PlanItem";

import {GetQuestionnares} from "../../Services/Questionnares/index";
import {GetPlans} from '../../Services/Plans/index';
import Grid from '@mui/material/Grid2';

import "./Plans.scss";

const Plans = () => {
    const {id} = JSON.parse(sessionStorage.getItem('consultant'));
    const access_token = localStorage.getItem('access_token');
    const [plans, setPlans] = useState();
    const [loading, setLoading] = useState(true);
    const [questionnaires, setQuestionnaires] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            // Busca todos os protocolos
            let plansList = await GetPlans(access_token);
            setPlans(plansList?.data?.rows);
            
            // Adiciona evento de visualização da lista de planos
            if (plansList?.data?.rows?.length > 0) {
                window.dataLayer.push({
                    'event': 'view_plans_list',
                    'plans_count': plansList.data.rows.length
                });
            }
            
            setLoading(false);
        };

        fetchData();
    }, [access_token]);

    useEffect(() => {
        const fetchData = async () => {
            // Busca todos os questionarios
            let questionnaireList = await GetQuestionnares(access_token, id);
            questionnaireList = questionnaireList?.data?.rows;
            setQuestionnaires(questionnaireList || []);
        };
        fetchData();
    }, [access_token, id]);

    return (
        <div className="plans">
            <PageWrapper menuItem="/plans">
                <div className="content">
                    <Header
                        title="Consultoria"
                        subtitle="Automatize seu processo de vendas com consultorias"
                    />

                    <div className="plans-container">
                        {!loading && plans?.length >
                            0 && (
                                <><Grid container>
                                    <Grid item xs={12}>
                                        <Link to="/plans/edit" style={{textDecoration: "none"}} variant="body2">
                                            <Button
                                                className="new-student-btn"
                                                aria-label="Novo aluno"
                                                startIcon={<AddCircleOutlineIcon/>}
                                                sx={{
                                                    backgroundColor: '#553872',
                                                    color: 'white',
                                                    boxShadow: '2px 4px 12px rgba(0, 0, 0, 0.2)',
                                                    borderRadius: 2,
                                                    width: 250,
                                                    height: 40,
                                                    ":hover": {backgroundColor: '#553872'}
                                                }}
                                            >
                                                <Typography sx={{
                                                    color: 'white',
                                                    fontWeight: '700',
                                                    fontSize: 14,
                                                }}>Nova consultoria</Typography>
                                            </Button>
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12} sx={{marginBottom: "30px"}}>&nbsp;</Grid>
                                </Grid>
                                </>
                            )}
                        {!loading && plans && plans?.length === 0 && (
                            <Stack className="form-marketplace">
                                <Stack direction="row" spacing={1} p={3} alignItems="center" justifyContent="center">
                                    <Stack direction="column" spacing={1} alignItems="center">
                                        <Typography variant="h3">Você ainda não possui consultorias.</Typography>

                                        <Typography variant="subtitle2">As consultorias são os seus produtos, onde você
                                            irá definir o valor, tempo e outros detalhes, como um "pacote".</Typography>
                                        <Typography variant="subtitle2">Você poderá automatizar o seu processo de vendas
                                            com as consultorias, gerando um link de pagamento genêrico, que pode ser
                                            enviado para possiveis clientes.</Typography>
                                        <Typography variant="subtitle2">Vale lembrar que você precisa de um protocolo
                                            para cadastrar o aluno manualmente também, pois é onde será definido o
                                            preço.</Typography>

                                        <Link to="/assistente-protocolos" style={{textDecoration: "none"}}
                                              variant="body2">
                                            <Button
                                                aria-label="Novo Protocolo"
                                                startIcon={<AddCircleOutlineIcon/>}
                                                sx={{
                                                    backgroundColor: '#553872',
                                                    color: 'white',
                                                    boxShadow: '2px 4px 12px rgba(0, 0, 0, 0.2)',
                                                    borderRadius: 2,
                                                    paddingX: 2,
                                                    ":hover": {backgroundColor: '#553872'}
                                                }}
                                            >
                                                <Typography sx={{
                                                    color: 'white',
                                                    fontWeight: '600',
                                                    fontSize: 14,
                                                }}>Nova consultoria</Typography>
                                            </Button>
                                        </Link>
                                        {
                                            questionnaires.length === 0 && (
                                                <Typography variant="subtitle2">
                                                    <Link to="/questionnaire" variant="body2">
                                                        Você ainda não possui questionários.
                                                        Clique aqui para cadastrar.
                                                    </Link>
                                                </Typography>
                                            )
                                        }
                                    </Stack>
                                </Stack>
                            </Stack>
                        )}
                        {loading && (
                            <CommonLoading color="#553872" size="large"/>
                        )}
                        {!loading && (
                            <Box sx={{border: '1px solid #ccc'}}>
                                {plans.map((plan, index) => (
                                    <Box key={index} sx={{backgroundColor: index % 2 === 0 ? '#FFF' : ''}}>
                                        <PlanItem plan={plan} setPlans={setPlans}/>
                                    </Box>
                                ))}
                            </Box>
                        )}
                    </div>
                </div>
            </PageWrapper>
        </div>
    );
};

export default Plans;
