// src/DraggableItem.js
import React from 'react';
import {useDrag} from 'react-dnd';

const DraggableItem = ({item, children, context}) => {
    const [{isDragging}, drag] = useDrag(() => ({
        type: 'ITEM',
        item: {context, item},
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    return (
        <div
            ref={drag}
            style={{
                opacity: isDragging ? 0.5 : 1,
                cursor: 'move',
            }}
        >
            {children}
        </div>
    );
};

export default DraggableItem;
