import React, {useEffect, useState} from 'react';
import {initMercadoPago} from '@mercadopago/sdk-react';
import InputMask from 'react-input-mask';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    FormControl,
    Input,
    InputLabel,
    Typography
} from '@mui/material';

import {PostPreApproval} from '../../../../Services/Payments';

import './CreditCardPayment.scss';

const CreditCardPayment = ({setCurrentStep, plan, planName, method}) => {
    const consultant = JSON.parse(sessionStorage.getItem('consultant'));

    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [validDate, setValidDate] = useState();
    const [cpf, setCpf] = useState(consultant.document ?? '');

    useEffect(() => {
        const loadMercadoPago = async () => {
            setError(null);
            const publicKey = process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY;
            initMercadoPago(publicKey);
            const mp = new window.MercadoPago(publicKey, {
                locale: 'pt-BR',
            });

            const form = mp.cardForm({
                amount: method === 'M' ? plan.monthlyValue : plan.anualValue,
                autoMount: true,
                form: {
                    id: 'form-checkout',
                    cardholderName: {
                        id: 'form-checkout__cardholderName',
                        placeholder: 'Nome no cartão',
                    },
                    cardholderEmail: {
                        id: 'form-checkout__cardholderEmail',
                        placeholder: 'E-mail',
                    },
                    cardNumber: {
                        id: 'form-checkout__cardNumber',
                        placeholder: 'Número do cartão',
                    },
                    cardExpirationDate: {
                        id: 'form-checkout__cardExpirationDate',
                        placeholder: 'MM/YY',
                    },
                    securityCode: {
                        id: 'form-checkout__securityCode',
                        placeholder: 'CVV',
                    },
                    installments: {
                        id: 'form-checkout__installments',
                        placeholder: 'Parcelas',
                    },
                    identificationType: {
                        id: 'form-checkout__identificationType',
                    },
                    identificationNumber: {
                        id: 'form-checkout__identificationNumber',
                        placeholder: 'CPF',
                    },
                    issuer: {
                        id: 'form-checkout__issuer',
                    },
                },
                callbacks: {
                    onFormMounted: (error) => {
                        if (error) {
                            console.warn('Form Mounted handling error: ', error);
                        } else {
                            window.dataLayer.push({
                                'event': 'payment_form_loaded',
                                'plan_name': planName,
                                'plan_price': method === 'M' ? plan.monthlyValue : plan.anualValue,
                                'plan_period': method === 'M' ? 'monthly' : 'yearly'
                            });
                        }
                    },
                    onSubmit: async (event) => {
                        event.preventDefault();
                        setProcessing(true);
                        setError(null);

                        window.dataLayer.push({
                            'event': 'payment_initiated',
                            'plan_name': planName,
                            'plan_price': method === 'M' ? plan.monthlyValue : plan.anualValue,
                            'plan_period': method === 'M' ? 'monthly' : 'yearly'
                        });

                        const {
                            paymentMethodId,
                            issuerId,
                            cardholderEmail,
                            amount,
                            token,
                            installments,
                            identificationNumber,
                            identificationType
                        }
                            = form.getCardFormData();

                        // Validações dos campos
                        if (!paymentMethodId || !issuerId || !cardholderEmail || !amount || !token || !installments || !identificationNumber || !identificationType) {
                            setError('Por favor, preencha todos os campos corretamente.');
                            setProcessing(false);
                            return;
                        }

                        try {
                            const payload = {
                                token,
                                coachPlanId: plan.id,
                                consultantId: consultant.id,
                                chargeMethod: method,
                                issuerId: issuerId,
                                paymentMethodId: paymentMethodId,
                                transactionAmount: Number(amount),
                                installments: Number(installments),
                                payer: {
                                    email: cardholderEmail,
                                    docType: 'CPF',
                                    docNumber: identificationNumber,
                                },
                            };
                            const response = await PostPreApproval(payload);

                            setPaymentStatus(response.data.status);
                            setCurrentStep(3);

                            window.dataLayer.push({
                                'event': 'payment_success',
                                'plan_name': planName,
                                'plan_price': method === 'M' ? plan.monthlyValue : plan.anualValue,
                                'plan_period': method === 'M' ? 'monthly' : 'yearly',
                                'payment_status': response.data.status
                            });

                        } catch (error) {
                            window.dataLayer.push({
                                'event': 'payment_error',
                                'plan_name': planName,
                                'error_message': error.message
                            });
                            setError(error.message);
                        } finally {
                            setProcessing(false);
                        }
                    },
                },
            });
        };
        loadMercadoPago();
    }, [plan, method, setCurrentStep, consultant.id, planName]);

    return (
        <form id="form-checkout">
            <Box sx={{display: 'flex', flexDirection: {xs: 'column', sm: 'row'}, justifyContent: 'center', gap: 3}}>
                <Box
                    sx={{
                        maxWidth: 500,
                        padding: 3,
                        borderRadius: 2,
                        boxShadow: '1px 2px 10px rgba(0, 0, 0, 0.2)',
                    }}
                >
                    {error && <Typography color="error">{error}</Typography>}
                    <Typography variant="h3" sx={{color: '#553872', fontWeight: 600}}>Adicionar método de
                        pagamento</Typography>
                    <Input
                        sx={{display: "none"}}
                        id="form-checkout__cardExpirationDate"
                        label="MM/AAAA"
                        margin="normal"
                        value={validDate}
                    />
                    <FormControl fullWidth margin="normal" sx={{display: "none"}}>
                        <InputLabel htmlFor="form-checkout__cardholderEmail">E-mail</InputLabel>
                        <Input type="email" id="form-checkout__cardholderEmail" placeholder="E-mail"
                               value={consultant.email}/>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel htmlFor="form-checkout__cardNumber">Número do cartão</InputLabel>
                        <InputMask mask="9999 9999 9999 9999" disabled={false} maskChar="">
                            {() => (
                                <Input
                                    type="text"
                                    id="form-checkout__cardNumber"
                                    placeholder="Número do cartão"
                                />
                            )}
                        </InputMask>
                    </FormControl>
                    <Box sx={{display: "flex", flexDirection: "row", gap: 5}}>
                        <FormControl fullWidth margin="normal">
                            <InputLabel htmlFor="form-checkout__cardExpirationDate">MM/AA</InputLabel>
                            <InputMask mask="99/99" disabled={false} maskChar=""
                                       onChange={(e) => setValidDate(e.target.value)}>
                                {() => (
                                    <Input
                                        id="form-checkout__cardExpirationDate"
                                        label="MM/AA"
                                        margin="normal"
                                    />
                                )}
                            </InputMask>
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <InputLabel htmlFor="form-checkout__securityCode">CVV</InputLabel>
                            <InputMask mask="9999" disabled={false} maskChar="">
                                {() => (
                                    <Input
                                        type="text"
                                        id="form-checkout__securityCode"
                                        placeholder="CVV"
                                    />
                                )}
                            </InputMask>
                        </FormControl>
                    </Box>

                    <FormControl fullWidth margin="normal">
                        <InputLabel htmlFor="form-checkout__cardholderName">Nome no cartão</InputLabel>
                        <Input type="text" id="form-checkout__cardholderName" defaultValue={consultant.name}
                               placeholder="Nome no cartão"/>
                    </FormControl>

                    <FormControl fullWidth margin="normal">
                        <InputLabel htmlFor="form-checkout__identificationNumber">CPF</InputLabel>
                        <InputMask
                            value={cpf}
                            onChange={(e) => setCpf(e.target.value)}
                            mask="99999999999"
                            disabled={false}
                            maskChar=""
                        >
                            {() => (
                                <Input
                                    type="text"
                                    id="form-checkout__identificationNumber"
                                    placeholder="CPF"
                                />
                            )}
                        </InputMask>
                    </FormControl>

                    <select id="form-checkout__installments" placeholder="Parcelas" hidden></select>
                    <select id="form-checkout__identificationType" hidden></select>
                    <select id="form-checkout__issuer" hidden></select>


                    {paymentStatus &&
                        <Typography>{`Status do pagamento: ${paymentStatus} - Assim que seu pagamento for aprovado, você receberá um email.`}</Typography>}
                </Box>

                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    boxShadow: '1px 2px 10px rgba(0, 0, 0, 0.2)',
                }}>
                    <Card sx={{
                        maxWidth: {xs: '100%', sm: 300},
                        width: '100%',
                        borderRadius: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}>
                        <CardHeader
                            title={
                                <Typography variant="h6" component="div"
                                            sx={{fontWeight: 'bold', mb: 2, color: '#553872'}}>
                                    Resumo
                                </Typography>}
                            sx={{
                                backgroundColor: 'rgb(249, 249, 251)',
                                borderBottom: '1px solid rgb(218, 223, 234)'
                            }}
                        />
                        <CardContent>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mb: 1}}>
                                <Typography variant="body1">
                                    {planName}
                                </Typography>
                                <Typography variant="body1" sx={{fontWeight: 'bold'}}>
                                    R$ {method === 'M' ? `${plan.monthlyValue}/mês ` : `${plan.anualValue}/ano`}
                                </Typography>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', mb: 2}}>
                                <Typography variant="h6" component="div" sx={{fontWeight: 'bold'}}>
                                    Total
                                </Typography>
                                <Typography variant="h6" component="div" sx={{fontWeight: 'bold'}}>
                                    R$ {method === 'M' ? `${plan.monthlyValue} ` : `${plan.anualValue}`}
                                </Typography>
                            </Box>
                        </CardContent>
                        <CardActions sx={{justifyContent: 'flex-end'}}>
                            <Button
                                fullWidth
                                variant="contained"
                                style={{color: '#FFF', backgroundColor: '#1e306e'}}
                                type="submit"
                                disabled={processing}
                                sx={{mt: 2}}
                            >
                                {processing ? <CircularProgress size={24}/> : 'Confirmar Pagamento'}
                            </Button>
                        </CardActions>
                        <Typography variant="body2" color="text.secondary" align="center" p={0} pb={1}>
                            O pagamento será renovado automaticamente.
                        </Typography>
                    </Card>
                </Box>
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'row', pt: {xs: 1, sm: 5}, pl: {xs: 1, sm: 24}}}>
                <Typography fontSize={14}>
                    Ao continuar você está aceitando os
                    <a
                        style={{textDecoration: 'none'}}
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.coach.trainme.com.br/privacy-police"
                    > termos de serviço</a> e
                    <a
                        style={{textDecoration: 'none'}}
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.coach.trainme.com.br/privacy-police"
                    > politicas de privacidade.</a>
                </Typography>
            </Box>
        </form>
    );
};

export default CreditCardPayment;