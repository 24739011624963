import {DeleteAsync, GetAsync, PostAsync, PutAsync} from "../api";

/**
 * Método responsável por criar um planos
 */
const CreatePlans = (token, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await PostAsync(`/plans`, config, data);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável por editar um plano
 */
const UpdatePlans = (token, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await PutAsync(`/plans`, config, data);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * Método responsável por completar um protocolo
 */
const UpdateCompleteProtocol = (id, completed) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                }
            };
            const response = await PutAsync(`/plans/protocol/${id}/complete`, config, {
                completed
            });
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * Método responsável por listar planos
 */
const GetPlans = (token) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await GetAsync(`/plans?order=createdAt&orderBy=ASC`, config);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável por deletar um plano
 */
const DeletePlans = (token, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await DeleteAsync(`/plans/${id}`, config)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável por deletar um protocolo
 */
const DeleteProtocol = (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                }
            };
            const response = await DeleteAsync(`/plans/protocol/${id}`, config)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável por obter um pagamento de um protocolo
 */
const GetProtocolPayment = (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                }
            };
            const response = await GetAsync(`/plans/protocol/${id}`, config)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};


/**
 * Método responsável por alterar status um pagamento de um protocolo
 */
const PutProtocolPaid = (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                }
            };
            const response = await PutAsync(`/plans/protocol/${id}/paid`, config, {})
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável por obter dados do plano especifico
 */
const GetPlan = (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await GetAsync(`/plans/${id}`);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};


/**
 * Método responsável por obter as imagens dos estudantes
 */
const GetImages = (id, token) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await GetAsync(`/plans/activity/${id}/images`, config);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável por obter as imagens do estudante
 */
const GetEvolution = (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                }
            };
            const response = await GetAsync(`/student/${id}/evolution`, config);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

export {
    GetPlans,
    CreatePlans,
    UpdatePlans,
    DeletePlans,
    GetPlan,
    GetImages,
    GetEvolution,
    DeleteProtocol,
    GetProtocolPayment,
    UpdateCompleteProtocol,
    PutProtocolPaid
};
