import React from 'react';
import {IconButton, InputBase, Paper} from "@mui/material";

import Divider from "@mui/material/Divider";
import SendIcon from "@mui/icons-material/Send";

import "./InputMessage.scss";

const InputMessage = ({setMessage, message, handleCreateMessage}) => {
    return (
        <div className="input-message">
            <Paper
                sx={{
                    p: "12px 14px",
                    display: "flex",
                    justifySelf: "flex-end",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                <InputBase
                    sx={{ml: 1, flex: 1, width: "100%"}}
                    onChange={(event) => setMessage(event.target.value)}
                    value={message}
                    placeholder="Mensagem"
                    maxRows={3}
                    multiline
                    inputProps={{'maxLength': 500}}
                />
                <Divider sx={{height: 28, m: 0.5}} orientation="vertical"/>
                <IconButton
                    onClick={handleCreateMessage}
                    color="primary"
                    sx={{p: "10px"}}
                >
                    <SendIcon/>
                </IconButton>
            </Paper>
        </div>
    );
};

export default InputMessage;
