import React, { useEffect, useMemo, useState } from 'react';
import IntlCurrencyInput from "react-intl-currency-input"
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import * as yup from "yup";

import { CreatePlans } from '../../../../Services/Plans/index';
import { GetQuestionnares } from "../../../../Services/Questionnares/index";

const schema = yup.object().shape({
    name: yup.string().required("O campo nome é obrigatório!"),
    price: yup.string().required("O campo preço é obrigatório!"),
    description: yup.string().required("O campo descrição é obrigatório!"),
});
const PlanCreationWizard = ({ activeStep, steps, handleNext, handleBack, handleFinish }) => {
    const { id } = JSON.parse(sessionStorage.getItem('consultant'));
    const access_token = localStorage.getItem('access_token');
    const currencyConfig = {
        locale: "pt-BR",
        formats: {
            number: {
                BRL: {
                    style: "currency",
                    currency: "BRL",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                },
            },
        },
    };
    const periodData = useMemo(() => [
        { label: "Mensal (4 semanas)", id: 4 },
        { label: "Bimestral (9 semanas)", id: 9 },
        { label: "Trimestral (13 semanas)", id: 13 },
        { label: "Quadrimestral (17 semanas)", id: 17 },
        { label: "Pentamestral (21 semanas)", id: 21 },
        { label: "Semestral (26 semanas)", id: 26 },
        { label: "Anual (51 semanas)", id: 51 }
    ], []);

    const { control, setValue } = useForm({
        resolver: yupResolver(schema),
    });

    const [period, setPeriod] = useState(periodData[0]);
    const [formValues, setFormValues] = useState({
        planName: '',
        description: '',
        duration: '',
        price: '',
        anamnese: ''
    });
    const [selectedOption, setSelectedOption] = useState();
    const [questionnaires, setQuestionnaires] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');
    const [training, setTraining] = useState(false);
    const [diet, setDiet] = useState(false);
    const [inLoco, setInLoco] = useState(false);

    const nextStep = () => {
        setErrorMsg('');
        if (activeStep === 0) {
            if (formValues.planName === '') {
                setErrorMsg('O campo nome é obrigatório.');
                return;
            }
        } else if (activeStep === 1) {
            if (formValues.description === '') {
                setErrorMsg('O campo descrição é obrigatório.');
                return;
            }
        } else if (activeStep === 3) {
            if (formValues.price < 5) {
                setErrorMsg('O valor minimo é de R$ 5,00.');
                return;
            }
        } else if (activeStep === 4) {
            if (!diet && !training && !inLoco) {
                setErrorMsg('É necessário selecionar pelo menos uma atividade.');
                return;
            }
        }
        handleNext();
    };

    const handleChange = (field) => (event) => {
        setFormValues({
            ...formValues,
            [field]: event.target.value,
        });
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
        setValue('quiz', event.target.value);
    };

    const handlePriceChange = (event, value, maskedValue) => {
        setValue('price', value);
        handleChange('price')({ target: { value } });
    };

    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <TextField
                        label="Nome do Plano"
                        fullWidth
                        value={formValues.planName}
                        onChange={handleChange('planName')}
                    />
                );
            case 1:
                return (
                    <TextField
                        name={'description'}
                        label="Descrição"
                        variant="outlined"
                        fullWidth
                        rows={4}
                        onChange={handleChange('description')}
                        placeholder="(Ex: Montarei seu plano de alimentação e treinos baseado em suas necessidades com foco no emagrecimento)"
                        multiline
                    />
                );
            case 2:
                return (
                    <Autocomplete
                        name="weeks"
                        disableClearable
                        options={periodData}
                        renderInput={(params) => <TextField {...params} label="Periodo" />}
                        value={period}
                        onChange={(_, data) => {
                            setPeriod(data);
                            handleChange('duration');
                            return data;
                        }}
                        defaultValue={{ label: "Mensal", id: 30 }}
                    />
                );
            case 3:
                return (
                    <Box sx={{ width: { xs: '100%', sm: '100%' } }}>
                        <div className="price-container">
                            <label
                                data-shrink="true"
                                htmlFor=":rp:"
                                id=":rp:-label"
                            > Valor
                            </label>
                            <div>
                                <IntlCurrencyInput
                                    currency="BRL"
                                    config={currencyConfig}
                                    onChange={handlePriceChange}
                                    defaultValue={formValues.price}
                                />
                                <fieldset>
                                    <legend>
                                        <span>Valor</span>
                                    </legend>
                                </fieldset>
                            </div>
                        </div>
                    </Box>
                );
            case 4:
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <FormControlLabel
                            control={<Checkbox value={training} color="primary" />}
                            value={training}
                            checked={training}
                            onChange={() => setTraining(!training)}
                            label="Treinos"
                        />
                        <FormControlLabel
                            control={<Checkbox value={diet} color="primary" />}
                            value={diet}
                            checked={diet}
                            onChange={() => setDiet(!diet)}
                            label="Dieta"
                        />
                        <FormControlLabel
                            control={<Checkbox value={inLoco} color="primary" />}
                            value={inLoco}
                            checked={inLoco}
                            onChange={() => setInLoco(!inLoco)}
                            label="Treino Presencial"
                        />
                    </Box>
                );
            case 5:
                return (
                    <Box>
                        <Box sx={{ width: { xs: '100%', sm: '100%' } }}>
                            <Stack>
                                <FormControl variant="outlined">
                                    <Select
                                        control={control}
                                        name="quiz"
                                        label="quiz"
                                        labelId="select-label"
                                        value={selectedOption}
                                        onChange={handleSelectChange}
                                        autoWidth
                                    >
                                        {questionnaires.map((option, index) => (
                                            <MenuItem key={index} value={option.id}>{option.name}</MenuItem>
                                        ))}
                                    </Select>
                                    <InputLabel id="select-label">Questionnário</InputLabel>
                                </FormControl>
                            </Stack>
                        </Box>
                        {questionnaires.length === 1 &&
                            <Typography variant='caption'>Encontramos apenas um questionário cadastrado, mas não se
                                preocupe, você pode editar esses dados depois.</Typography>}
                    </Box>
                );
            default:
                return null;
        }
    };

    const insertPlan = async () => {
        const dataInsert = {
            name: formValues.planName,
            price: Number(formValues.price),
            weeks: period.id,
            description: formValues.description,
            available: true,
            training: training,
            diet: diet,
            inLoco: inLoco,
            quiz: selectedOption || null
        };
        
        await CreatePlans(access_token, dataInsert);
        
        // GTM - Create Plan Success
        window.dataLayer.push({
            'event': 'create_plan_success',
            'plan_name': formValues.planName,
            'plan_price': formValues.price,
            'plan_duration': period.id,
            'has_training': training,
            'has_diet': diet,
            'has_in_loco': inLoco
        });
        
        handleFinish();
    };

    useEffect(() => {
        const fetchData = async () => {
            // Busca todos os questionarios
            let questionnaireList = await GetQuestionnares(access_token, id);
            questionnaireList = questionnaireList?.data?.rows;
            setQuestionnaires(questionnaireList || []);
            if (questionnaireList.length > 0) {
                setSelectedOption(questionnaireList[0].id);
            }
        };
        fetchData();
    }, [access_token, id]);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                bgcolor: 'background.default',
                color: 'text.primary',
                p: 3,
                pt: 0,
                boxSizing: 'border-box'
            }}
        >
            <Box sx={{ mt: 4, width: '100%', maxWidth: 600 }}>

                <Typography variant="h4" gutterBottom sx={{ mb: 4, textAlign: 'center' }}>
                    {steps[activeStep].label}
                </Typography>
                <Box>
                    {renderStepContent(activeStep)}
                    {errorMsg && <p className='error'>{errorMsg}</p>}

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
                        <Button disabled={activeStep === 0} onClick={handleBack}>
                            Voltar
                        </Button>
                        {activeStep === steps.length - 1 ? (
                            <Button variant="contained" color="primary" onClick={insertPlan}>
                                Finalizar
                            </Button>
                        ) : (
                            <Button variant="contained" color="primary" onClick={nextStep}>
                                Próximo
                            </Button>
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default PlanCreationWizard;
