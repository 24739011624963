import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Modal } from '@mui/material';
import Schema from '@mui/icons-material/Schema';

import Title from '../../../../Components/Title/Title';
import TextFieldTrainMe from '../../../../Components/TextField/TextField';
import PrimaryButton from '../../../../Components/PrimaryButton/PrimaryButton';

import { CreatePresets } from "../../../../Services/Presets/index";

import './ModalAddPreset.scss';

const schema = yup.object().shape({
    name: yup.string().required("O campo nome do preset é obrigatório!")
});

const ModalAddPreset = ({ handleModal, presets, setPresets, reloadPresets, open, setModal }) => {
    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });
    const [loading, setLoading] = useState(false);

    const onSubmitHandler = async (data) => {
        setLoading(true);
        await createPreset(data.name);
        handleCloseModal();
        reset();
        setLoading(false);
    };

    const createPreset = async (name) => {
        await CreatePresets(name);
        const payload = {
            name: name
        };
        const newList = [...presets, payload];
        setPresets(newList);
        
        // GTM - Create Preset Success
        window.dataLayer.push({
            'event': 'create_preset_success',
            'preset_name': name
        });
        
        reloadPresets();
    };

    const handleCloseModal = () => {
        // GTM - Close Preset Modal
        window.dataLayer.push({
            'event': 'close_preset_modal'
        });
        
        handleModal();
    };

    return (
        <Modal
            open={open}
            onClose={() => setModal(false)}
            aria-labelledby="Adicionar preset"
            aria-describedby="Adicionar preset"
        >
            <form className='modal-add-preset' id='form'>
                <div className='titles'>
                    <div>
                        <div className='div-image'>
                            <Schema />
                        </div>
                        <div className='div-title'>
                            <Title title={'Criar nova periodização'} />
                            <p>Insira o nome da periodização</p>
                        </div>
                    </div>

                    <img src={process.env.REACT_APP_PUBLIC_URL + "icon-close.svg"} alt={"icon-close"} onClick={handleCloseModal} />
                </div>

                <div className='new-preset'>
                    <div className='input'>
                        <TextFieldTrainMe
                            control={control}
                            name="name"
                            label="Nome (Ex: Homem 35-50 anos)"
                            fullWidth
                            errors={errors.name}
                        />
                    </div>
                </div>

                <Box className='button' sx={{ display: 'flex', justifyContent: 'center' }}>
                    <PrimaryButton text="Criar periodização" onClick={handleSubmit(onSubmitHandler)} loading={loading} />
                </Box>
            </form>
        </Modal>
    );
};

export default ModalAddPreset;