import React, {useState} from 'react';
import {Box, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography} from '@mui/material';

import CreditCardIcon from '@mui/icons-material/CreditCard';

import PixPayment from '../PixPayment/PixPayment';
import CreditCardPayment from '../CreditCardPayment/CreditCardPayment';

const PaymentSelection = ({
                              personalData,
                              callback,
                              consultantName,
                              planInfo,
                              consultantId,
                              gender,
                              plansActivityId,
                              isMobile
                          }) => {
    const [method, setMethod] = useState('CARD');

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <Box
                    component="form"
                    noValidate
                    sx={{
                        mt: 2,
                        width: {xl: '35%', lg: '50%', xs: '90%'},
                        backgroundColor: '#FFF',
                        p: 3,
                        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1)',
                        borderRadius: '8px',
                    }}
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap={1}
                        mb={2}
                    >
                        <CreditCardIcon/>
                        <Typography fontSize={24}>Forma de Pagamento</Typography>
                    </Stack>
                    <Box>
                        <FormControl>
                            <RadioGroup
                                aria-labelledby="radio-buttons-group-label"
                                defaultValue="CARD"
                                name="radio-buttons-group"
                                value={method}
                                onChange={(e) => setMethod(e.target.value)}
                            >
                                <Box>
                                    <FormControlLabel value="CARD" control={<Radio/>} label="Cartão de crédito"/>
                                    <FormControlLabel value="PIX" control={<Radio/>} label="Pix"/>
                                </Box>
                            </RadioGroup>
                        </FormControl>
                    </Box>

                    {method === 'PIX' && (
                        <PixPayment
                            personalData={personalData}
                            consultantName={consultantName}
                            planInfo={planInfo}
                            consultantId={consultantId}
                            gender={gender}
                            isMobile={isMobile}
                            callback={callback}
                            plansActivityId={plansActivityId}
                        />
                    )}

                    <Box sx={{display: method === 'CARD' ? 'block' : 'none'}}>
                        <CreditCardPayment
                            personalData={personalData}
                            planInfo={planInfo}
                            plansActivityId={plansActivityId}
                            callback={callback}
                            consultantId={consultantId}
                        />
                    </Box>
                </Box>


            </Box>

        </>
    );
};

export default PaymentSelection;