import React, {useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {Box, Container, CssBaseline, Grid, TextField} from '@mui/material';
import {LoadingButton} from '@mui/lab';

import {ResetConsultantPassword} from '../../Services/Auth/index';

import './ResetPassword.scss';

const ResetPassword = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        try {
            setLoading(true);
            await ResetConsultantPassword(data.get('email'));
            navigate(-1);
        } catch {
            setErrorMessage('Email inválido');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container className='reset-password' component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 10,
                    gap: 5
                }}
            >
                <img className='logo' src="/logo-logotipo.png" alt="logo" width="100%"/>
                <Box component="form" onSubmit={handleSubmit} noValidate>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        sx={{mt: 0}}
                    />
                    <LoadingButton
                        type="submit"
                        loading={loading}
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2, backgroundColor: '#1e306e', fontWeight: '600'}}
                    > Gerar nova senha
                    </LoadingButton>
                    {errorMessage && (
                        <p style={{color: 'red'}}>{errorMessage}</p>
                    )}
                    <Grid container>
                        <Grid item xs sx={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <Link to="/" variant="body2">Voltar</Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
};

export default ResetPassword;