import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { CommonLoading } from 'react-loadingg';
import { Stack, Typography, Button, ButtonGroup, Box } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Delete, Edit } from "@mui/icons-material";

import Header from "../../Components/Header/Header";
import PageWrapper from "../../Components/PageWrapper/PageWrapper";
import ModalDelete from "../../Components/ModalDelete/ModalDelete";
import EmptyState from "../../Components/EmptyState/EmptyState";
import ModalAddTraining from "../ManageClientPlan/Components/ModalAddTraining/ModalAddTraining"
import VolumeChart from "../../Components/VolumeChart/VolumeChart";

import { GetTrainingsPreset, RemoveTraining } from "../../Services/Trainings/index";

import "./ManagePresets.scss";

const ManagePresets = () => {
    const access_token = localStorage.getItem('access_token');

    const { id } = useParams();
    const navigate = useNavigate();

    const [workoutList, setWorkoutList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false);
    const [presetId,] = useState(id);
    const [client,] = useState({ plan: { id: null, weeks: 1 }, presetId });
    const [modalDelete, setModalDelete] = useState(false);
    const [modalDeleteTitle, setModalDeleteTitle] = useState("");
    const [modalDeleteSubtitle, setModalDeleteSubtitle] = useState("");
    const [modalDeleteText, setModalDeleteText] = useState("");
    const [modalDeleteId, setModalDeleteId] = useState("");

    const handleModalDelete = () => {
        setModalDelete(!modalDelete);
    };

    const handleDeleteTraining = (id) => {
        RemoveTraining(access_token, id);
        setWorkoutList(workoutList.filter(training => training.id !== id));
        
        // GTM - Delete Training
        window.dataLayer.push({
            'event': 'delete_training',
            'training_id': id,
            'preset_id': presetId
        });
        
        handleModalDelete();
    };

    const createNewTraining = async (updated) => {
        if (updated === true) {
            await fetchDataTraining(presetId);
            
            // GTM - Create Training Success
            window.dataLayer.push({
                'event': 'create_training_success',
                'preset_id': presetId
            });
        }
        setModal(!modal);
    };

    const handleSetTraining = (e, training) => {
        e.stopPropagation();
        setModalDeleteTitle("Excluir treino");
        setModalDeleteSubtitle("Deseja excluir o treino:");
        setModalDeleteText(`Treino ${training.name}`);
        setModalDeleteId(training.id);
        handleModalDelete();
    };

    const fetchDataTraining = async (id) => {
        const response = await GetTrainingsPreset(access_token, id);
        const trainings = response?.data;
        setWorkoutList(trainings);
        setLoading(false);

        // GTM - View Preset Trainings
        window.dataLayer.push({
            'event': 'view_preset_trainings',
            'preset_id': id,
            'trainings_count': trainings?.length || 0
        });
    };

    const navigateTrainingDetails = (training) => {
        const length = training.weeks.length
        navigate('/manage-plan/workout-details', { state: { training: training, client: { plan: { id: null, weeks: length }, presetId }, prevTab: '1' } });
    };

    useEffect(() => {
        fetchDataTraining(presetId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (client &&
        <div className="training-list">
            <PageWrapper menuItem="/preset">
                <div className="content">
                    <Header
                        title="SmartTrainer"
                        subtitle="Crie sua biblioteca de periodizações"
                        showReturn
                    />

                    <div className="list-item">
                        <Button
                            className="new-student-btn"
                            aria-label="Novo aluno"
                            onClick={createNewTraining}
                            startIcon={<AddCircleOutlineIcon />}
                            sx={{
                                backgroundColor: '#553872',
                                color: 'white',
                                boxShadow: '2px 4px 12px rgba(0, 0, 0, 0.2)',
                                borderRadius: 2,
                                width: 250,
                                height: 40,
                                ":hover": { backgroundColor: '#553872' }
                            }}
                        >
                            <Typography sx={{
                                color: 'white',
                                fontWeight: '700',
                                fontSize: 14,
                            }}>Novo treino</Typography>
                        </Button>
                    </div>

                    <Box sx={{
                        display: { xs: 'flex', md: 'grid' },
                        flexDirection: { xs: 'column', md: 'row' },
                        gridTemplateColumns: { xs: '100%', md: '40% 59%' },
                        gap: 2
                    }}>
                        <Box sx={{
                            bgcolor: 'background.paper',
                            borderRadius: 1,
                            boxShadow: '1px 1px 6px rgba(0, 0, 0, 0.2)',
                            p: 2
                        }}>
                            {workoutList.length > 0 && workoutList.map((training, key) => (
                                <Stack
                                    key={key}
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 1 }}
                                    className={`list-item ${key % 2 === 0 && 'background-gray'}`}
                                    onClick={() => !training.restDay ? navigateTrainingDetails(training) : null}
                                >
                                    <div className="item-header">
                                        <p className="title">{training.restDay ? training.name : `Treino ${training.name}`}</p>
                                        <Typography className="subtitle">{`Dia(s): ${training.weeks[0].days.map((d) => d.day)}`}</Typography>
                                    </div>

                                    <div className="actions">
                                        <ButtonGroup variant="outlined">
                                            {!training.restDay &&
                                                <Button component="label" startIcon={<Edit />} size="small" onClick={(e) => navigateTrainingDetails(training)}>
                                                    Editar
                                                </Button>
                                            }
                                            <Button component="label" onClick={(e) => handleSetTraining(e, training)}
                                                startIcon={<Delete />} size="small">
                                                Apagar
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </Stack>
                            ))}
                        </Box>

                        <Box>
                            <VolumeChart
                                client={client}
                                presetId={presetId}
                            />
                        </Box>
                    </Box>

                    {loading && (<CommonLoading color="#553872" size="large" />)}

                    {((!workoutList || workoutList.length === 0) && !loading) && (
                        <EmptyState
                            title="Ainda não há nenhum treino criado!"
                            subtitles={['Clique em criar novo treino e configure o primeiro', 'treino do seu aluno.']}
                        />
                    )}

                    <ModalAddTraining
                        modal={modal}
                        handleModal={createNewTraining}
                        client={client}
                    />

                    {modalDelete && (
                        <ModalDelete
                            handleModal={handleModalDelete}
                            title={modalDeleteTitle}
                            subtitle={modalDeleteSubtitle}
                            text={modalDeleteText}
                            id={modalDeleteId}
                            handleDelete={handleDeleteTraining}
                        />
                    )}
                </div>
            </PageWrapper>
        </div>
    );
}

export default ManagePresets;