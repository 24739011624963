import React from 'react';
import {Box, TextField, Typography} from '@mui/material';

import TextFieldTrainMe from '../../../../Components/TextField/TextField';

const QuestionBox = ({question, control, errorMessage, name, register, type, mask = undefined, onBlur = undefined}) => {
    const boxStyle = {
        width: '100%',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 2,
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    };

    return (
        <Box sx={boxStyle}>
            <Typography component="h3" variant="subtitle1" fontWeight="600" sx={{mb: 1}}>{question}</Typography>
            {!control && (
                <>
                    <TextField
                        name={name}
                        label="Resposta"
                        variant="outlined"
                        type={type}
                        fullWidth
                        onBlur={onBlur}
                        {...register(name, {
                            required: "Esse campo é obrigatório.",
                        })}
                    />
                    {errorMessage && <p className='error'>{errorMessage.message}</p>}
                </>
            )}
            {control && (
                <TextFieldTrainMe
                    control={control}
                    name={name}
                    defaultValue=""
                    label="Resposta"
                    type={type}
                    fullWidth
                    errors={errorMessage}
                    mask={mask}
                    showInput
                    handleKeyDown={handleKeyDown}
                    onBlur={onBlur}
                />
            )}
        </Box>
    );
};

export default QuestionBox;