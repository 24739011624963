import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import Grid from '@mui/material/Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

const PersonalInfo = ({ student, show }) => {
    const breakLine = '%0A';
    const getWhatsappLink = () => {
        const appStore = 'https://apps.apple.com/br/app/train-me/id6482847649';
        const playStore = 'https://play.google.com/store/apps/details?id=com.trainme.app';
        const message = `Olá, ${student.name}!${breakLine}*Seu acesso à TrainMe está liberado!*${breakLine}${breakLine}Tá na hora de mudar o nível dos seus treinos!${breakLine}${breakLine}Para acessar seus treinos siga as instruções abaixo:${breakLine}Instale o APP na loja do seu dispositivo:${breakLine}${breakLine}*IOS:* ${appStore}${breakLine}*Android:* ${playStore}${breakLine}${breakLine}O sistema irá enviar o acesso para o seu e-mail: ${student.email}${student.tempPassword !== null ? `, caso não tenha recebido, segue sua senha provisória: *${student.tempPassword}*` : ''}.${breakLine}${breakLine}_Qualquer dúvida, estou à disposição!_`;

        return `https://wa.me/55${student.phone.replace(/\D/g, '')}?text=${message}`;
    };

    return (
        <>
            <Card sx={{ display: 'flex' }}>
                <CardMedia
                    component="img"
                    sx={{ width: 120 }}
                    image={student.profileImagePath}
                    alt={student.name}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography component="div" variant="h6">
                            {student.name} {student.gender === 'M' && <>
                                <MaleIcon />
                            </>}
                            {student.gender === 'F' && <>
                                <FemaleIcon />
                            </>}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            component="div"
                            sx={{ color: 'text.secondary' }}
                        >
                            {student.email}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <a
                                rel="noreferrer"
                                href={`https://wa.me/55${student.phone.replace(/\D/g, '')}`}
                                target="_blank"
                            >
                                <Typography
                                    variant="subtitle1"
                                    component="a"
                                    sx={{ color: 'text.secondary', cursor: 'pointer' }}
                                >
                                    {student.phone}
                                </Typography>
                            </a>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography
                                variant="subtitle1"
                                component="div"
                                sx={{ color: 'text.secondary' }}
                            >
                                {`Senha provisória: ${student.tempPassword ?? '-'}`}
                            </Typography>
                            <Button
                                variant="outlined"
                                color="success"
                                href={getWhatsappLink()}
                                target="_blank"
                            >
                                <Typography
                                    variant="subtitle1"
                                    sx={{ color: '#25D366', cursor: 'pointer', fontSize: 14 }}
                                >
                                    Enviar Boas Vindas
                                </Typography>
                            </Button>
                        </Box>
                    </CardContent>
                </Box>

            </Card>


            <Grid container>
                {student && show && (
                    <></>

                )}
                {
                    !student && show && (
                        <Typography variant="h3">Você não possui alunos</Typography>
                    )
                }
            </Grid>
        </>
    );
};

export default PersonalInfo;