import React from "react";
import {Box, Typography} from "@mui/material";

const Terms = () => {
    return (
        <Box className="upgrade">
            <Box sx={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "100%"
            }}>
                <Typography variant="h2">TrainMe</Typography>

                <Box sx={{
                    marginLeft: 35,
                    marginRight: 35,
                    margin: 0,
                    padding: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontFamily: 'Inter sans-serif',
                    fontSize: 13
                }}>
                    <div id="container">
                        <div class="privacy_and_terms">
                            <i>Atualizado 14/10/2024</i>

                            <h3>TERMOS DE USO E POLÍTICA DE PRIVACIDADE</h3>

                            <p>
                                TRAIN ME LTDA, pessoa jurídica de direito privado, inscrita sob CNPJ nº
                                57.612.352/0001-15, com sede na AVENIDA BRIGADEIRO FARIA LIMA, 1811, SALA 1119 - Bairro:
                                Jardim Paulistano, SÃO PAULO – SP, CEP 01452001, vem, por meio da presente Política de
                                Privacidade, declarar o seu compromisso com em relação à privacidade e segurança das
                                informações, informando sobre o tratamento de dados pessoais de todos os usuários e
                                terceiros em relacionamento online, por meio de sua plataforma de serviços ou
                                aplicativo. O presente documento foi elaborado em conformidade com a Lei Geral de
                                Proteção de Dados Pessoais (Lei 13.709/18), o Marco Civil da Internet (Lei 12.965/14) e
                                complementa os Termos e Condições Gerais de Uso do site ou aplicativo da TrainMe,
                                podendo ser atualizado periodicamente em decorrência de possíveis atualizações
                                legislativas, razão pela qual é indicado ao usuário ou interessado a consulta desta
                                seção regularmente.
                            </p>

                            <h3>1. DOS DADOS PESSOAIS COLETADOS</h3>

                            <p>
                                1.1 A empresa TRAIN ME, como controladora de dados, coleta dados quando da criação da
                                conta a ser utilizada e durante a prestação de serviços a fim de verificar a
                                autenticidade da identidade do usuário que utiliza o sistema, sendo que estes dados são
                                livremente fornecidos pelo próprio usuário, sendo que o aceite da política de
                                privacidade e termos de uso configura a autorização do usuário para o tratamento dos
                                dados.
                            </p>

                            <p>
                                1.2 Os dados pessoais que a TRAIN ME recolhe são, dentre outros, os dados de uso,
                                correio eletrônico, incluindo mas não limitando-se ao nome completo, CPF, RG, data de
                                nascimento, números de telefones, endereços, dados da conta bancária, além do nome da
                                empresa e CNPJ, em se tratando de pessoa jurídica.
                            </p>

                            <p>
                                1.3 Ao utilizar-se da aplicação TRAIN ME, o usuário se responsabiliza pelos dados
                                pessoais e de terceiros publicados ou compartilhados e confirma que tem o consentimento
                                da parte terceira para fornecer dados para a empresa.
                            </p>

                            <p>
                                1.4 A TRAIN ME não coleta dados sensíveis de seus usuários.
                            </p>

                            <p>
                                1.5 Todos os dados inseridos nas plataformas da TRAIN ME são coletados pela empresa e
                                tratados da mesma forma que os dados dos usuários clientes.
                            </p>

                            <p>
                                1.6 Dados financeiros, como número de cartão de crédito, validade e código de segurança
                                não são armazenados pelo sistema.
                            </p>

                            <p>
                                1.7 Todos os dados pessoais, enviados para plataforma devem ser reais e pertencentes a
                                pessoas reais.
                            </p>

                            <h3>
                                2. OBJETIVOS DA COLETA DOS DADOS PESSOAIS
                            </h3>

                            <p>
                                2.1 O tratamento de dados coletados pela TRAIN ME se dará em plena conformidade com a
                                legislação brasileira de proteção de dados e no estrito propósito de atendimento às
                                seguintes finalidades:
                            </p>

                            <p>
                                a) Identificação dos seus usuários, bem como pelas suas preferências e necessidades, nos
                                termos da Política “Know Your Customer”, cumprindo com a legislação vigente, bem como
                                com as normativas do Banco Central do Brasil;
                            </p>

                            <p>
                                b) Promoção dos serviços oferecidos pela empresa, bem como de novas funcionalidades dos
                                aplicativo e outros desenvolvimentos que possam ser de interesse dos clientes, usuários
                                e seguidores da empresa, além da melhoria dos serviços prestados para realização de
                                acompanhamento de tráfego, identificando grupos de perfis de usuários para fins de
                                orientação publicitária;
                            </p>

                            <p>
                                c) Divulgação de conteúdo relacionado às necessidades dos usuários e finalidade do
                                aplicativo;
                            </p>

                            <p>
                                d) Estudar a interação dos usuários com os meios de comunicação da TRAIN ME, no
                                propósito de desenvolver melhorias e novas formas de relacionamento, como layout de
                                canais de contato, novas ferramentas de disponibilização de conteúdo, avaliação do
                                interesse pelos materiais divulgados, blogpost e outros;
                            </p>

                            <p>
                                e) Estabelecer critérios de identificação do usuário cadastrado e suas atividades na
                                plataforma, sistema, website e redes sociais;
                            </p>

                            <p>
                                f) Evitar a ocorrência de fraudes contra o usuário, terceiros e contra a empresa;
                            </p>

                            <p>
                                g) Possibilitar a execução de funcionalidades do sistema, plataforma, website e redes
                                sociais, atendendo expectativas do usuário;
                            </p>

                            <p>
                                h) Execução das obrigações contratuais estabelecidas pela TRAIN ME em face de usuários
                                clientes, incluindo prestação de serviços de atendimento, suporte, geração de
                                relatórios, hospedagem de dados e outros;
                            </p>

                            <p>
                                i) Aprimorar a experiência de uso e desenvolver melhorias do sistema, plataforma,
                                website e redes sociais. .
                            </p>

                            <h3>3. DA PROTEÇÃO E DO ARMAZENAMENTO DOS DADOS COLETADOS</h3>

                            <p>
                                3.1 A TRAIN ME, objetivando a proteção dos dados dos usuários e de terceiros
                                armazenados, utilizar-se-á de medidas físicas e digitais de segurança, como controles de
                                acessos, criptografia de informações, firewall, auditoria de alterações, entre outros
                                mecanismos de proteção.
                            </p>

                            <p>
                                3.2 Os dados pessoais obtidos pela empresa terão acesso restrito, com estrutura de
                                segurança da informação aplicada a limitar o acesso de cada dado pessoal ao profissional
                                ou setor que efetivamente os necessite para execução de algumas das finalidades
                                declaradas do processamento de dados.
                            </p>

                            <p>
                                3.3 A empresa, mesmo comprometida com as melhores práticas da segurança da informação,
                                não garantirá a impossibilidade de evento danoso decorrente de invasões hacker ou de
                                incidentes de segurança pelo acesso a sites suspeitos e/ou instalação de software
                                malicioso, ou ainda falhas e vícios, salvo se comprovadamente ocasionadas por conduta
                                culposa ou deliberada da própria TRAIN ME, cabendo ao usuário a manutenção de segurança
                                e integridade do dispositivo pelo qual acessa o sistema, o qual deverá manter
                                atualizados os sistemas antivírus e de segurança do sistema operacional.
                            </p>

                            <p>
                                3.4.1 Cabe ao usuário zelar pela confidencialidade de informações relacionadas ao acesso
                                e operação de seus dados no sistema, plataforma e website da TRAIN ME, tais como
                                informações de login, senha, chaves de segurança, duplo fator de autenticação e qualquer
                                outra solução de segurança implementada pela contratada.
                            </p>

                            <h3>4. DO COMPARTILHAMENTO DE DADOS</h3>

                            <p>
                                4.1 Os dados de natureza pessoal do Usuário somente poderão ser compartilhados pela
                                TRAIN ME com terceiros, nas hipóteses de:
                            </p>

                            <p>
                                (a) sucessão empresarial, a exemplo de fusão, aquisição e/ou incorporação;
                            </p>

                            <p>
                                (b) contratação de serviços de processamento de dados junto a terceiros (operadores),
                                tais como hospedagem de dados, sistemas de processamento (serviços de computação em
                                nuvem), consultoria em tecnologia da informação, serviços de telecomunicação, serviços
                                de entrega, serviços de publicidade e marketing, organização de eventos, serviços de
                                faturamento de créditos (financeiros), serviços jurídicos, redes sociais, sites de
                                compras e de pesquisa e anúncios de terceiros.
                            </p>

                            <p>
                                4.2 A TRAIN ME compromete-se a exigir compromisso de confidencialidade e de conformidade
                                com os terceiros que terão acesso aos dados dos usuários, inclusive dos processadores
                                diretos e indiretos dos dados que poderão estar fora do território brasileiro, que será
                                reduzida a termo em instrumento contratual ou em declaração específica com força
                                obrigacional.
                            </p>

                            <p>
                                4.3 Com a ciência e aceite dos termos desta Política de Privacidade, o usuário manifesta
                                seu inequívoco, livre e informado consentimento com a eventual transferência de dados em
                                atenção à ordem emanada por autoridades oficiais de países de fora do território
                                brasileiro. A TRAIN ME esclarece ainda, que o consentimento em questão é dispensável
                                caso o fornecimento das informações às autoridades decorra de exigência legal.
                            </p>

                            <p>
                                4.4 Informa-se ao usuário que a empresa é obrigada por lei a compartilhar dados pessoais
                                dos usuários em caso de exigência emanada de autoridade judicial, policial ou
                                administrativa. A disponibilização destes dados se dará apenas mediante validação da
                                ordem emitida junto aos órgãos competentes.
                            </p>

                            <p>
                                4.5 A TRAIN ME reserva-se ao direito de utilizar todos os dados fornecidos pelos seus
                                usuários dentro da aplicação e do website (incluindo os dos alunos do usuário),
                                respeitando a legislação vigente.
                            </p>

                            <h3>5. DO USO DOS COOKIES</h3>

                            <p>
                                5.1 A TrainMe declara fazer uso de cookies para aprimorar a experiência de seus usuários
                                em suas plataformas e páginas na Internet. Cookies são arquivos enviados pela empresa a
                                cada acesso em seu website e instalados no navegador dos usuários que permitem a
                                identificação do terminal de Internet, autenticar o usuário, personalizar a experiência
                                de navegação e contratação de serviços, registrar acessos e atividades na página e no
                                aplicativo. A TrainMe faz uso de cookies para execução de: (a) autenticação e segurança;
                                (b) oferta de anúncios e conteúdos relacionados a suas atividades; (c) oferta de
                                produtos e serviços; (d) medição de desempenho e estatísticas; (e) anúncios próprio e de
                                terceiros. A empresa assegura que tanto na execução de cookies próprios quanto de
                                terceiros, as melhores práticas de segurança são adotadas para garantir a proteção dos
                                dados pessoais levantados. O uso dos cookies pela TrainMe poderá ser previamente
                                autorizado pelo usuário quando de seu acesso às plataformas e websites online. As
                                autorizações também poderão ser revisadas e modificadas a qualquer tempo pelo usuário. A
                                TrainMe esclarece que o bloqueio dos cookies poderá interferir no pleno uso dos recursos
                                disponibilizados, inclusive inviabilizar o acesso a determinadas funções e ferramentas
                                dos canais e plataformas.
                            </p>

                            <p>
                                5.2 Os cookies poderão ser desligados a qualquer momento pelo usuário, entretanto, a
                                desativação desta ferramenta mudará a forma como o usuário interage com o website e
                                aplicativo, podendo, inclusive, impedir o login do usuário em determinados programas,
                                websites ou fóruns.
                            </p>

                            <h3>
                                6. DOS DIREITOS DO TITULAR
                            </h3>

                            <p>
                                6.1 A TrainMe assegura ao usuário o direito de acesso aos dados pessoais em uso, bem
                                como todos os direitos assegurados na Lei Geral de Proteção de Dados, conforme determina
                                seu artigo 18.
                            </p>

                            <p>
                                6.2 Os dados colhidos serão mantidos pela TrainMe pelo período necessário para a
                                prestação dos serviços e, na hipótese de rescisão do contrato, conforme previsto pela
                                Lei 9.613/98, serão armazenados pelo período de 5 (cinco) anos, extensíveis mediante
                                solicitação do Ministério Público ou autoridades administrativas.
                            </p>

                            <p>
                                6.3 O usuário pode solicitar a destruição dos dados pessoais constantes na TRAIN ME,
                                sendo que para isso deverá observar as diretrizes constantes na Lei 9.613/98, decorrido
                                o prazo de 5 (cinco) anos de armazenamento obrigatório.
                            </p>

                            <h3>7. DO CONSENTIMENTO QUANTO À COLETA DE DADOS</h3>

                            <p>
                                7.1 Ao se cadastrar no sistema da TRAIN ME, concordando com o CONTRATO DE PRESTAÇÃO DE
                                SERVIÇOS E GESTÃO DE PAGAMENTOS, denominado TERMOS DE USO, o usuário estará aceitando,
                                também, a Política de Privacidade estabelecida, que estará disponível para acesso no
                                site da empresa, sendo este: https://coach.trainme.com.br/privacy-police estando ciente
                                que não havendo concordância do usuário em relação à Política adotada, não é possível
                                utilizar-se dos serviços fornecidos pela empresa.
                            </p>

                            <h3>
                                8. RESPONSABILIDADE DO USUÁRIO NA UTILIZAÇÃO
                            </h3>

                            <p>
                                8.1 Ao utilizar a “área de arquivos” ou “campo de texto livre” para prescrever dietas,
                                receitas médicas ou informações referentes à suplementação sem a devida autorização dos
                                órgãos competentes (CRN, CRM, CFM e etc.), o usuário estará sujeito às diretrizes dos
                                respectivos órgãos, não tendo a TRAIN ME qualquer responsabilidade acerca dessas
                                prescrições.
                            </p>

                            <p>
                                8.2 A TRAIN ME não tem qualquer ligação com nenhum órgão regulamentador de qualquer
                                profissão, não tendo obrigação de fornecer voluntariamente nenhum dado àqueles, a não
                                ser quando determinado judicialmente, reservando-se ao direito de fazê-lo quando
                                identificar atos ilícitos por parte do usuário ou seus clientes.
                            </p>

                            <p>
                                8.3 As contas criadas pelos usuários na plataforma são pessoais e intrasferíveis, não
                                podendo ser compartilhadas, transferidas ou vendidas à terceiros. O usuário é
                                inteiramente responsável pela manutenção da confidencialidade de suas informações de
                                login. Havendo suspeita de incidente de segurança, perda, roubo ou divulgação não
                                autorizada dos mesmos, deverá contatar imediatamente o suporte da TRAIN ME.
                            </p>

                            <p>
                                8.4 Poderão ser autorizados até dois aparelhos para login em uma mesma conta, sem ferir
                                o disposto no item antecedente.
                            </p>

                            <h3>
                                9. ASSINATURAS
                            </h3>

                            <p>9.1 Modalidades de Assinatura</p>

                            <p>A TRAIN ME oferece assinaturas mensais que se adaptam às necessidades dos profissionais
                                de saúde e bem-estar. Cada modalidade de assinatura é projetada para fornecer diferentes
                                níveis de acesso e recursos dentro da plataforma.</p>

                            <p>9.2 Teste Gratuito</p>

                            <p>Todos os novos usuários podem começar a usar a Train Me totalmente grátis com até 03
                                alunos cadastrados, podendo conhecer todas as funcionalidades da plataforma sem custo
                                algum. O plano gratuito pode ter limitações de recursos e capacidade em comparação com
                                as assinaturas pagas.</p>

                            <p>9.3 Cancelamento e Reembolso</p>

                            <p>Usuários que desejarem cancelar sua assinatura podem fazê-lo a qualquer momento.</p>

                            <p>9.4 Exclusividade e Segurança</p>

                            <p>As assinaturas que oferecem acesso ilimitado aos alunos são de uso exclusivo do usuário
                                que realizou a contratação. A partilha de credenciais de acesso com terceiros não é
                                permitida, visando manter a integridade e a segurança dos dados na plataforma.</p>

                            <p>9.5 Upgrade de Plano</p>

                            <p>A qualquer momento, você pode evoluir seu plano atual, sendo o Pro o nosso plano mais
                                completo.</p>

                            <p>9.6 Downgrade de Plano</p>

                            <p>Mudar do Plano Pro para o Plano Basic sem complicações, ajustando a quantidade de alunos
                                e recursos disponíveis. Caso esteja no Plano Basic, o sistema não permite voltar para o
                                plano gratuito. Sendo necessário cancelar a conta ou entrar em contato com o suporte
                                TrainMe.</p>

                            <p>9.7 Responsabilidade do usuário</p>

                            <p>É de responsabilidade do usuário gerenciar e salvar os dados dos seus alunos antes de
                                mudar de plano. A Train Me avisa com antecedência, mas não se responsabiliza por dados
                                perdidos após o downgrade.</p>

                            <p>9.8 Cobrança por Aluno ou Percentual de Taxa de Processamento</p>

                            <p>Para continuar oferecendo uma exceção aos profissionais que processam pagamentos
                                externamente, a TRAIN ME possui uma taxa mensal de R$10 por aluno adicional ou uma taxa
                                de processamento de 6,99% sobre os pagamentos processados através da plataforma TrainMe
                                Wallet.</p>

                            <p>9.8.1 Modelo de Cobrança por Aluno</p>

                            <p>Taxa por Aluno: R$10,00/mês<br/>Taxa de Processamento: 0%</p>

                            <p>9.8.2 Modelo de Cobrança por Percentual</p>

                            <p>Taxa por Aluno: R$0,00/mês<br/>Taxa de Processamento: 6,99%</p>

                            <p>9.9 Concordância com as Formas de Cobrança</p>

                            <p>A utilização da plataforma Train Me está condicionada à aceitação dos termos de cobrança
                                estabelecidos. Ao optar por usar a plataforma Train Me, o usuário concorda com as
                                modalidades de cobrança descritas, incluindo a assinatura mensal dos planos da Train Me,
                                juntamente com a taxa mensal por aluno ou a taxa de processamento.</p>

                            <p>9.9.1 Concordância Obrigatória</p>

                            <p>Aceitação dos Termos: Ao continuar a utilizar a plataforma Train Me, o usuário concorda
                                integralmente com as formas de cobrança estabelecidas, seja pela assinatura, assim como
                                pela taxa mensal por aluno adicional de R$ 10,00 ou pela taxa de processamento de 6,99%.
                                A aceitação dos presentes Termos de Uso e Política de Privacidade implica na
                                concordância com o modelo de cobrança descrito.</p>

                            <p>9.9.2 Procedimento de Pagamento e Cancelamento</p>

                            <p>Inserção de Cartão de Crédito: O usuário deverá inserir um cartão de crédito válido na
                                plataforma para que sejam cobradas as taxas relacionadas ao número de alunos e/ou taxa
                                de processamento.</p>

                            <p>Bloqueio por Atraso no Pagamento: Caso ocorra atraso no pagamento por mais de 3 dias, a
                                conta do usuário será automaticamente bloqueada. O desbloqueio será feito após a
                                regularização do pagamento.</p>

                            <p>Cancelamento por Inadimplência: Se o pagamento não for regularizado dentro de 30 dias
                                após o bloqueio, a conta do usuário será definitivamente cancelada e todos os dados
                                serão removidos da plataforma.</p>

                            <p>Notificações de Atraso e Cancelamento: O usuário será notificado por e-mail sobre o
                                atraso no pagamento, o bloqueio da conta e o prazo de 30 dias para regularização antes
                                do cancelamento definitivo.</p>

                            <p>9.10 Cancelamento pela TRAIN ME</p>

                            <p>A TRAIN ME reserva-se o direito de cancelar o plano de qualquer usuário a qualquer
                                momento, a seu exclusivo critério, por motivos que incluem, mas não se limitam a:</p>

                            <p>9.10.1 Violações dos Termos de Uso: Qualquer ação que constitua uma violação dos termos e
                                condições de uso da plataforma.</p>

                            <p>9.10.2 Inadimplência: Atrasos ou falhas no pagamento das taxas de assinatura ou outras
                                taxas aplicáveis.</p>

                            <p>9.10.3 Comportamento Inadequado: Qualquer comportamento considerado ofensivo, abusivo,
                                difamatório, ilegal, ou que possa prejudicar a imagem da empresa ou de seus
                                colaboradores.</p>

                            <p>9.10.4 Motivos Operacionais: Qualquer outra razão que a TRAIN ME considere prejudicial ou
                                desvantajosa para suas operações, negócios ou reputação.</p>

                            <p>9.10.5 Decisão Unilateral e Sem Aviso Prévio: A TRAIN ME pode tomar a decisão de cancelar
                                o plano do usuário sem aviso prévio, caso entenda que a continuidade do uso não faz mais
                                sentido para a empresa. Esta decisão pode ser baseada em critérios internos e não
                                necessita de justificativa para o usuário.</p>

                            <p>9.10.6 Procedimento de Cancelamento:</p>

                            <p>Notificação: O usuário será notificado por e-mail sobre o cancelamento da sua conta.</p>
                            <p>Bloqueio Imediato: O acesso à plataforma será bloqueado imediatamente após a decisão de
                                cancelamento.</p>
                            <p>Preservação de Dados: Os dados dos alunos serão preservados por um período de 30 dias a
                                partir da data de cancelamento, permitindo que o usuário os exporte.</p>
                            <p>Remoção Permanente de Dados: Após o período de 30 dias, todos os dados serão removidos
                                permanentemente da plataforma Train Me.</p>

                            <p>9.10.7 Irreversibilidade: O cancelamento da conta é irreversível e, uma vez decidido, não
                                pode ser contestado ou revertido.</p>

                            <p>9.10.8 Ausência de Indenização: A decisão de cancelar o plano do usuário não enseja
                                nenhum tipo de indenização, compensação ou reembolso, salvo disposição expressa em
                                contrário na legislação aplicável.</p>


                            <h3>
                                10. CARTEIRA TrainMe, CHARGEBACKS E CONTESTAÇÕES DE PAGAMENTO
                            </h3>

                            <p>
                                10.1 O serviço de gestão de pagamentos denominado como CARTEIRA TrainMe não é
                                automaticamente contratado através da contratação dos planos da TRAIN ME. Para tanto,
                                deverá o usuário habilitar tal serviço dentro da plataforma, aceitando os termos de uso
                                e condições próprios deste serviço.
                            </p>

                            <p>
                                10.2 A TRAIN ME não oferecer qualquer tipo de seguro ou garantia em relação à
                                chargebacks ou qualquer outro tipo de operação fraudulenta proveniente de quaisquer
                                partes.
                            </p>

                            <p>
                                10.3 a TRAIN ME reserva-se ao direito de remover o acesso do usuário no caso de
                                identificação de tentativa fraudulenta de chargeback ou qualquer outro tipo de
                                contestação de valores por meio de terceiros (a exemplo de operadoras de cartão de
                                crédito e plataformas de pagamento).
                            </p>

                            <p>
                                10.4 identificada a contestação fraudulenta de valores, o usuário terá bloqueado seu
                                acesso à plataforma e aos aplicativos, podendo liberá-lo após o pagamento integral dos
                                valores, com adição de multa de 30% (trinta por cento), através de boleto bancário.
                            </p>

                            <p>
                                10.5 Em caso de cobrança equivocada, poderá o usuário entrar em contato com o suporte da
                                TRAIN ME para requerer o reembolso dos valores.
                            </p>

                            <p>
                                10.6 É estritamente proibido que os clientes utilizem a CARTEIRA TrainMe para qualquer
                                tipo de fraude financeira ou tentativa. Caso seja identificada a utilização indevida da
                                CARTEIRA TrainMe para fins fraudulentos, a TRAIN ME reserva-se o direito de tomar as
                                medidas necessárias, incluindo a rescisão imediata do acesso do usuário, sem aviso
                                prévio. Além disso, a TRAIN ME cooperará com as autoridades competentes na investigação
                                de tais atividades fraudulentas.
                            </p>

                            <p>
                                10.7 O usuário é responsável por garantir que todas as transações e atividades
                                realizadas por meio da CARTEIRA TrainMe sejam legítimas e em conformidade com as leis e
                                regulamentações aplicáveis. Qualquer violação resultante de atividades fraudulentas ou
                                ilícitas acarretará em ações legais e na rescisão imediata do acesso à plataforma.
                            </p>

                            <p>
                                10.8 A TRAIN ME reserva-se ao direito de intervir em quaisquer negociações entre
                                terceiros e usuários quando houver violação dos termos e condições de uso, regras de
                                oferta, ofensa ao Código de Defesa do Consumidor ou à qualquer legislação vigente
                                aplicada ao caso.
                            </p>

                            <h3>
                                11. INFORMAÇÕES ADICIONAIS
                            </h3>

                            <p>
                                11.1 O usuário da TRAIN ME reconhece e concorda com os termos previstos na presente
                                Política de Privacidade, estando ciente que são aplicáveis as leis brasileiras para
                                reger a relação jurídica em comento, reconhecendo, por fim, que a Política é
                                complementar ao termos de uso disponíveis no site da contratada.
                            </p>

                            <p>
                                11.2 O usuário da plataforma que se cadastrar como educador físico declara, através do
                                formulário de cadastramento, que está registrado no Conselho Nacional de Educação Física
                                (CREF) de sua região, estando sujeito às diretrizes por aquele impostas, bem como às
                                sanções cabíveis, no caso de infringir eventual Lei, ou exercer a profissão de forma
                                ilegal, sendo a TRAIN ME isenta de qualquer responsabilidade em razão dos atos do
                                usuário irregular.
                            </p>

                            <p>
                                11.3 O usuário é responsável por todas as informações que adicionar ao sistema, sendo
                                responsável por eventuais danos causados aos seus alunos em razão de prescrição de
                                treinos, testes ou avaliações físicas (seja pela má execução do aluno ou do
                                profissional).
                            </p>

                            <p>
                                11.4 A TRAIN ME reserva-se ao direito de ofertar produtos ou serviços, próprio ou de
                                terceiros, para todos os usuários da plataforma, sejam eles professores, alunos ou
                                quaisquer outros usuários.
                            </p>

                            <p>
                                11.5 A TRAIN ME possui ligações para outros sites, que podem conter
                                informações/ferramentas úteis para os visitantes. A política de privacidade não é
                                aplicada à websites de terceiros, devendo assim, o usuário ler e concordar com os termos
                                de uso e política de privacidade destes websites, a fim de garantir a sua segurança,
                                visto que a TRAIN ME não se responsabiliza pela política de privacidade e conteúdo
                                destes websites.
                            </p>

                            <p>
                                11.6 Ao clicar em algum anúncio, os dados do usuário serão coletados pelo anunciante,
                                devendo o usuário seguir as instruções do item “8.5”, podendo os anunciantes
                                utilizarem-se do serviço de geotargeting.
                            </p>

                            <p>
                                11.7 O endereço de IP (Internet Protocol) e ISP (Internet Service Provider) serão
                                coletados pela TRAIN ME e por terceiros, na forma do item “8.5”, bem como as informações
                                do browser utilizado para visitação do website, tempo de visita e páginas acessadas
                                através da plataforma, a fim de proporcionar uma melhor experiência aos usuários.
                            </p>

                            <p>
                                11.8 A TRAIN ME reserva-se ao direito de enviar notificações push-up e e-mails para
                                todos os usuários cadastrados em sua base de dados, podendo as notificações terem
                                finalidade transacional, publicitária, de oferta, anúncio, engajamento e outros.
                            </p>

                            <p>
                                11.9 A TRAIN ME não se responsabiliza em relação à entrega ou qualidade da prestação dos
                                serviços e produtos que venham a ser vendidos pelos usuários aos seus clientes, visto
                                que não se encaixa na cadeia de consumo (visto não ser fornecedor), reservando-se ao
                                direito de regresso, a fim de ver-se ressarcido de quaisquer prejuízos que venha a ter
                                em razão de algum ato indevido de seus usuários.
                            </p>

                            <p>
                                11.10. A TRAIN ME ainda se reserva ao direito de encerrar a prestação de serviços ao
                                cliente que não mantiver conduta adequada, violar os termos de uso, faltar com respeito
                                com quaisquer dos colaboradores da empresa ou deixar de efetuar o pagamento das
                                mensalidades nas datas convencionadas, ficando ciente o cliente que tal encerramento não
                                lhe ensejará nenhum tipo de indenização e não se adequa à hipótese de recusa de
                                atendimento, mas de violação dos termos de uso.
                            </p>

                            <p>
                                11.11 Esta política é celebrada por prazo indeterminado, entrando e mantendo-se em vigor
                                a partir da data do cadastro e utilização do sistema até o seu efetivo cancelamento.
                            </p>

                            <p>
                                11.12 Para solicitação de esclarecimentos, dúvidas e solicitações de qualquer natureza
                                relacionadas ao tratamento de dados pessoais e aos termos da presente política de
                                privacidade, a TRAIN ME se coloca à disposição para contato por meio de seus canais de
                                atendimento.
                            </p>

                            <h3>
                                12. NÍVEL DE SERVIÇO
                            </h3>

                            <p>
                                12.1 A TRAIN ME empreenderá esforços comercialmente razoáveis para tornar o aplicativo
                                disponível, no mínimo, 99% (noventa e nove por cento) durante cada ano.
                            </p>

                            <p>
                                12.2 O Compromisso de Nível de Serviço não se aplica às circunstâncias de
                                indisponibilidade que:
                            </p>

                            <p>
                                12.2.1 Resultem de uma interrupção do fornecimento de energia elétrica ou paradas
                                emergenciais não superiores a 2 (duas) horas ou que ocorram no período das 22h00min até
                                às 6h00min do horário de Brasília;
                            </p>

                            <p>
                                12.2.2 Forem causadas por fatores que fujam ao cabível controle da TRAIN ME, inclusive
                                casos de força maior ou de acesso à Internet e problemas correlatos;
                            </p>

                            <p>
                                12.2.3 Resultem de quaisquer atos ou omissões do cliente ou de terceiros;
                            </p>

                            <p>
                                12.2.4 Resultem do Equipamento de TI, Recursos, software ou outras tecnologias que o
                                cliente usar e/ou do Equipamento de TI e Recursos que impeçam o acesso regular ao
                                aplicativo;
                            </p>

                            <p>
                                12.2.5 Resultem de falhas de instâncias individuais não atribuíveis à indisponibilidade
                                do aplicativo.
                            </p>

                            <h3>
                                13. LIMITAÇÃO DE RESPONSABILIDADE E INDENIZAÇÃO
                            </h3>

                            <p>
                                13.1 A TRAIN ME não responderá, em nenhuma hipótese, ainda que em caráter solidário ou
                                subsidiário:
                            </p>

                            <p>
                                13.1.1 Por eventuais prejuízos sofridos pelo cliente em razão da tomada de decisões com
                                base nas informações disponibilizadas no aplicativo.
                            </p>

                            <p>
                                13.1.2 Por eventuais prejuízos sofridos pelo cliente em razão de falhas no sistema de
                                informática ou nos servidores que independam de culpa da TRAIN ME ou em sua
                                conectividade com a internet de modo geral, devendo o cliente manter, às suas expensas,
                                linha de telecomunicação, roteador, software de comunicação, endereço de correio
                                eletrônico e outros recursos necessários à comunicação com a TRAIN ME.
                            </p>

                            <p>
                                13.1.3 Por situações de caso fortuito ou força maior, nos termos do artigo 393 do Código
                                Civil Brasileiro.
                            </p>

                            <p>
                                13.1.4 A TRAIN ME não garante que as funções contidas no aplicativo atendam às suas
                                necessidades, que a operação do aplicativo será ininterrupta ou livre de erros, que
                                qualquer funcionalidade continuará disponível, que os defeitos no aplicativo serão
                                corrigidos ou que o aplicativo será compatível ou funcione com qualquer celular,
                                aparelhos, aplicações ou serviços de terceiros.
                            </p>

                            <p>
                                13.1.5 O cliente concorda em defender, indenizar e manter indene a TRAIN ME, seus
                                diretores, empregados e agentes, de e contra quaisquer encargos, ações ou demandas,
                                incluindo, mas não limitado a honorários advocatícios razoáveis, resultantes: (i) da sua
                                eventual utilização indevida do aplicativo ou (ii) da violação das condições ora
                                pactuadas.
                            </p>

                            <p>
                                13.1.6 Em nenhum caso a TRAIN ME será responsável por danos pessoais ou qualquer
                                prejuízo incidental, especial, indireto ou consequente, lucros cessantes, incluindo, sem
                                limitação, prejuízos por perda de lucro, corrupção ou perda de dados, falha de
                                transmissão ou recepção de dados, não continuidade do negócio ou qualquer outro prejuízo
                                ou perda comercial, decorrentes ou relacionados ao seu uso ou sua inabilidade em usar o
                                aplicativo, por qualquer outro motivo.
                            </p>

                            <p>
                                13.1.7 Na eventualidade da TRAIN ME ser compelida, por decisão judicial transitada em
                                julgado, a indenizar ou ressarcir o cliente por danos sofridos, apesar do disposto
                                acima, o valor devido ao cliente será limitado à totalidade da quantia efetivamente paga
                                pelo cliente à TRAIN ME a título de fruição das funcionalidades oferecidas pelo
                                aplicativo.
                            </p>

                            <h3>14. Encerramento do Acesso</h3>

                            <p>
                                14.1 O acesso do usuário à plataforma está sujeito aos seguintes termos de uso. A
                                violação de qualquer uma dessas regras ou ações que causem prejuízo financeiro ou danos
                                à imagem da marca, empresa ou seus colaboradores podem resultar no encerramento imediato
                                do acesso do usuário, sem aviso prévio.
                            </p>

                            <p>
                                14.2 O usuário concorda em cumprir todas as regras e regulamentos estabelecidos nestes
                                termos de uso. Qualquer violação, incluindo, mas não se limitando a:
                            </p>

                            <p>
                                14.2.1 Uso indevido ou não autorizado da plataforma;
                            </p>

                            <p>
                                14.2.2 Compartilhamento de informações confidenciais obtidas por meio da plataforma;
                            </p>

                            <p>
                                14.2.3 Violação dos direitos de propriedade intelectual da marca ou de terceiros;
                            </p>

                            <p>
                                14.2.4 Comportamento ofensivo, abusivo, difamatório ou ilegal na plataforma;
                            </p>

                            <p>
                                14.2.5 Qualquer ação que cause prejuízo financeiro à marca, empresa ou seus
                                colaboradores, pode resultar no encerramento imediato do acesso do usuário à plataforma,
                                sem aviso prévio.
                            </p>

                            <p>
                                14.3 A decisão de encerrar o acesso do usuário será de exclusiva responsabilidade da
                                empresa. O encerramento do acesso pode ser realizado a critério exclusivo da empresa,
                                sem necessidade de fornecer uma justificativa para tal ação
                            </p>

                            <p>
                                14.4 Em caso de encerramento do acesso do usuário, todas as disposições destes termos de
                                uso que, por sua natureza, devem permanecer em vigor após a rescisão, continuarão a ser
                                aplicáveis.
                            </p>

                            <h3>15. TrainMe Wallet</h3>

                            <p>
                                Ao abrigo destes Termos e Condições de Uso da Ferramenta TrainMe Wallet na plataforma
                                Train Me, a Train Me, que sempre permitiu o acesso a diversas funcionalidades de seu
                                software que habilitam aos Usuários transacionarem entre si, com especial destaque para
                                alunos e profissionais de saúde que realizarem transações sobre Consultorias e
                                Atendimentos diretamente entre eles para intermediar e facilitar as soluções de
                                pagamento disponibilizadas pela Train Me, cobrando comissão de profissionais de saúde
                                pela disponibilização dessa ferramenta em compras parceladas com cartão de crédito ou
                                pix.
                            </p>

                            <p>
                                15.1 Além das definições previstas nos Termos e Condições Gerais de Uso da Train Me, os
                                seguintes termos, palavras, expressões e abreviações grafados com letras iniciais
                                maiúsculas e não definidos em outras partes deste Contrato, independentemente de serem
                                utilizados no singular ou no plural, terão o significado a eles atribuído nesta
                                cláusula, exceto se expressamente indicado de outra forma:
                            </p>

                            <p>
                                Autorização do Emissor: informação prestada pelos Emissores após a realização da
                                Transação pelo profissional, de que: (i) o Cartão não está bloqueado ou cancelado; (ii)
                                o limite de crédito disponível do Portador suporta a Transação; (iii) não há duplicidade
                                da Transação; e (iv) não há impedimento para a captura e liquidação da Transação, de
                                acordo com os critérios utilizados pelos Emissores (localização geográfica do
                                profissional, ramo de atividade, valor da Transação, indícios de fraude, dentre outros).
                            </p>

                            <p>
                                Autorização do Emissor: informação prestada pelos Emissores após a realização da
                                Transação pelo profissional, de que: (i) o Cartão não está bloqueado ou cancelado; (ii)
                                o limite de crédito disponível do Portador suporta a Transação; (iii) não há duplicidade
                                da Transação; e (iv) não há impedimento para a captura e liquidação da Transação, de
                                acordo com os critérios utilizados pelos Emissores (localização geográfica do
                                profissional, ramo de atividade, valor da Transação, indícios de fraude, dentre outros).
                            </p>

                            <p>
                                Cancelamento de Transação: operação que pode ser realizada pela TrainMe Wallet nas
                                hipóteses de irregularidade na Transação ou quando solicitado pelo profissional. O
                                Cancelamento da Transação caracteriza a não realização do pagamento ao profissional ou o
                                estorno do crédito na agenda de recebíveis.
                            </p>

                            <p>
                                Cartão: instrumento de pagamento disponibilizado pelos Emissores em forma de cartão
                                plástico ou outro meio físico ou digital, para uso pessoal e intransferível dos
                                Portadores.
                            </p>

                            <p>
                                Credenciadora: instituição de pagamento que, sem gerenciar conta de pagamento, habilita
                                estabelecimentos para a aceitação de instrumento de pagamento emitido pelos Emissores
                                participantes de um mesmo arranjo de pagamento e participa do processo de liquidação das
                                transações de pagamento como credor perante o Emissor, de acordo com as regras do
                                arranjo de pagamento.
                            </p>

                            <p>
                                Domicílio Bancário: conta corrente de titularidade do profissional, regularmente mantida
                                perante instituição bancária, que será cadastrada para receber saldo de créditos
                                decorrentes da relação com a TrainMe Wallet no processamento das Transações e/ou outras
                                relações jurídicas mantidas com a TrainMe Wallet.
                            </p>

                            <p>
                                Emissor: Instituição de Pagamento, emissora de cartão, nacional ou estrangeira,
                                instituição bancária ou não, autorizada pelas Bandeiras a emitir e conceder Cartões aos
                                Portadores.
                            </p>

                            <p>
                                Meios de Pagamento: Instrumentos de Pagamento, Boleto de Pagamento ou outros
                                instrumentos ou formas de pagamento que venham a ser aceitos para a realização de
                                Transações.
                            </p>

                            <p>
                                Negociação de Recebíveis: operação realizada com instituição financeira, fundo de
                                investimento ou outro credor, pela qual o profissional realiza a cessão ou constituição
                                de alguma garantia sobre os direitos creditórios decorrentes das Transações.
                            </p>

                            <p>
                                Regras do Mercado de Meios de Pagamento: regras, regulamentações, determinações,
                                entendimentos e outros requerimentos além da Legislação aplicável (sendo contratual ou
                                outros) impostos ou adotados por quaisquer outras bandeiras, pelos Instituidores,
                                práticas e usos de mercado, regras de autorregulação, normas e regulamentos emitidos
                                pelas autoridades brasileiras, incluindo, mas sem limitação, o Conselho Monetário
                                Nacional, o Banco Central do Brasil e o Conselho de Controle de Atividades Financeiras,
                                bem como toda a legislação aplicável em âmbito federal, estadual e municipal.
                            </p>

                            <p>
                                Solução de Antecipação: consiste no arranjo irretratável e irrevogável pelo qual todos
                                os recebíveis gerados por Transação parcelada sejam recebidos necessariamente em 30
                                (trinta) (“D+30”) dias após a Transação por meio de cartão de crédito e 24 (vinte e
                                quatro) horas úteis para transação via PIX.
                            </p>

                            <h3>16. Ferramenta TrainMe Wallet</h3>

                            <p>
                                16.1 Funcionalidades aos profissionais que utilizam a Ferramenta TrainMe Wallet. Os
                                profissionais cujas transações forem intermediadas e facilitadas pela Ferramenta TrainMe
                                Wallet, acessória da plataforma da Train Me,poderão:
                            </p>

                            <p>
                                (a) Cadastrar-se, credenciar-se e habilitar-se a aceitar pagamentos por Cartão;
                            </p>

                            <p>
                                (b) Habilitar-se para receber pagamentos instantâneos pelo PIX ou outras modalidades que
                                venham a ser disponibilizadas;
                            </p>

                            <p>
                                16.2 Transações com Cartões. A Ferramenta TrainMe Wallet permite que o profissional
                                receba Transações pela disponibilização de profissionais mediante:
                            </p>

                            <p>
                                (a) A captura e processamento das Transações dos Cartões aceitos pelas Bandeiras;
                            </p>

                            <p>
                                (b) A submissão das Transações realizadas com Cartões por meio de uma Credenciadora para
                                aprovação pelos Emissores e Bandeiras, sem que haja interferência ou participação da
                                TrainMe Wallet nos processos de aprovação das Transações.
                            </p>

                            <p>
                                (c) A liquidação do Valor Líquido das Transações, após o recebimento da Credenciadora, e
                                do desconto das demais taxas devidas à TrainMe Wallet; e
                            </p>

                            <p>
                                (d) O controle e fornecimento de extratos, que serão disponibilizados por meio das
                                Funcionalidades, sobre as movimentações financeiras decorrentes das Transações
                                realizadas pelo profissional.
                            </p>

                            <p>
                                16.3 Inexistência de serviços de instituição financeira ou de cartão de crédito. A
                                Ferramenta TrainMe Wallet não se destina a prestar serviços oferecidos por instituições
                                financeiras ou por administradoras ou credenciadoras de cartão de crédito. A Ferramenta
                                TrainMe Wallet é apenas uma facilitadora de transações de pagamento, sendo que o
                                pagamento de Transações ocorre por conta e ordem dos Usuários. A TrainMe Wallet não é
                                uma instituição financeira e não realiza operações de crédito diretamente.
                            </p>

                            <h3>
                                17. Obrigações do Profissional
                            </h3>

                            <p>
                                17.1 Cumprimento das Regras do Mercado de Meios de Pagamento. O profissional se
                                compromete a cumprir todas as Regras do Mercado de Meios de Pagamento, além das
                                exigências dos instituidor(es) ou demais participantes do(s) arranjo(s) de pagamento de
                                que a TrainMe Wallet faça parte.
                            </p>

                            <p>
                                17.1.1 O profissional declara estar ciente de que, em se tratando de serviços de
                                tecnologia e que dependem de serviços prestados por terceiros, a TrainMe Wallet não
                                poderá ser responsabilizada ou assumirá qualquer responsabilidade por falhas, erros,
                                interrupções, mau funcionamento ou atrasos na utilização da Ferramenta TrainMe Wallet,
                                não garantindo a manutenção do funcionamento da Ferramenta e de suas funcionalidades de
                                forma ininterrupta.
                            </p>

                            <p>
                                17.1.2 As Transações com Cartões realizadas pelo profissional poderão ser processadas
                                por quaisquer Credenciadoras indicadas pela TrainMe Wallet, sendo esta responsável
                                apenas pelo cumprimento das obrigações previstas nestes Termos.
                            </p>

                            <p>
                                17.1.3 O profissional assume integral responsabilidade pelos atos por ele praticados que
                                impliquem multas ou penalidades que vierem a ser aplicadas pelas Bandeiras,
                                Credenciadoras, Banco Central do Brasil ou demais integrantes do mercado de meios de
                                pagamento, sendo autorizado que a TrainMe Wallet retenha diretamente os valores dessas
                                penalidades diretamente da conta do profissional.
                            </p>

                            <p>
                                17.1.4 Trava Bancária da TrainMe Wallet. Para que a TrainMe Wallet possa oferecer as
                                funcionalidades financeiras da TrainMe Wallet, o profissional consente, de modo
                                irrevogável e irretratável, que a integralidade dos valores das Transações efetuadas
                                pelo profissional sejam recebidos em domicílio bancário da TrainMe Wallet para
                                liquidação junto à Câmara Interbancária de Pagamentos (CIP). Após a liquidação das
                                Transações, a TrainMe Wallet transferirá esses valores, descontados das Taxas, ao
                                Domicílio Bancário cadastrado na Plataforma.
                            </p>

                            <p>
                                17.2 Transações online. Todas as Transações realizadas ocorrem de modo online, motivo
                                pelo qual o profissional assume integralmente o risco de chargeback e de inadimplência
                                dos Compradores, nas hipóteses em que não houver o pagamento da Transação pelo
                                respectivo Emissor ou pela respectiva Credenciadora, por qualquer hipótese.
                            </p>

                            <p>
                                17.2.1 Outras obrigações do profissional. O profissional, sem prejuízo das demais
                                obrigações e responsabilidades, é responsável pelo(a):
                            </p>

                            <p>
                                (a) Observância dos procedimentos e regras de segurança para a realização da Transação,
                                inclusive pela obtenção de declarações do comprador, caso venha a ser exigido pela
                                TrainMe Wallet;
                            </p>

                            <p>
                                (b) Adequação de seu sistema às funcionalidades para a realização de Transações, arcando
                                com todos os custos eventualmente incidentes para tal ato;
                            </p>

                            <p>
                                (c) Cumprimento das regras determinadas pela TrainMe Wallet quanto à tecnologia a ser
                                utilizada em sua Plataforma;
                            </p>

                            <p>
                                (d) Garantia de ambiente seguro para a navegação e realização de Transações, de acordo
                                com as regras de tecnologia estabelecidas pela TrainMe Wallet, Credenciadoras, Emissores
                                e/ou Bandeiras;
                            </p>

                            <p>
                                17.2.2 O profissional deverá oferecer, de forma ininterrupta, um ambiente seguro para a
                                realização das Transações.
                            </p>

                            <p>
                                17.3 Confidencialidade. O profissional é responsável pela confidencialidade de todos os
                                dados que compõem as Transações, sendo expressamente vedada a sua utilização para
                                quaisquer outros fins que não sejam a obtenção da autorização e a efetiva captura da
                                Transação.
                            </p>

                            <p>
                                17.4 Inacessibilidade temporária da Ferramenta TrainMe Wallet. Em razão das
                                particularidades do ambiente em nuvem, a TrainMe Wallet não poderá garantir que a
                                Plataforma e nem que a Ferramenta TrainMe Wallet fiquem disponíveis e acessíveis
                                ininterruptamente, sendo possível que se restrinja a disponibilidade da Plataforma ou de
                                certas áreas ou recursos a ela relacionados, se necessário, considerando os limites de
                                capacidade, a segurança ou a integridade de seus servidores, bem como para realizar
                                medidas de manutenção ou aprimoramento dos seus serviços. Em ocorrendo tais situações,
                                excluir-se-á a responsabilidade da TrainMe Wallet, a teor do art. 393, do Código Civil.
                            </p>

                            <p>
                                17.5 Tributos e Nota Fiscal: O profissional é o único responsável por determinar,
                                recolher, reter, declarar (inclusive, a Declaração de Informações de Meios de Pagamentos
                                – DIMP, se cabível) e remeter tributos, tributos de importação, taxas, sobretaxas e
                                cobranças adicionais aplicáveis que sejam decorrentes ou resultem de qualquer venda ou
                                de seu uso da Plataforma. Qualquer contrato de venda realizado na Plataforma é feito
                                diretamente entre Infoprodutor e o Comprador. De acordo com a legislação aplicável, cada
                                Infoprodutor é responsável pelo recolhimento dos impostos/retenções originados em razão
                                das transações realizadas na Plataforma. Assim, o profissional responsabiliza-se por
                                emitir e encaminhar o documento fiscal referente à disponibilização do profissional ao
                                Comprador.
                            </p>

                            <p>
                                17.5.1 Emissão da nota fiscal. Cabe ao profissional a correta emissão da nota fiscal
                                correspondente aos serviços prestados, referente ao valor total da venda, sem qualquer
                                desconto e/ou retenção, inclusive na hipótese de cobrança parcelada do Comprador, com a
                                inclusão em nota fiscal do preço total de venda.
                            </p>

                            <p>
                                17.6 Ingressos e Receitas da TrainMe Wallet. A receita da TrainMe Wallet não inclui
                                valores devidos ou repassados ao profissional, ou qualquer outra receita de terceiros
                                que por ela transite em razão de recebimento por conta e ordem de terceiros e/ou do
                                Usuário. Só compõe a receita da TrainMe Wallet os valores e taxas recebidas em razão das
                                funcionalidades efetivamente disponibilizadas por ela aos Usuários.
                            </p>

                            <p>
                                17.7 Valores Repassados a Terceiros. Os valores cobrados pela TrainMe Wallet e dispostos
                                nos Termos e Condições Gerais de Uso da TrainMe Wallet e descritas em nossos planos
                                constituem a integralidade dos serviços objeto do presente. Custos da operação e dos
                                serviços prestados pela TrainMe Wallet ou terceiros contratados em nome e por conta do
                                profissional integram o Valor Bruto, porém não compõem a receita tributável da TrainMe
                                Wallet.
                            </p>
                        </div>
                    </div>
                </Box>
            </Box>
        </Box>
    );
};

export default Terms;
