import React from 'react';

import './Title.scss';

const Title = ({title}) => {
    return (
        <div className="title">
            <h2>{title}</h2>
        </div>
    );
}

export default Title;