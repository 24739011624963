import React from 'react';
import TextField from '@mui/material/TextField';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {Controller} from 'react-hook-form';

import './CustomDatePicker.scss';

const CustomDatePicker = ({name, label, errors, control, inputFormat = "dd/MM/yyyy", views = ['day']}) => {

    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({field: {onChange, value}}) =>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            views={views}
                            inputFormat={inputFormat}
                            label={label}
                            name={name}
                            value={value}
                            onChange={(data) => {
                                onChange(data);
                                return data;
                            }}
                            renderInput={(params) => <TextField {...params} helperText={null}/>}
                        />
                    </LocalizationProvider>
                }
            />
            {errors && <p className='error'>{errors.message}</p>}
        </>
    );
}

export default CustomDatePicker;