import React, {useContext, useState} from 'react';

import Subtitle from '../../../../Components/Subtitle/Subtitle';
import Title from '../../../../Components/Title/Title';
import ModalDelete from '../../../../Components/ModalDelete/ModalDelete';
import PrimaryButton from '../../../../Components/PrimaryButton/PrimaryButton';
import Feedback from '../../../../Components/Feedback/Feedback';


import DraggableItem from '../DraggableItem/DraggableItem';
import DraggableTarget from '../DraggableTarget/DraggableTarget';
import NewExerciseBox from './Components/NewExerciseBox/NewExerciseBox';
import ModalExercise from './Components/ModalExercise/ModalExercise';
import ModalSpareExercise from './Components/ModalSpareExercise/ModalSpareExercise';

import {
    CreateExercise,
    DeleteExercise,
    DeleteExerciseReposition,
    LinkExercises,
    UpdateExerciseOrders
} from "../../../../Services/Exercises/index";
import {ExercisesContext} from '../../../../Context/ExercisesContext';

import './ExercisesList.scss';
import {Box, Switch, Typography} from '@mui/material';

const ExercisesList = ({workoutId, callbackExercises}) => {
    const APP_PATH = process.env.REACT_APP_PUBLIC_URL;
    const CARDIO_GROUP_ID = Number(process.env.REACT_APP_CARDIO_GROUP_ID);
    const access_token = localStorage.getItem('access_token');

    const {exercises, setExercises} = useContext(ExercisesContext);

    const [modalSpareExercise, setModalSpareExercise] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [modalDeleteText, setModalDeleteText] = useState("");
    const [modalExercise, setModalExercise] = useState("");
    const [linkedExercises, setLinkedExercises] = useState([]);

    const [selectedExercise, setSelectedExercise] = useState();
    const [reposition, setReposition] = useState();
    const [exercise, setExercise] = useState("");
    const [showFeedback, setShowFeedback] = useState(false);
    const [feedbackType, setFeedbackType] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState(false);
    const [checked, setChecked] = useState(true);

    const handleAddExercise = async (data) => {
        let lastItem;
        setExercises((prevState) => {
            lastItem = prevState[prevState.length - 1];
            return prevState;
        });
        const isCardio = data.group.id === CARDIO_GROUP_ID;
        const payload = {
            training: workoutId,
            exerciseLibrary: data.id,
            name: data.name,
            group: data.group,
            executionMethod: "Padrão",
            repetitions: isCardio ? "30" : "12",
            series: isCardio ? "1" : "4",
            rest: isCardio ? "0" : "60",
            cadency: "1:1",
            observations: "",
            order: lastItem ? lastItem.order + 1 : 1,
            spareExercise: undefined,
            linkedExercises: []
        };
        // Fazer calculo da order
        const exerciseData = await CreateExercise(access_token, payload);
        setExercises((prevState) => ([
            ...prevState,
            {
                ...payload,
                ...data,
                id: exerciseData.data.id,
                order: exerciseData.data.order,
                exerciseLibrary: {id: data.id, media: data.media, image: data.image}
            }
        ]));
    };

    const handleChangeOrder = async (data, target, context) => {
        if (context !== 'all') {
            let newState;
            setExercises((prevState) => {
                const newArr = [...prevState];
                const temp = newArr[data.index];
                const itemOrder = newArr[data.index].order;
                const targetOrder = newArr[target].order;

                newArr[data.index] = {...newArr[target], order: itemOrder};
                newArr[target] = {...temp, order: targetOrder};
                newState = newArr;
                return newArr;
            });
            const payloadUpdate = newState.map((a) => ({id: a.id, order: a.order}));
            await UpdateExerciseOrders(access_token, payloadUpdate);
        } else {
            await handleAddExercise(data);
        }
    };

    const handleModalEditExercise = (exercise) => {
        setSelectedExercise('exercise');
        setExercise(exercise);
        handleModalExercise();
    };

    const handleSetExercise = (exercise) => {
        setSelectedExercise(exercise);
        handleModalSpareExercise();
    };

    const handleDeleteSpareExercise = async (exerciseId) => {
        await DeleteExerciseReposition(access_token, exerciseId);
        await callbackExercises();
        setReposition(undefined);
    };

    const handleEditSpareExercise = (exercise) => {
        setSelectedExercise(exercise);
        setReposition(exercise.reposition);
        handleModalSpareExercise();
    };

    const handleModalDeleteExercise = (exercise) => {
        setModalDeleteText(exercise.name + ' - ' + exercise.executionMethod);
        setSelectedExercise(exercise);
        handleModalDelete();
    };

    const handleModalSpareExercise = () => {
        if (modalSpareExercise) setReposition(undefined);
        setModalSpareExercise(!modalSpareExercise);
    };

    const handleDeleteExercise = (id) => {
        DeleteExercise(access_token, id);
        const filteredExercises = exercises
            .filter(exercise => exercise.id !== id)
            .map((ex) => ({
                ...ex,
                linkedExercises: ex.linkedExercises.filter((le) => le.id !== id)
            }));
        setExercises(filteredExercises);
        handleModalDelete();
    };

    const handleModalExercise = async (exercise) => {
        setModalExercise(!modalExercise);
    };

    const handleModalDelete = () => {
        setModalDelete(!modalDelete);
    };

    const handleAgreggate = (checked, exercise) => {
        window.dataLayer.push({
            'event': checked ? 'select_exercise' : 'unselect_exercise',
            'exercise_id': exercise.id,
            'exercise_name': exercise.name,
            'workout_id': workoutId
        });
        
        if (checked) {
            setLinkedExercises((prevState) => [...prevState, exercise]);
        } else {
            setLinkedExercises((prevState) => prevState.filter((ex => ex.id !== exercise.id)));
        }
    };

    const showFeedbackToast = (severity, message) => {
        setShowFeedback(true);
        setFeedbackType(severity);
        setFeedbackMessage(message);
        setTimeout(() => {
            setShowFeedback(false);
        }, 3500);
    };

    const onAgreggate = async () => {
        if (linkedExercises && linkedExercises.length >= 2) {
            window.dataLayer.push({
                'event': 'aggregate_exercises',
                'exercises_count': linkedExercises.length,
                'workout_id': workoutId
            });
            const linkedExcs = linkedExercises.sort((a, b) => a.id - b.id);
            const exercisesHaveLinked = linkedExcs.filter((linked) => linked.linkedExercises.length > 0);

            if (exercisesHaveLinked.length > 1) {
                showFeedbackToast("error", "Não é possivel agregar dois exercicios já agregados.");
                return;
            }

            const mainId = exercisesHaveLinked.length > 0 ? exercisesHaveLinked[0].id : linkedExercises[0].id;
            const mainExIdx = exercisesHaveLinked.length > 0 ? linkedExcs.findIndex((l) => l.id === mainId) : 0;
            linkedExcs.splice(mainExIdx, 1);

            const exerciseIds = linkedExcs.map((ex) => ex.id);
            await LinkExercises(access_token, mainId, exerciseIds);
            await callbackExercises();
            setLinkedExercises([]);
        }
    };

    return (
        <>
            <form id="form-exercises">
                <div className='exercises-list'>
                    <DraggableTarget callback={(item) => handleAddExercise(item)} context="all">

                        <Box className='item-title'>
                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: {xs: 'column', sm: 'row'},
                                    justifyContent: 'space-between'
                                }}>
                                    <div>
                                        <Title title="Exercícios do treino"/>
                                        <Subtitle subtitle="Edite os exercícios e sua ordem abaixo"/>
                                    </div>
                                    <PrimaryButton
                                        text="Conjugar Exercicios"
                                        disabled={linkedExercises.length < 2}
                                        type="button"
                                        onClick={onAgreggate}
                                    />
                                </Box>
                                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <Switch checked={checked} onChange={() => setChecked(!checked)} color="info"/>
                                    <Typography>Visualizar exercicios</Typography>
                                </Box>
                            </Box>
                        </Box>
                        {showFeedback && (<Feedback severity={feedbackType} text={feedbackMessage}/>)}

                        <div className="exercise-item-container">
                            {exercises && exercises.map((exercise, key) => (
                                <div key={key}>
                                    <DraggableTarget callback={handleChangeOrder} targetIdx={key} context="exercises">
                                        <DraggableItem item={{...exercise, index: key}} context="exercises">
                                            <NewExerciseBox
                                                exercise={exercise}
                                                handleAgreggate={handleAgreggate}
                                                handleModalEditExercise={handleModalEditExercise}
                                                handleModalDeleteExercise={handleModalDeleteExercise}
                                                handleEditSpareExercise={handleEditSpareExercise}
                                                handleDeleteSpareExercise={handleDeleteSpareExercise}
                                                handleSetExercise={handleSetExercise}
                                                linked={false}
                                                linkedExercises={linkedExercises}
                                                showImage={checked}
                                                index={key}
                                                key={key}
                                            />
                                            {
                                                exercise.linkedExercises.map((linkedExercise, linkedKey) => (
                                                    <NewExerciseBox
                                                        exercise={linkedExercise}
                                                        handleAgreggate={handleAgreggate}
                                                        handleModalEditExercise={handleModalEditExercise}
                                                        handleModalDeleteExercise={handleModalDeleteExercise}
                                                        handleEditSpareExercise={handleEditSpareExercise}
                                                        handleDeleteSpareExercise={handleDeleteSpareExercise}
                                                        handleSetExercise={handleSetExercise}
                                                        linked
                                                        linkedExercises={linkedExercises}
                                                        parentIndex={key}
                                                        showImage={checked}
                                                        index={linkedKey}
                                                        key={linkedKey}
                                                    />
                                                ))
                                            }
                                        </DraggableItem>
                                    </DraggableTarget>
                                </div>
                            ))}
                        </div>

                        {exercises.length === 0 && (
                            <section className="empty-list">
                                <div>
                                    <div className="images-container">
                                        <img src={APP_PATH + "group-top.svg"} alt="group-top" className="image-top"/>
                                        <img src={APP_PATH + "group-quite.svg"} alt="group-quite"
                                             className="image-quite"/>
                                        <img src={APP_PATH + "group-bottom.svg"} alt="group-bottom"
                                             className="image-bottom"/>
                                    </div>
                                    <h4>Ainda não há nenhum exercício!</h4>
                                    <div>
                                        <p>Clique em algum exercício da biblioteca adicione o primeiro</p>
                                        <p>exercício do seu aluno.</p>
                                    </div>
                                </div>
                            </section>
                        )}
                    </DraggableTarget>
                </div>
            </form>

            {
                modalSpareExercise &&
                <ModalSpareExercise
                    workoutId={workoutId}
                    handleModal={handleModalSpareExercise}
                    reposition={reposition}
                    exercises={exercises}
                    setExercises={setExercises}
                    selectedId={selectedExercise?.id}
                    callbackExercises={callbackExercises}
                />
            }
            {
                modalDelete &&
                <ModalDelete
                    handleModal={handleModalDelete}
                    title="Excluir exercício"
                    subtitle="Deseja excluir o exercício:"
                    text={modalDeleteText}
                    id={selectedExercise?.id}
                    handleDelete={handleDeleteExercise}
                />
            }
            <ModalExercise
                workoutId={workoutId}
                open={modalExercise}
                handleModal={handleModalExercise}
                exerciseItem={exercise}
                exercises={exercises}
                setExercises={setExercises}
                callbackExercises={callbackExercises}
            />
        </>
    );
};

export default ExercisesList;