import {GetAsync, PostAsync, PutAsync, PutFormAsync} from '../api'

/**
 * Método responsável para atualizar um consultor
 */
const UpdateConsultant = (token, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await PutAsync(`/consultant`, config, data);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável para atualizar a carga de trabalho do consultor
 */
const UpdateConsultantWork = (token, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };
            const response = await PutAsync(`/consultant/work`, config, data);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável para atualizar um consultor
 */
const ChangePassword = (token, id, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await PutAsync(`/consultant/${id}/password`, config, data);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável para atualizar o pix de um consultor
 */
const ChangePix = (token, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await PutAsync(`/consultant/pix`, config, data);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável para atualizar a carga de trabalho do consultor
 */
const CreateAccount = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await PostAsync(`/consultant`, {}, data);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável para atualizar a foto do consultor
 */
const UpdateProfilePic = (token, id, file) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            };
            const form = new FormData();
            form.append('consultantId', id);
            form.append('image', file);
            const response = await PutFormAsync(`/consultant/profile-photo`, config, form);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável por verificar o limite de alunos do consultor
 */
const CheckLimit = (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await GetAsync(`/consultant/${id}/check-limit`, {}, {})
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

export {
    UpdateConsultant,
    ChangePassword,
    UpdateConsultantWork,
    UpdateProfilePic,
    CreateAccount,
    CheckLimit,
    ChangePix
};