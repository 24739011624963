import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Box, Stack, Typography,} from "@mui/material";
import InfiniteScroll from 'react-infinite-scroll-component';

import InputMessage from "../../../Chat/components/InputMessage/InputMessage";
import MessageBubble from "../../../Chat/components/MessageBubble/MessageBubble";

import {CreateMessage, GetChatMessages, ReadMessages} from "../../../../Services/Chats/index";

const ChatMessage = ({student}) => {
    const paragraphRef = useRef(null);
    const access_token = localStorage.getItem('access_token');

    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState("");

    const handleCreateMessage = async () => {
        if (message && message !== null && message !== '') {
            const consultant = JSON.parse(sessionStorage.getItem('consultant'));
            const createPayload = {
                content: message
            };
            await CreateMessage(access_token, student.chatId, createPayload);

            setMessages((prevState) => [{
                ...{
                    ...createPayload,
                    sender: {
                        id: consultant.userId
                    }
                },
                createdAt: new Date()
            }, ...prevState]);

            setMessage("");
        }
    };

    const getChatMessages = useCallback(async () => {
        const response = await GetChatMessages(access_token, 1, student.chatId, 100);
        setMessages((prevState) => prevState.concat(response.data?.rows));
    }, [access_token, setMessages, student]);

    useEffect(() => {
        setMessages([]);
        getChatMessages();
        ReadMessages(access_token, student.chatId);
    }, [getChatMessages, access_token, student]);

    return (
        <Box className="chat-message" sx={{
            borderRadius: 1,
            bgcolor: 'background.paper',
            px: 1,
            py: 2,
            boxShadow: '1px 1px 6px rgba(0, 0, 0, 0.2)'
        }}>
            {student.chatId && <Stack sx={{justifyContent: "space-between"}} paddingTop={0}>
                <div
                    id="scrollableDiv"
                    style={{
                        height: "500px",
                        width: "100%",
                        overflow: "none",
                        display: 'flex',
                        flexDirection: 'column-reverse',
                    }}>
                    <InfiniteScroll
                        dataLength={messages.length}
                        height="500px"
                        width="100%"
                        inverse={true}
                        scrollThreshold={0.8}
                        style={{display: 'flex', flexDirection: 'column-reverse', scrollbarColor: "grey white"}}
                        scrollableTarget="scrollableDiv"
                        endMessage={
                            <p style={{textAlign: 'center'}}>
                                <b>Não há {messages.length > 0 ? 'mais' : ''} mensagens.</b>
                            </p>
                        }
                    >
                        {messages
                            .map(m => (
                                <MessageBubble
                                    key={m.id}
                                    message={m}
                                    userInfo={student}
                                />
                            ))}
                    </InfiniteScroll>
                </div>

                <p ref={paragraphRef}></p>
                {student.chatId && <InputMessage
                    setMessage={setMessage}
                    message={message}
                    handleCreateMessage={handleCreateMessage}
                />}
            </Stack>}
            {!student.chatId && <Typography>Não é possivel abrir um chat com você mesmo.</Typography>}
        </Box>
    );
};

export default ChatMessage;