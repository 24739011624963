import {DeleteAsync, GetAsync, PostAsync} from '../api';

/**
 * Método responsável por obter dados dos estudantes na api
 */
const CreateStudent = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await PostAsync('/student', {}, data)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável por obter dados dos estudantes na api
 */
const PostStudent = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await PostAsync('/student/full', {}, data)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável por criar um estudante manualmente
 */
const PostStudentManual = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                }
            };
            const response = await PostAsync('/student/manual', config, data)

            if (response.status === 400) {
                reject(response.data);
            } else {
                resolve(response.data);
            }
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável por obter dados dos estudantes na api
 */
const GetStudents = (token, page = 1, size = 30, filters = undefined) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await GetAsync(`/student?page=${page}&size=${size}&orderBy=DESC&order=createdAt&filters=${filters}`, config)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável por estudante por email
 */
const GetStudent = (email) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                }
            };
            const response = await GetAsync(`/student/email/${email}`, config)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável por estudante pelo id
 */
const GetStudentDetails = (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                }
            };
            const response = await GetAsync(`/student/${id}`, config)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável por obter estudante por id
 */
const GetStudentById = (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                }
            };
            const response = await GetAsync(`/student/find/${id}`, config)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável por exportar o XLS dos Planos
 */
const GetExport = (token, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                baseURL: process.env.REACT_APP_BASE_URL,
                method: 'GET',
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }
            const response = await GetAsync(`/plans/export/${id}`, config)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável por deletar um estudante pelo id
 */
const Delete = (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('access_token')}`,
                }
            };
            const response = await DeleteAsync(`/student/${id}/relation`, config)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};


export {
    GetStudents,
    GetExport,
    PostStudent,
    GetStudent,
    PostStudentManual,
    GetStudentById,
    GetStudentDetails,
    CreateStudent,
    Delete
}