import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Button, IconButton, Modal, Switch, TextField, Typography } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';

import TextFieldTrainMe from '../../../../../../Components/TextField/TextField';
import PrimaryButton from '../../../../../../Components/PrimaryButton/PrimaryButton';
import Title from '../../../../../../Components/Title/Title';
import Select from '../../../../../../Components/Select/Select';

import { UpdateExercise } from '../../../../../../Services/Exercises/index';

import './ModalExercise.scss';

const schema = yup.object().shape({
    name: yup.string().required("O campo nome do exercício é obrigatório!"),
    repetitions: yup.string(),
    series: yup.string(),
    rest: yup.string(),
    cadency: yup.string(),
    executionMethod: yup.string().required("O campo forma de execução é obrigatório!"),
    observations: yup.string(),
});

const ModalExercise = ({
    open,
    handleModal,
    exercises,
    setExercises,
    exerciseItem,
    workoutId,
    callbackExercises
}) => {
    const serieDefault = { repetitions: '12', cadency: '1:1', rest: 60 };
    const CARDIO_GROUP_ID = Number(process.env.REACT_APP_CARDIO_GROUP_ID);
    const access_token = localStorage.getItem('access_token');

    const { control, handleSubmit, setValue, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });
    const [loading, setLoading] = useState(false);
    const [method, setMethod] = useState('');
    const [customSeries, setCustomSeries] = useState(false);
    const [series, setSeries] = useState([]);

    const saveExercise = async (payload) => {
        window.dataLayer.push({
            'event': 'edit_exercise',
            'exercise_id': exerciseItem.id,
            'exercise_name': payload.name,
            'exercise_method': payload.executionMethod,
            'workout_id': workoutId
        });
        
        let totalSeries = String(payload.series);
        let repetitions = series[0].repetitions;
        let rest = series[0].rest.toString();
        let cadency = series[0].cadency;

        if (customSeries) {
            repetitions = series.map(s => s.repetitions).join(';');
            cadency = series.map(s => s.cadency).join(';');
            rest = series.map(s => s.rest).join(';');
            totalSeries = String(series.length);
        }

        const dataUpdate = {
            id: exerciseItem.id,
            training: workoutId,
            name: payload.name,
            exerciseLibrary: exerciseItem.exerciseLibrary.id,
            series: totalSeries,
            repetitions,
            rest,
            cadency,
            observations: payload.observations,
            executionMethod: payload.executionMethod
        };
        await UpdateExercise(access_token, dataUpdate);

        const newExercises = [...exercises];
        let indexExercise = newExercises.findIndex((ex) => ex.id === exerciseItem.id);
        console.log(indexExercise);
        if (indexExercise === -1) {
            await callbackExercises();
        } else {
            newExercises[indexExercise].name = payload.name;
            newExercises[indexExercise].executionMethod = payload.executionMethod;
            newExercises[indexExercise].series = totalSeries;
            newExercises[indexExercise].repetitions = repetitions;
            newExercises[indexExercise].rest = rest;
            newExercises[indexExercise].cadency = cadency;
            newExercises[indexExercise].observations = payload.observations;
            setExercises(newExercises);
        }

        handleModal();
        setLoading(false);
    };

    const onSubmitHandler = async (payload) => {
        setLoading(true);
        await saveExercise(payload);
    };

    const onClose = () => {
        reset();
        setMethod('');
        handleModal();
    };

    const fillCustomSeries = useCallback(() => {
        if (exerciseItem) {
            const reps = exerciseItem.repetitions.split(';');
            const cadency = exerciseItem.cadency.split(';');
            const rest = exerciseItem.rest.split(';');
            const s = [];
            for (let index = 0; index < reps.length; index++) {
                s.push({ repetitions: reps[index], cadency: cadency[index], rest: rest[index] });
            }
            setSeries(s);
        }
    }, [exerciseItem]);

    useEffect(() => {
        if (!customSeries) {
            setSeries([{ repetitions: '12', cadency: '1:1', rest: 60 }]);
        } else {
            fillCustomSeries();
        }
    }, [customSeries, exerciseItem.cadency, exerciseItem.repetitions, exerciseItem.rest, fillCustomSeries]);

    useEffect(() => {
        if (exerciseItem !== undefined && exerciseItem !== '') {
            setValue('name', exerciseItem.name);
            setValue('executionMethod', exerciseItem.executionMethod);
            setMethod(exerciseItem.executionMethod);
            setValue('repetitions', exerciseItem.repetitions);
            setValue('series', exerciseItem.series);
            setValue('rest', exerciseItem.rest);
            setValue('cadency', exerciseItem.cadency);
            setValue('observations', exerciseItem.observations);
            setSeries([{ repetitions: exerciseItem.repetitions, cadency: exerciseItem.cadency, rest: exerciseItem.rest }]);

            if (exerciseItem.repetitions.includes(';') && exerciseItem.cadency.includes(';') && exerciseItem.rest.includes(';')) {
                setCustomSeries(true);
                fillCustomSeries();
            } else {
                setCustomSeries(false);
            }
        }
    }, [open, setValue, setSeries, exerciseItem, fillCustomSeries]);

    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <div className='modal-edit-exercise'>
                <form className='modal-add' id='edit-exercise-form'>
                    <Box sx={{ p: 2 }} className='titles'>
                        <div>
                            <div className='div-image'>
                                <img src={process.env.REACT_APP_PUBLIC_URL + "icon-modal-spare-exercise.svg"}
                                    alt={"icon-modal-spare-exercise"} />
                            </div>
                            <div className='div-title'>
                                <Title title="Editar exercício" />
                                <p>Insira as informações abaixo</p>
                            </div>
                        </div>

                        <img src={process.env.REACT_APP_PUBLIC_URL + "icon-close.svg"} alt={"icon-close"}
                            onClick={handleModal} />
                    </Box>

                    <Box sx={{
                        overflow: { xs: 'scroll', sm: 'unset' },
                        height: { xs: '70vh', sm: 'inherit' },
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        px: 3,
                        py: 2
                    }}
                    >
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <TextFieldTrainMe
                                control={control}
                                name={'name'}
                                label={'Nome do exercício'}
                                fullWidth
                                errors={errors.name}
                            />
                        </Box>

                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Select
                                disableClearable
                                name="executionMethod"
                                options={exerciseItem && exerciseItem.group.id === CARDIO_GROUP_ID ? ['Padrão', 'HIIT'] : ['Padrão', 'Rest and Pause', 'Drop Set', 'Bi-set', 'Tri-set', 'Super Série', 'Circuito', 'FST-7', 'Cluster Set', 'HIIT']}
                                defaultValue={exerciseItem.executionMethod ?? 'Padrão'}
                                setValue={(data) => {
                                    setMethod(data);
                                    setValue('executionMethod', data);
                                }}
                                label="Forma de execução"
                                control={control}
                                errors={errors.executionMethod}
                                fullWidth
                            />
                        </Box>

                        {(!exerciseItem || exerciseItem.group.id !== CARDIO_GROUP_ID) && <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Switch checked={customSeries} onChange={() => setCustomSeries(!customSeries)} color="secondary" />
                            <Typography>Customizar séries</Typography>
                        </Box>}

                        {(!exerciseItem || exerciseItem.group.id !== CARDIO_GROUP_ID) && !customSeries && <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <TextFieldTrainMe
                                control={control}
                                name="series"
                                defaultValue=""
                                type="number"
                                label="Quantidade de séries"
                                fullWidth
                                errors={errors.series} />
                        </Box>}

                        <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column', pt: 1, overflowY: customSeries ? 'scroll' : '', overflowX: customSeries ? 'hidden' : '', maxHeight: 200 }}>
                            {series && series.map((s, i) => <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                {customSeries && <Typography fontWeight={500}>S{i + 1}</Typography>}
                                {((method !== 'HIIT' && exerciseItem && exerciseItem.group.id !== CARDIO_GROUP_ID) ||
                                    (exerciseItem && exerciseItem.group.id === CARDIO_GROUP_ID)) && <TextField
                                        control={control}
                                        name="repetitions"
                                        onChange={(e) => {
                                            const updatedSeries = [...series];
                                            updatedSeries[i].repetitions = e.target.value;
                                            setSeries(updatedSeries);
                                        }}
                                        value={s.repetitions}
                                        label={exerciseItem && exerciseItem.group.id === CARDIO_GROUP_ID ? 'Tempo de cardio (minutos)' : 'Repetições'}
                                        fullWidth
                                        variant="outlined"
                                        errors={errors.repetitions} />
                                }
                                {(!exerciseItem || exerciseItem.group.id !== CARDIO_GROUP_ID) && (<>
                                    <TextField
                                        control={control}
                                        name="cadency"
                                        defaultValue={method === 'HIIT' ? '60' : "1:1"}
                                        label={method === 'HIIT' ? 'Tempo sob tensão' : "Cadência"}
                                        onChange={(e) => {
                                            const updatedSeries = [...series];
                                            updatedSeries[i].cadency = e.target.value;
                                            setSeries(updatedSeries);
                                        }}
                                        value={s.cadency}
                                        fullWidth
                                        variant="outlined"
                                        errors={errors.cadency} />
                                    <TextField
                                        control={control}
                                        name="rest"
                                        defaultValue={60}
                                        label={'Descanso (segundos)'}
                                        onChange={(e) => {
                                            const updatedSeries = [...series];
                                            updatedSeries[i].rest = e.target.value;
                                            setSeries(updatedSeries);
                                        }}
                                        value={s.rest}
                                        type="number"
                                        fullWidth
                                        variant="outlined"
                                        errors={errors.rest} />
                                </>)}
                                {customSeries && i !== 0 &&
                                    <IconButton edge="end" aria-label="delete"
                                        onClick={(e) => { setSeries(prevState => [...prevState.slice(1)]) }}>
                                        <Delete sx={{ color: '#c7427b' }} />
                                    </IconButton>}
                            </Box>
                            )}
                        </Box>

                        {(!exerciseItem || exerciseItem.group.id !== CARDIO_GROUP_ID) && customSeries && <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1 }}>
                            <Button variant='outlined' onClick={() => setSeries(prevState => [...prevState, serieDefault])} startIcon={<Add />}> Nova série</Button>
                        </Box>}

                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <TextFieldTrainMe
                                control={control}
                                name={'observations'}
                                label={'Adicione as observações'}
                                fullWidth
                                errors={errors.observations}
                            />
                        </Box>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <PrimaryButton
                            text="Editar"
                            onClick={handleSubmit(onSubmitHandler)}
                            loading={loading}
                        />
                    </Box>
                </form>
            </div>
        </Modal>
    );
};

export default ModalExercise;