import React, {useState} from 'react';
import {
    Box,
    Chip,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from '@mui/material';

import {GetTransactions} from '../../../../Services/Payments/index';

const Transactions = ({transactions, totalRows}) => {
    const COLORS = ['#C7427B', '#1E306E', '#3ac49c', '#db3d60', '#c2c627', '#C7427B', '#1E306E', '#3ac49c', '#db3d60', '#c2c627'];

    const [pageSize, setPageSize] = useState(5);
    const [page, setPage] = useState(0);
    const [currentTransactions, setTransactions] = useState(transactions);

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
        getTransactions(newPage, pageSize);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        const newPageSize = parseInt(event.target.value, 10);
        setPageSize(newPageSize);
        getTransactions(0, newPageSize);
    };

    const getTransactions = async (pageIdx, rowsPerPage) => {
        const response = await GetTransactions(pageIdx + 1, rowsPerPage);

        if (response && response.data) {
            setTransactions(response.data.transactions);
        }
    };

    const getDate = (date) => {
        const formattedDate = new Date(date);
        return `${formattedDate.toLocaleDateString()}`;
    };

    const getTimestamp = (date) => {
        const formattedDate = new Date(date);
        return `${formattedDate.toLocaleTimeString()}`;
    };

    const formatCurrency = (value) => {
        return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL',})
    };

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead sx={{backgroundColor: '#e5e7eb'}}>
                    <TableRow>
                        <TableCell sx={{display: {xs: 'none', sm: 'table-cell'}}}>Data</TableCell>
                        <TableCell>Aluno</TableCell>
                        <TableCell sx={{display: {xs: 'none', sm: 'table-cell'}}}>Protocolo</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Valor Líquido</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {currentTransactions.map((transaction, index) => (
                        <TableRow key={index} sx={{height: 100}}>
                            <TableCell sx={{display: {xs: 'none', sm: 'table-cell'}}}>
                                <Box>
                                    <Typography>{getDate(transaction.paymentDate)}</Typography>
                                    <Typography>{getTimestamp(transaction.paymentDate)}</Typography>
                                </Box>
                            </TableCell>
                            <TableCell>{transaction.studentName}</TableCell>
                            <TableCell sx={{display: {xs: 'none', sm: 'table-cell'}}}>
                                <Chip
                                    label={transaction.protocolName}
                                    color="primary"
                                    sx={{
                                        fontWeight: '700',
                                        bgcolor: `${COLORS[transaction.planId % 10 ?? 0]}1a`,
                                        color: COLORS[transaction.planId % 10 ?? 0]
                                    }}/>
                            </TableCell>
                            <TableCell>
                                <Typography sx={{
                                    bgcolor: '#DCFAE6',
                                    color: '#085D3A',
                                    fontWeight: 600,
                                    width: 'fit-content',
                                    px: 1,
                                    mb: 1,
                                    fontSize: 12
                                }}>
                                    {transaction.manual ? 'Recebido Externo' : transaction.status}
                                </Typography>
                                {!transaction.manual &&
                                    <Typography sx={{
                                        bgcolor: '#EAECF0',
                                        color: 'rgba(0, 0, 0, 0.8)',
                                        fontWeight: 500,
                                        width: 'fit-content',
                                        px: 1,
                                        fontSize: 12
                                    }}>
                                        {transaction.paymentMethod === 'CREDIT_CARD' ? 'CARTÃO DE CRÉDITO' : transaction.paymentMethod}
                                    </Typography>
                                }
                            </TableCell>
                            <TableCell sx={{
                                color: '#344054',
                                fontWeight: 600
                            }}>{formatCurrency(transaction.profitAmount)}</TableCell>
                        </TableRow>
                    ))}
                    {currentTransactions.length === 0 && (
                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2}}>
                            <Typography fontWeight={600}>Você ainda não possui nenhuma transação.</Typography>
                        </Box>
                    )}
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                count={totalRows}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25, 50]}
                labelRowsPerPage="Linhas por página"
            />
        </TableContainer>
    );
};

export default Transactions;