import React, {useEffect, useState} from 'react';
import {initMercadoPago} from '@mercadopago/sdk-react';
import InputMask from 'react-input-mask';
import {Box, FormControl, Input, InputLabel, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';

import {CreatePayment} from '../../../../Services/Payments';

import './CreditCardPayment.scss';

const CreditCardPayment = ({personalData, callback, plansActivityId, planInfo, consultantId}) => {
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [validDate, setValidDate] = useState();
    const [cpf, setCpf] = useState(personalData.cpf ?? '');

    useEffect(() => {
        const loadMercadoPago = async () => {
            setError(null);
            const publicKey = process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY;
            initMercadoPago(publicKey);
            const mp = new window.MercadoPago(publicKey, {
                locale: 'pt-BR',
            });

            const form = mp.cardForm({
                amount: planInfo.price,
                autoMount: true,
                form: {
                    id: 'form-checkout',
                    cardholderName: {
                        id: 'form-checkout__cardholderName',
                        placeholder: 'Nome no cartão',
                    },
                    cardholderEmail: {
                        id: 'form-checkout__cardholderEmail',
                        placeholder: 'E-mail',
                    },
                    cardNumber: {
                        id: 'form-checkout__cardNumber',
                        placeholder: 'Número do cartão',
                    },
                    cardExpirationDate: {
                        id: 'form-checkout__cardExpirationDate',
                        placeholder: 'MM/YY',
                    },
                    securityCode: {
                        id: 'form-checkout__securityCode',
                        placeholder: 'CVV',
                    },
                    installments: {
                        id: 'form-checkout__installments',
                        placeholder: 'Parcelas',
                    },
                    identificationType: {
                        id: 'form-checkout__identificationType',
                    },
                    identificationNumber: {
                        id: 'form-checkout__identificationNumber',
                        placeholder: 'CPF',
                    },
                    issuer: {
                        id: 'form-checkout__issuer',
                    },
                },
                callbacks: {
                    onFormMounted: (error) => {
                        if (error) {
                            console.warn('Form Mounted handling error: ', error);
                        } else {
                            console.log('Form mounted');
                        }
                    }, onSubmit: async (event) => {
                        event.preventDefault();
                        setProcessing(true);
                        setError(null);

                        const {
                            paymentMethodId,
                            issuerId,
                            cardholderEmail,
                            amount,
                            token,
                            installments,
                            identificationNumber,
                            identificationType
                        }
                            = form.getCardFormData();

                        // Validações dos campos
                        if (!paymentMethodId || !issuerId || !cardholderEmail || !amount || !token || !installments || !identificationNumber || !identificationType) {
                            setError('Por favor, preencha todos os campos corretamente.');
                            setProcessing(false);
                            return;
                        }

                        try {
                            const payload = {
                                token,
                                consultantId: consultantId,
                                issuerId: issuerId,
                                paymentMethodId: paymentMethodId,
                                transactionAmount: Number(amount),
                                installments: Number(installments),
                                description: 'TrainMe LTDA',
                                plansActivityId: plansActivityId ?? undefined,
                                idStudent: personalData.id,
                                planId: planInfo.id,
                                payer: {
                                    email: cardholderEmail,
                                    docType: 'CPF',
                                    docNumber: identificationNumber,
                                },
                            };
                            const response = await CreatePayment(payload);
                            setPaymentStatus(response.data.status);

                            if (response.data.status === 'approved') {
                                await callback(response.data.paymentId, personalData, true);
                            }
                        } catch (error) {
                            setError(error.message);
                        }

                        setProcessing(false);
                    },
                },
            });
        };
        loadMercadoPago();
    }, [planInfo.price, planInfo.id, personalData, callback, plansActivityId, consultantId]);

    return (
        <Box
            sx={{
                maxWidth: 500,
                margin: {xs: 3, lg: '0 auto'},
                padding: 3,
                backgroundColor: '#FFF',
            }}
        >
            {error && <Typography color="error">{error}</Typography>}
            <form id="form-checkout">
                <Input
                    sx={{display: "none"}}
                    id="form-checkout__cardExpirationDate"
                    label="MM/AAAA"
                    margin="normal"
                    value={validDate}
                />
                <FormControl fullWidth margin="normal" sx={{display: "none"}}>
                    <InputLabel htmlFor="form-checkout__cardholderEmail">E-mail</InputLabel>
                    <Input type="email" id="form-checkout__cardholderEmail" placeholder="E-mail"
                           value={personalData.email}/>
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="form-checkout__cardNumber">Número do cartão</InputLabel>
                    <InputMask mask="9999 9999 9999 9999" disabled={false} maskChar="">
                        {() => (
                            <Input
                                type="text"
                                id="form-checkout__cardNumber"
                                placeholder="Número do cartão"
                            />
                        )}
                    </InputMask>
                </FormControl>
                <Box sx={{display: "flex", flexDirection: "row", gap: 5}}>
                    <FormControl fullWidth margin="normal">
                        <InputLabel htmlFor="form-checkout__cardExpirationDate">MM/AA</InputLabel>
                        <InputMask mask="99/99" disabled={false} maskChar=""
                                   onChange={(e) => setValidDate(e.target.value)}>
                            {() => (
                                <Input
                                    id="form-checkout__cardExpirationDate"
                                    label="MM/AA"
                                    margin="normal"
                                />
                            )}
                        </InputMask>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                        <InputLabel htmlFor="form-checkout__securityCode">CVV</InputLabel>
                        <InputMask mask="9999" disabled={false} maskChar="">
                            {() => (
                                <Input
                                    type="text"
                                    id="form-checkout__securityCode"
                                    placeholder="CVV"
                                />
                            )}
                        </InputMask>
                    </FormControl>
                </Box>

                <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="form-checkout__cardholderName">Nome no cartão</InputLabel>
                    <Input type="text" id="form-checkout__cardholderName" placeholder="Nome no cartão"/>
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="form-checkout__identificationNumber">CPF</InputLabel>
                    <InputMask
                        value={cpf}
                        onChange={(e) => setCpf(e.target.value)}
                        mask="99999999999"
                        disabled={false}
                        maskChar=""
                    >
                        {() => (
                            <Input
                                type="text"
                                id="form-checkout__identificationNumber"
                                placeholder="CPF"
                            />
                        )}
                    </InputMask>
                </FormControl>

                <select id="form-checkout__installments" placeholder="Parcelas"
                        style={{
                            width: "100%",
                            height: 30,
                            marginTop: 15,
                            marginBottom: 15,
                            padding: 5,
                            border: "1px solid #ccc",
                            borderRadius: 5,
                            boxSizing: "border-box"
                        }}></select>
                <select id="form-checkout__identificationType" hidden></select>
                <select id="form-checkout__issuer" hidden></select>

                <LoadingButton
                    fullWidth
                    variant="contained"
                    style={{color: '#FFF', fontWeight: '600', fontSize: 16, backgroundColor: 'rgb(21 216 141 / 1)'}}
                    type="submit"
                    loading={processing}
                    disabled={processing}
                    sx={{mt: 2}}
                >
                    {processing ? '...' : 'Finalizar'}
                </LoadingButton>
            </form>

            {paymentStatus &&
                <Typography>{`Status do pagamento: ${paymentStatus} - Assim que seu pagamento for aprovado, você receberá um email.`}</Typography>}
        </Box>
    );
};

export default CreditCardPayment;