import {createRoot} from 'react-dom/client';
import {BrowserRouter as Router} from "react-router-dom";
// import reportWebVitals from './reportWebVitals';
import {Provider} from "./Helpers/Context";
import {datadogRum} from '@datadog/browser-rum'


import App from './App';

import './index.css';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

if (process.env.REACT_APP_ENV === 'PRD') {
    datadogRum.init({
        applicationId: '5e335e41-54af-4e04-b78e-72834f470d23',
        clientToken: 'pubb2c674189c1ebfd6e04926c32531cc80',
        site: 'us5.datadoghq.com',
        service: 'trainmeadmin',
        env: process.env.REACT_APP_ENV,
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
    });
}

root.render(
    <Provider>
        <Router>
            <App/>
        </Router>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
