import React from 'react';
import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import Subtitle from '../../../../Components/Subtitle/Subtitle';
import Title from '../../../../Components/Title/Title';

import './Chart.scss';

const Chart = ({height, financialReport}) => {
    const formatCurrency = (value) => {
        return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL',})
    };

    const CustomTooltip = ({active, payload = 0}) => {
        if (active) {
            return (
                <div className='tooltip'>
                    <div>
                        Previsto:
                        <img src={process.env.REACT_APP_PUBLIC_URL + 'arrow-right.svg'} alt=""/>
                        <span>
                            {formatCurrency(payload[0].value)}
                        </span>
                    </div>
                </div>
            );
        }

        return null;
    };

    return (
        <div className='chart'>
            <div className='chart-title'>
                <div>
                    <Title title="Relatório financeiro"/>
                    <Subtitle subtitle="Receita dos ultimos meses"/>
                </div>
                {/* <div className='chart-infos'>
                    <div className='chart-select'>
                        <div className='chart-select-image'>
                            <img src={process.env.REACT_APP_PUBLIC_URL + 'icon-graphic.svg'} alt="" />
                        </div>
                        <select>
                            <option>2023</option>
                        </select>
                    </div>
                </div> */}
            </div>

            <div className='chart-graphic'>
                {financialReport.length > 0 && (
                    <ResponsiveContainer width="100%" height={height}>
                        <AreaChart data={financialReport}>
                            <defs>
                                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="0.8">
                                    <stop offset="5%" stopColor="#1E306E" stopOpacity={0.4}/>
                                    <stop offset="95%" stopColor="#1E306E" stopOpacity={0}/>
                                </linearGradient>
                            </defs>
                            <Area type="monotone" dataKey="value" stroke="#1E306E" fillOpacity={1}
                                  fill="url(#colorUv)"/>

                            <YAxis width={100} tickFormatter={formatCurrency} axisLine={false} tickLine={false}/>
                            <XAxis dataKey="month" tickFormatter={(month) => month} axisLine={false} tickLine={false}/>

                            <Tooltip content={<CustomTooltip/>}/>
                            <CartesianGrid opacity={0.4} vertical={false}/>
                        </AreaChart>
                    </ResponsiveContainer>
                )}
                {financialReport.length === 0 && (
                    <div className='empty-list'>
                        <p>Ainda não há dados financeiros.</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Chart;