import React, {useState} from "react";
import {Box, Button, ButtonGroup, Chip, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import WalletIcon from '@mui/icons-material/Wallet';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import {Link} from "react-router-dom";

import Feedback from "../../../../Components/Feedback/Feedback";
import ModalDelete from "../../../../Components/ModalDelete/ModalDelete";
import PaymentLink from "../../../../Components/PaymentLink/PaymentLink";

import {DeletePlans,} from '../../../../Services/Plans/index';

const PlanItem = ({plan, setPlans}) => {
    const COLORS = ['#C7427B', '#1E306E', '#3ac49c', '#db3d60', '#c2c627', '#C7427B', '#1E306E', '#3ac49c', '#db3d60', '#c2c627'];
    const consultant = JSON.parse(sessionStorage.getItem('consultant'));
    const access_token = localStorage.getItem('access_token');
    const baseUrl = process.env.REACT_APP_NEW_STUDENT_BASE_URL;

    const [feedback, setFeedback] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('success');
    const [modalDelete, setModalDelete] = useState(false);

    const handleModalDelete = () => {
        window.dataLayer.push({
            'event': 'delete_plan_attempt',
            'plan_id': plan.id,
            'plan_name': plan.name,
            'plan_price': plan.price,
            'consultant_id': consultant.id
        });
        
        setModalDelete(!modalDelete);
    };

    const removePlan = async (id) => {
        try {
            if (id) {
                await DeletePlans(access_token, id)
                
                window.dataLayer.push({
                    'event': 'delete_plan_success',
                    'plan_id': id,
                    'plan_name': plan.name,
                    'consultant_id': consultant.id
                });
            }
            setPlans((prevState) => prevState.filter(p => p.id !== id));
            handleModalDelete();
        } catch {
            window.dataLayer.push({
                'event': 'delete_plan_failed',
                'plan_id': id,
                'plan_name': plan.name,
                'error_message': 'Não é possivel deletar um protocolo com clientes ativos',
                'consultant_id': consultant.id
            });
            
            setFeedback(true);
            setMessage('Não é possivel deletar um protocolo com clientes ativos.');
            setSeverity('error');
            handleModalDelete();
        }
    };

    const handleCopyPaymentLink = () => {
        window.dataLayer.push({
            'event': 'copy_payment_link',
            'plan_id': plan.id,
            'plan_name': plan.name,
            'plan_price': plan.price,
            'consultant_id': consultant.id
        });
    };

    return (
        <>

            <Box>
                {feedback && (
                    <Feedback severity={severity} text={message}/>
                )}

                <Grid container spacing={1} sx={{padding: 2}}>
                    <Grid item size={{xs: 12, md: 9}}>
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
                            <Box>
                                <Chip
                                    label={plan.name}
                                    color="primary"
                                    sx={{
                                        fontSize: 18,
                                        fontWeight: '700',
                                        bgcolor: `${COLORS[plan.id % 10 ?? 0]}1a`,
                                        color: COLORS[plan.id % 10 ?? 0]
                                    }}
                                />
                            </Box>
                            <Typography variant="subtitle2">{plan.description}</Typography>
                            <Box sx={{display: 'flex', flexDirection: 'row', gap: 1}}>
                                <WalletIcon/>
                                <Typography fontSize={14} color="#67666b">
                                    <Typography component="span" fontWeight={600}
                                                fontSize={14}>Valor:</Typography> R$ {plan.price}
                                </Typography>
                            </Box>
                            <Box sx={{display: 'flex', flexDirection: 'row', gap: 1}}>
                                <TimerOutlinedIcon/>
                                <Typography fontSize={14} color="#67666b">
                                    <Typography component="span" fontWeight={600}
                                                fontSize={14}>Duração:</Typography> {plan.weeks} semanas
                                </Typography>
                            </Box>
                            <Box sx={{display: 'flex', flexDirection: 'row', gap: 1}}>
                                <QuestionAnswerOutlinedIcon/>
                                <Typography fontSize={14} color="#67666b">
                                    <Typography component="span" fontWeight={600}
                                                fontSize={14}>Questionário:</Typography> {plan.quiz?.name}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item size={{xs: 12, md: 3}}>
                        <ButtonGroup variant="outlined" aria-label="Basic button group">
                            <Link to="/plans/edit" state={plan}>
                                <Button startIcon={<EditIcon/>} size="small">
                                    Editar
                                </Button>
                            </Link>
                            <Button component="label" onClick={handleModalDelete}
                                    startIcon={<DeleteIcon/>} size="small">
                                Apagar
                            </Button>
                        </ButtonGroup>
                        <Grid item>
                            <p>&nbsp;</p>
                        </Grid>
                        <PaymentLink
                            link={new URL(`${baseUrl}/novo-aluno?pid=${plan.id}&cn=${consultant.name}&cid=${consultant.id}`).href}
                            onCopy={handleCopyPaymentLink}
                        />
                    </Grid>
                </Grid>

            </Box>

            {modalDelete && (
                <ModalDelete
                    handleModal={handleModalDelete}
                    title="Excluir protocolo"
                    subtitle="Deseja excluir o protocolo:"
                    text={plan.name}
                    id={plan.id}
                    handleDelete={removePlan}
                />
            )
            }
        </>
    );
};

export default PlanItem;
