import {DeleteAsync, GetAsync, PostAsync, PutAsync} from '../api'

/**
 * Método responsável para criar um exercicio
 */
const CreateExercise = (token, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await PostAsync(`/exercises`, config, data);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * Método responsável por listar todos os exercicios
 */
const ListExercises = (token) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await GetAsync(`/exercises/library`, config)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável por listar todos os grupos musculares
 */
const ListMuscleGroups = (token) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await GetAsync(`/muscle-group`, config)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável para criar um exercicio customizado
 */
const CreateExerciseLibrary = (token, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await PostAsync(`/exercises/library`, config, data);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * Método responsável para deletar um exercicio customizado
 */
const DeleteExerciseLibrary = (token, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await DeleteAsync(`/exercises/library/${id}`, config);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * Método responsável para criar um exercicio de reposição
 */
const CreateExerciseReposition = (token, data, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await PostAsync(`/exercises/reposition/${id}`, config, data)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável por agregar os exercicios
 */
const LinkExercises = (token, mainExerciseId, linkedExercises) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await PostAsync(`/exercises/aggregate/${mainExerciseId}`, config, {linkedExercises: linkedExercises});
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável por atualizar um exercicio de reposição
 */
const UpdatedExerciseReposition = (token, data, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await PutAsync(`/exercises/reposition/${id}`, config, data)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável para editar um exercicio
 */
const UpdateExercise = (token, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await PutAsync(`/exercises`, config, data)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável para editar as ordens dos exercicios
 */
const UpdateExerciseOrders = (token, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await PutAsync(`/exercises/order`, config, data)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável para deletar um exercicio
 */
const DeleteExercise = (token, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await DeleteAsync(`/exercises/${id}`, config)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável para deletar um exercicio de reposição
 */
const DeleteExerciseReposition = (token, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await DeleteAsync(`/exercises/reposition/${id}`, config)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

/**
 * Método responsável por listar exercicios de um treino
 */
const ListTrainingExercises = (token, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await GetAsync(`/exercises/training/${id}`, config)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
};

export {
    CreateExercise,
    ListExercises,
    CreateExerciseReposition,
    UpdatedExerciseReposition,
    UpdateExercise,
    DeleteExercise,
    DeleteExerciseReposition,
    ListTrainingExercises,
    LinkExercises,
    CreateExerciseLibrary,
    DeleteExerciseLibrary,
    ListMuscleGroups,
    UpdateExerciseOrders
};
