import React from 'react';
import {makeStyles} from '@mui/styles';
import {IMaskInput} from 'react-imask';
import {FormControl, FormHelperText, InputLabel, OutlinedInput} from '@mui/material';

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: "100%",
        '& .MuiInput-underline:before': {
            borderBottomColor: 'rgba(0, 0, 0, 0.42)', // cor padrão do TextField
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'black', // cor da linha de foco
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottomColor: 'rgba(0, 0, 0, 0.87)', // cor da linha ao passar o mouse
        },
        '& .MuiFormLabel-root': {
            color: 'rgba(0, 0, 0, 0.54)', // cor do label padrão do TextField
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: 'black', // cor do label ao focar
        },
        '& .MuiFormHelperText-root': {
            color: 'rgba(0, 0, 0, 0.54)', // cor do texto de ajuda
        },
    },
    outlinedInput: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.23)', // cor padrão da borda
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.87)', // cor da borda ao passar o mouse
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'black', // cor da borda ao focar
        },
    }
}));
const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const {onChange, ...other} = props;
    return (
        <IMaskInput
            {...other}
            mask={props.mask}
            inputRef={ref}
            onAccept={(value) => onChange({target: {name: props.name, value}})}
            overwrite
        />
    );
});


const CustomInput = ({label, name, type, mask, errors, register, onChange, disabled = false, value = undefined}) => {
    const classes = useStyles();

    return (
        <FormControl className={classes.formControl}>
            <InputLabel>{label}</InputLabel>
            <OutlinedInput
                className={classes.outlinedInput}
                required
                fullWidth
                id={name}
                label={label}
                name={name}
                type={type}
                onChange={onChange}
                disabled={disabled}
                value={value}
                inputProps={{
                    mask: mask,
                    ...register(name, {
                        required: `O campo de ${label} é obrigatório.`,
                    }),
                }}
                inputComponent={TextMaskCustom}
            />
            {errors && <FormHelperText style={{color: 'red'}}>{errors.message}</FormHelperText>}
        </FormControl>
    );
};

export default CustomInput;