import {DeleteAsync, PostAsync, PutAsync} from '../api';

/**
 * Método responsável criar uma pergunta
 */
const CreateQuestion = (token, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await PostAsync(`/questions`, config, data);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * Método responsável por deletar um pergunta
 */
const DeleteQuestion = (token, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await DeleteAsync(`/questions/${id}`, config)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * Método responsável atualizar uma pergunta
 */
const UpdateQuestion = (token, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await PutAsync(`/questions`, config, data);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

export {
    CreateQuestion,
    DeleteQuestion,
    UpdateQuestion
}