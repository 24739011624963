import React, {useEffect, useState} from "react";
import {AlertTitle} from "@mui/material";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import Alert from '@mui/material/Alert';
import * as yup from "yup";

import PrimaryButton from "../../../../Components/PrimaryButton/PrimaryButton";
import {CpfMask} from '../../../../Helpers/Masks';
import Select from '../../../../Components/Select/Select';
import CustomInput from "../../../SignUp/Components/CustomInput/CustomInput";

import {ChangePix} from '../../../../Services/Consultants/index';

import "./Finance.scss";

const schema = yup.object().shape({
    pixKey: yup.string().required("O campo da chave é obrigatório!"),
});

const Finance = () => {
    const [success, setSucess] = useState(false);
    const access_token = localStorage.getItem('access_token');

    const consultant = JSON.parse(sessionStorage.getItem('consultant'));
    const {control, handleSubmit, formState: {errors}, setValue, register} = useForm({
        resolver: yupResolver(schema),
        defaultValues: consultant
    });

    const [mask, setMask] = useState('000.000.000-00');
    const [typeKey, setTypeKey] = useState(consultant?.keyType);
    const [pixKey, setPixKey] = useState(consultant?.pixKey);

    const saveData = async (values) => {
        try {
            const payload = {
                keyType: typeKey,
                pixKey: values.pixKey
            };
            await ChangePix(access_token, payload);
            const updatedConsultnat = {
                ...consultant,
                ...payload
            };
            sessionStorage.setItem('consultant', JSON.stringify(updatedConsultnat));
            setSucess(true);
        } catch {
            setSucess(false);
        }

        setTimeout(() => {
            setSucess(false);
        }, 2500)
    };

    const onChangeSelect = (data) => {
        setPixKey('pixKey', undefined);
        if (data === 'CPF') {
            setMask('000.000.000-00');
        } else if (data === 'CNPJ') {
            setMask('00.000.000/0000-00');
        } else if (data === 'Telefone') {
            setMask('(00) 00000-0000');
        } else if (data === 'E-mail') {
            setMask(undefined);
        } else if (data === 'Aleatória') {
            setMask('00000000-0000-0000-0000-000000000000');
        }

        setTypeKey(data);
    };

    useEffect(() => {
        setValue("pixKey", consultant?.keyType === 'CPF' ? CpfMask(consultant?.pixKey) : consultant?.pixKey);
    }, [consultant?.keyType, consultant?.pixKey, setValue]);

    return (
        <div className="my-data">
            {success && (
                <Alert severity="success">
                    <AlertTitle>Dados salvos com sucesso!</AlertTitle>
                </Alert>
            )}
            <div className="header">
                <div className="container-left">
                    <h3>Financeiro</h3>
                    <span>Edite sua forma de recebimento</span>
                </div>
                <div className="container-right">
                    <PrimaryButton text="Salvar Alterações"/>
                </div>
            </div>

            <div className="data">
                <Alert severity="info" sx={{mb: 1}}>
                    Utilizando nosso serviço de carteira, nós processaremos os pagamentos e faremos o repasse através da
                    chave PIX cadastrada.
                </Alert>
                <Alert severity="warning" sx={{mb: 3}}>
                    Atenção: para podermos realizar os repasses, o favorecido deve ser o mesmo registrado em nossa
                    plataforma.
                </Alert>
                <form className="form" id="form" onSubmit={handleSubmit(saveData)}>
                    <div className="column">
                        <div>
                            <Select
                                name="typeKey"
                                options={['CPF', 'CNPJ', 'Telefone', 'E-mail', 'Aleatória']}
                                defaultValue={typeKey}
                                setValue={onChangeSelect}
                                label="Tipo da chave"
                                control={control}
                                errors={errors.typeKey}
                            />
                        </div>
                        <div>
                            <CustomInput
                                label="Chave PIX"
                                name="pixKey"
                                mask={mask}
                                errors={errors.pixKey}
                                register={register}
                                value={pixKey}
                                onChange={(e) => {
                                    setValue('pixKey', e.target.value);
                                    setPixKey(e.target.value);
                                }}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Finance;
