import React, { useContext, useEffect, useState } from 'react';
import { CommonLoading } from 'react-loadingg';

import Header from '../../Components/Header/Header';
import Calendar from './Components/Calendar/Calendar';
import Chart from './Components/Chart/Chart';
import Cards from './Components/Cards/Cards';
import GeneralInfo from './Components/GeneralInfo/GeneralInfo';
import PageWrapper from '../../Components/PageWrapper/PageWrapper';
import OnboardingTour from '../../Components/OnboardingTour/OnboardingTour';

import { AppContext } from "../../Helpers/Context";

import { GetDashboardInfo } from '../../Services/Dashboard';

import './Home.scss';

const Home = () => {
    const date = new Date();
    const currentYear = date.getFullYear();
    const doneTour = localStorage.getItem('guided-tour-home');

    const [height] = useState(400);
    const [dashboardInfo, setDashboardInfo] = useState();
    const [loading, setLoading] = useState(true);
    const [run, setRun] = useState(!doneTour && window.innerWidth > 768);

    const targetPathMenu = '.page-wrapper .container .side-menu-container .menu-container ';
    const steps = [
        {
            target: `aaaa`,
            content: 'Página responsável pelos relátorios de acompanhamento dos protocolos e alunos.',
        }, {
            target: `${targetPathMenu} .dashboard`,
            content: 'Página responsável pelos relátorios de acompanhamento dos protocolos e alunos.',
            title: "Dashboard"
        },
        {
            target: `${targetPathMenu} .manage-students-menu`,
            content: 'Página responsável por fazer o acompanhamento dos treinos e dieta de seus alunos.',
            title: "Gerenciar Alunos"
        },
        {
            target: `${targetPathMenu} .questionnaires`,
            content: 'Página responsável pela criação das anamneses que serão enviadas à seus alunos.',
            title: "Questionários"
        },
        {
            target: `${targetPathMenu} .chat`,
            content: 'Chat com cada um de seus alunos.',
            title: "Chat"
        },
        {
            target: `${targetPathMenu} .plans`,
            content: 'Página responsável pela criação de protocolos, sendo o ponto de entrada para seus alunos. Nela, você poderá definir o valor, a duração e a anamnese dos protocolos oferecidos.',
            title: "Meus Protocolos"
        },
        {
            target: `${targetPathMenu} .my-account`,
            content: 'Página responsável pelo gerenciamentos dos seus dados e senhas.',
            title: "Minha Conta"
        },
        {
            target: '.calendar .calendar-title .primary-button',
            content: 'Calendário para criação de agendas com seus compromissos, com alunos ou não, podendo ser usado para acompanhar aulas de personal durante o dia.',
            title: "Agenda"
        },
        {
            target: '.whatsapp-support',
            content: 'Clique aqui quando precisar de ajuda ou tirar uma dúvida com nosso time de especialistas.',
            title: "Suporte"
        },
        {
            target: '.member-get-member',
            content: 'Convide um amigo para conhecer a plataforma.',
            title: "Convite"
        },
    ];

    const access_token = localStorage.getItem('access_token');
    const { signOut } = useContext(AppContext);

    useEffect(() => {
        window.dataLayer.push({
            'event': 'page_view',
            'page_name': 'dashboard',
            'user_type': 'consultant'
        });
    }, []);

    useEffect(() => {
        const fetchDashboardInfo = async () => {
            const consultant = JSON.parse(sessionStorage.getItem('consultant'));
            const response = await GetDashboardInfo(access_token, consultant.id);
            setDashboardInfo(response.data);
            setLoading(false);

            window.dataLayer.push({
                'event': 'dashboard_loaded',
                'total_students': response.data.totalStudents,
                'monthly_new_students': response.data.monthlyNewStudents,
                'inactive_students': response.data.inactives
            });
        };

        const consultantStorage = sessionStorage.getItem('consultant');
        if (!access_token || !consultantStorage) {
            signOut();
        } else {
            fetchDashboardInfo();
        }
    }, [access_token, signOut]);

    const handleTourComplete = () => {
        window.dataLayer.push({
            'event': 'tour_complete',
            'page': 'dashboard'
        });
    };

    return (
        <div className="home">
            {!loading && (
                <OnboardingTour 
                    page="home" 
                    run={run} 
                    setRun={setRun} 
                    steps={steps}
                    onComplete={handleTourComplete} 
                />
            )}

            <PageWrapper menuItem="/">
                <div className="content">
                    <Header
                        title="Dashboard Inicial"
                        subtitle="Últimos relatórios sobre os seus protocolos e alunos"
                    />
                    {!loading && (
                        <div className="home-body">
                            <Cards
                                totalStudents={dashboardInfo.totalStudents}
                                newStudents={dashboardInfo.monthlyNewStudents}
                                inactives={dashboardInfo.inactives}
                            />
                            <Chart
                                financialReport={dashboardInfo.financialReport}
                                height={height}
                            />
                            <GeneralInfo
                                currentYear={currentYear}
                                revenue={dashboardInfo.revenue}
                                plansInfo={dashboardInfo.plansInfo}
                            />
                        </div>
                    )}
                    {loading && (<CommonLoading color="#553872" size="large" />)}
                </div>

                <Calendar />
            </PageWrapper>
        </div>
    );
};

export default Home;