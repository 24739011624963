import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Carousel, {Modal, ModalGateway} from "react-images";
import {
    Avatar,
    Box,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import PendingIcon from '@mui/icons-material/Pending';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalDelete from '../../../../Components/ModalDelete/ModalDelete';

import InfiniteScroll from 'react-infinite-scroll-component';

import {Delete} from "../../../../Services/Students/index";

const StudentItem = ({students, fetchMoreData, hasMore, handleUpdate}) => {
    const navigate = useNavigate();

    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [photos, setPhotos] = useState([]);
    const [student, setStudent] = useState();
    const [modalDelete, setModalDelete] = useState(false);

    const handleModalDelete = (e, s) => {
        e.preventDefault();
        e.stopPropagation();
        setStudent(s);
        setModalDelete(!modalDelete);
    };

    const handleSetPhotos = (images) => {
        setPhotos([images]);
        setCurrentImage(0);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const getStudentStatus = (student) => {
        if (student.plans.length === 0)
            return <PendingIcon color="error"/>;

        if (student.hasPendingPayment)
            return <MoneyOffIcon color="error"/>;

        if (student.hasPendingProtocols)
            return <NewReleasesIcon color="warning"/>;

        return <CheckCircleIcon color="success"/>;
    };

    const handleDelete = async (studentId, e) => {
        await Delete(studentId);
        handleUpdate(studentId, true);
        setModalDelete(!modalDelete);
    };

    return (
        <Box className="student-item" sx={{mt: 1, borderRight: {md: 'none', lg: '1px solid #bcbdc8'}}}>
            <List sx={{
                width: '100%',
                height: {xs: '600px', lg: '90vh'},
                overflowY: 'scroll',
                scrollbarColor: 'white #f3f3f5',
                pt: 1
            }}>
                <InfiniteScroll
                    dataLength={students.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                >
                    {students.map((student, key) => (
                        <div key={key}>
                            <ListItemButton
                                onClick={() =>
                                    navigate(`/student/${student.id}`, {state: {student: student}})}
                                disableGutters
                                disableRipple
                                sx={{p: 0}}
                            >
                                <ListItem
                                    disableGutters
                                    disablePadding
                                    alignItems="center"
                                    sx={{
                                        bgcolor: 'background.paper',
                                        py: 2,
                                        px: 1
                                    }}
                                >
                                    <ListItemAvatar>
                                        <Avatar
                                            src={student.profileImagePath}
                                            alt="Imagem de perfil"
                                            onClick={() => handleSetPhotos(student.profileImagePath)}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={student.name}
                                    />
                                    <ListItemIcon>
                                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                                            {getStudentStatus(student)}
                                            {!student.forbidDelete && <IconButton edge="end" aria-label="delete"
                                                                                  onClick={(e) => handleModalDelete(e, student)}>
                                                <DeleteIcon sx={{color: '#c7427b'}}/>
                                            </IconButton>}
                                        </Box>
                                    </ListItemIcon>
                                </ListItem>
                            </ListItemButton>
                            <Divider variant="fullWidth" component="div"/>
                        </div>
                    ))}
                </InfiniteScroll>
            </List>

            <ModalGateway>
                {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                        <Carousel
                            currentIndex={currentImage}
                            views={photos.map(x => ({
                                source: x,
                                srcset: 'Perfil',
                                caption: 'Perfil'
                            }))}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
            {
                modalDelete &&
                <ModalDelete
                    handleModal={handleModalDelete}
                    title="Excluir aluno"
                    subtitle="Deseja excluir o aluno:"
                    text={student.name}
                    id={student.studentId}
                    handleDelete={handleDelete}
                />
            }
        </Box>
    );
};

export default StudentItem;