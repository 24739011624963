import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';

import TextFieldTrainMe from '../../../../Components/TextField/TextField';
import PrimaryButton from '../../../../Components/PrimaryButton/PrimaryButton';
import Subtitle from '../../../../Components/Subtitle/Subtitle';
import Title from '../../../../Components/Title/Title';
import Feedback from '../../../../Components/Feedback/Feedback';

import { UpdatedDiet } from "../../../../Services/Diets/index";

import './DietInfo.scss';

const schema = yup.object().shape({
    name: yup.string().required("O campo dia do treino é obrigatório!").nullable(),
});

const DietInfo = ({ clientId, dietId, name, dietStart, dietEnd, planActiveId }) => {
    const { control, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    const [success, setSucess] = useState(false);

    const access_token = localStorage.getItem('access_token');

    const onSubmitHandler = (data) => {
        const payload = {
            id: dietId,
            name: data.name,
            plansActivity: planActiveId,
        };

        UpdatedDiet(access_token, payload);

        setSucess(true);
        setTimeout(() => {
            setSucess(false);
        }, 2500);
    };

    useEffect(() => {
        if (dietId) {
            setValue('name', name);
            setValue('dietStart', dietStart);
            setValue('dietEnd', dietEnd);
        }
    }, [setValue, dietId, name, dietStart, dietEnd]);

    return (
        <form id="form" className="diet-info">
            <Accordion>
                <AccordionSummary
                    expandIcon={<img src={process.env.REACT_APP_PUBLIC_URL + "icon-accordion.svg"}
                        alt={"icon-accordion"} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div>
                        <Title title="Informações básicas da refeição" />
                        <Subtitle subtitle="Edite as informações abaixo" />
                    </div>
                </AccordionSummary>
                {success && (<Feedback severity="success" text="Informações da refeição alteradas sucesso" />)}

                <AccordionDetails>
                    <div className='accordion-container'>
                        <div className='accordion-item'>
                            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', gap: { xs: 1, md: 5 }, justifyContent: 'space-between' }}>
                                <Box>
                                    <TextFieldTrainMe
                                        control={control}
                                        name={'name'}
                                        label={'Nome da refeição'}
                                        fullWidth
                                        errors={errors.name}
                                    />
                                </Box>

                                <PrimaryButton text="Salvar alterações" onClick={handleSubmit(onSubmitHandler)} />
                            </Box>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </form>
    );
};

export default DietInfo;