import React from 'react';

import './SecondaryButton.scss';

const SecondaryButton = ({text, onClick, disabled = false, icon = "icon-add", className = ""}) => {
    return (
        <button className={`secondary-button ${disabled && 'disabled'} ${className}`} aria-label={text}
                onClick={onClick}>
            <img src={`${process.env.REACT_APP_PUBLIC_URL}${icon}.svg`} alt={"icon-add"} width={25} height={25}/>
            {text}
        </button>
    );
}

export default SecondaryButton;