import {DeleteAsync, GetAsync, PostAsync, PutAsync} from '../api'

/**
 * Método responsável por criar um questionario
 */
const CreateQuestionnaire = (token, name) => {
    return new Promise(async (resolve, reject) => {
        const data = {
            name: name
        }
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await PostAsync(`/quizzes`, config, data);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}


/**
 * Método responsável por deletar um questionario
 */
const DeleteQuestionnare = (token, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await DeleteAsync(`/quizzes/${id}`, config)
            resolve(response.data);
        } catch (err) {
            reject(err?.response?.data);
        }
    });
}

/**
 * Método responsável por respostas de um aluno
 */
const GetAnswered = (token, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await GetAsync(`/questions/answered/${id}`, config);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * Método responsável por enviar as respostas de um aluno
 */
const PostAnswers = (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await PostAsync('/questions/answered', {}, data);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * Método responsável por buscar um questionario
 */
const GetQuestionnare = (token, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await GetAsync(`/quizzes/${id}`, config)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * Método responsável por listar questionario de um usuario
 */
const GetQuestionnares = (token, consultantId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await GetAsync(`/quizzes/consultant/${consultantId}`, config)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * Método responsável por listar todos os questionarios do aluno
 */
const GetQuestionnaresUsers = (token, user_id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await PostAsync(`/quizzes/user/${user_id}`, config);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * Método responsável por listar questionario de um usuario
 */
const GetStudentQuizzes = (token, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await GetAsync(`/quizzes/user/${id}`, config)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * Método responsável por enviar um questionario para o aluno
 */
const SendQuestionnare = (token, user_id, quiz_id) => {
    return new Promise(async (resolve, reject) => {
        const data = {
            user: user_id,
            quiz: quiz_id
        }
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await PostAsync(`/quizzes/send`, config, data);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * Método responsável por atualizar um questionario
 */
const UpdateQuestionnaire = (token, id, name) => {
    return new Promise(async (resolve, reject) => {
        const data = {
            id: id,
            name: name
        }
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await PutAsync(`/quizzes`, config, data)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

/**
 * Método responsável por buscar um questionário
 */
const GetQuestionnaire = (name, consultant) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await GetAsync(`/quizzes/name/${name}/consultant/${consultant}`)
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

export {
    CreateQuestionnaire,
    DeleteQuestionnare,
    GetAnswered,
    GetQuestionnare,
    GetQuestionnares,
    GetQuestionnaresUsers,
    GetStudentQuizzes,
    UpdateQuestionnaire,
    SendQuestionnare,
    GetQuestionnaire,
    PostAnswers
};