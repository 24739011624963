import React, { useState } from 'react';

import ModalDelete from '../../../../Components/ModalDelete/ModalDelete';
import FormMeals from './Components/FormMeals/FormMeals';
import ModalAddMeal from './Components/ModalAddMeal/ModalAddMeal';

import { DeleteMeal } from "../../../../Services/Meals/index";

const MealsList = ({
    meals,
    setMeals,
    replenishmentMeals,
    setReplenishmentMeals,
    diet
}) => {
    const [indexExercise, setIndexExercise] = useState();
    const [modalDelete, setModalDelete] = useState(false);
    const [newMealType, setNewMealType] = useState(1);
    const [modalAddMeal, setModalAddMeal] = useState(false);
    const [meal, setMeal] = useState();
    const access_token = localStorage.getItem('access_token');

    const handleModalDelete = () => {
        setModalDelete(!modalDelete);
    };

    const handleModalEditMeal = (meal, key) => {
        setIndexExercise(key);
        setMeal(meal);
        setNewMealType(1);
        setModalAddMeal(!modalAddMeal);
    };

    const handleModalEditReplenishMeal = (meal, key) => {
        setIndexExercise(key);
        setMeal(meal);
        setNewMealType(2);
        setModalAddMeal(!modalAddMeal);
    };

    const handleModalDeleteMeal = (meal) => {
        setMeal(meal);
        setNewMealType(1);
        handleModalDelete();
    };

    const handleModalDeleteReplenishMeal = (meal) => {
        setMeal(meal);
        setNewMealType(2);
        handleModalDelete();
    };

    const handleDeleteMeal = (id) => {
        DeleteMeal(access_token, id);
        if (newMealType === 1) {
            setMeals(meals.filter(m => m.id !== id));
        } else {
            setReplenishmentMeals(replenishmentMeals.filter(m => m.id !== id));
        }
        handleModalDelete();
    };

    const handleModalAddMeal = (type) => {
        setMeal(undefined);
        setNewMealType(type);
        setModalAddMeal(!modalAddMeal);
    };

    return (
        <>
            <FormMeals
                title="Alimentos da refeição"
                diet={diet}
                reposition={false}
                meals={meals}
                description={diet.description}
                handleModalAddMeal={() => handleModalAddMeal(1)}
                handleModalEditMeal={handleModalEditMeal}
                handleModalDeleteMeal={handleModalDeleteMeal}
            />

            {meals && meals.length > 0 && <FormMeals
                title="Alimentos da refeição de reposição"
                reposition
                diet={diet}
                description={diet.repositionDescription}
                meals={replenishmentMeals}
                handleModalAddMeal={() => handleModalAddMeal(2)}
                handleModalEditMeal={handleModalEditReplenishMeal}
                handleModalDeleteMeal={handleModalDeleteReplenishMeal}
            />}

            {
                modalAddMeal &&
                <ModalAddMeal
                    mealId={diet.id}
                    handleModal={handleModalAddMeal}
                    mealIndex={indexExercise}
                    mealItem={meal}
                    isReposition={newMealType === 2}
                    meals={newMealType === 1 ? meals : replenishmentMeals}
                    setMeals={newMealType === 1 ? setMeals : setReplenishmentMeals}
                />
            }
            {
                modalDelete &&
                <ModalDelete
                    handleModal={handleModalDelete}
                    title="Excluir alimento"
                    subtitle="Deseja excluir o alimento:"
                    text={meal.name}
                    id={meal.id}
                    handleDelete={handleDeleteMeal}
                />
            }
        </>
    );
};

export default MealsList;