import React from 'react';

import './Card.scss';

const Card = ({title, subtitle, infos, showRedArrow = false}) => {
    return (
        <div className="card">
            <div className="card-left">
                <span>{title ? title : 'N/N'}</span>
                <h4>{subtitle ? subtitle : '0'}</h4>
                <div>
                    <strong> {infos}</strong>
                </div>
            </div>
            <div className={`card-right ${showRedArrow ? 'red' : 'green'}`}>
                {!showRedArrow &&
                    <img src={process.env.REACT_APP_PUBLIC_URL + 'arrow-card-green.svg'} alt="Flecha para cima"/>}
                {showRedArrow &&
                    <img src={process.env.REACT_APP_PUBLIC_URL + 'arrow-card-red.svg'} alt="Flecha para baixo"/>}
            </div>
        </div>
    );
}

export default Card;