import React, {useCallback, useEffect, useState} from "react";
import {CommonLoading} from 'react-loadingg';
import {Box, Grid, Typography} from "@mui/material";
import List from '@mui/material/List';
import InfiniteScroll from 'react-infinite-scroll-component';

import Header from "../../Components/Header/Header";
import PageWrapper from "../../Components/PageWrapper/PageWrapper";

import ChatListItem from "./components/chat-list-item/ChatListItem";
import Messages from "./components/Messages/Messages";

import {GetChatMessages, GetChats, ReadMessages} from "../../Services/Chats/index";

import {ChatContext} from '../../Context/ChatContext';

import "./Chat.scss";

const Chat = () => {
    const access_token = localStorage.getItem('access_token');

    const [show, setShow] = useState(true);
    const [chats, setChats] = useState([]);
    const [chatId, setChatId] = useState("");
    const [messages, setMessages] = useState([]);
    const [userInfo, setUserInfo] = useState();
    const [totalMessages, setTotalMessages] = useState(0);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const getChatMessages = useCallback(async (chat) => {
        const response = await GetChatMessages(access_token, 1, chat.id);
        const responseMessages = response.data?.rows;

        setTotalMessages(response.data?.count);
        if (responseMessages.length > 0) {
            const consultant = JSON.parse(sessionStorage.getItem('consultant'));
            // Faz leitura da ultima mensagem recebida e valida se deve adicionar no array
            const lastMessageReceivedStudent = responseMessages.find(m => m.sender.id !== consultant.id);

            setMessages((prevState) => {
                if (prevState.length === 0) return responseMessages;

                const lastMessageStudent = prevState.find(m => m.sender.id !== consultant.id);
                if (lastMessageReceivedStudent.id !== lastMessageStudent?.id) {
                    prevState.splice(0, 15, ...responseMessages);
                }

                return prevState;
            });

            ReadMessages(access_token, chat.id);
        } else {
            setMessages([]);
        }
    }, [access_token, setMessages]);

    const getChats = useCallback(async (page, setFirstChat) => {
        const response = await GetChats(access_token, page);
        const chatsRows = response.data?.rows;

        setChats((prevChats) => {
            const newChats = chatsRows.filter(
                newChat => !prevChats.some(existingChat => existingChat.id === newChat.id)
            );

            return [...prevChats, ...newChats];
        });

        setShow(true);
        if (chatsRows.length === 0) {
            setHasMore(false);
        }
        if (chatsRows.length > 0 && setFirstChat) {
            setChatId(chatsRows[0].id);
            setUserInfo(chatsRows[0].userInfo);
        }
    }, [access_token, setChatId]);

    useEffect(() => {
        getChats(1, true);
        const intervalId = setInterval(async () => {
            await getChats(page, false);
        }, 15000);

        return () => clearInterval(intervalId);
    }, [getChats, page]);

    useEffect(() => {
        if (chatId && chatId !== '') {
            getChatMessages(chats.find((c) => c.id === chatId));
        }
    }, [chatId, getChatMessages, chats]);

    const fetchMoreChats = () => {
        setPage((prevPage) => prevPage + 1);
        getChats(page + 1, false);
    };

    return (
        <div className="chat-container">
            <PageWrapper menuItem="/chat">
                <ChatContext.Provider value={{
                    messages,
                    setMessages,
                    userInfo,
                    setUserInfo,
                    chatId,
                    setChatId,
                    totalMessages,
                    setTotalMessages
                }}>
                    {show && (
                        <div className="content">
                            <>
                                <Header
                                    title="Gerenciar conversas"
                                    subtitle="Lista das suas conversas com alunos"
                                />

                                <Grid container rowSpacing={5} sx={{
                                    bgcolor: 'background.paper',
                                    mt: 2,
                                    width: '100%',
                                    border: '0.1px solid grey'
                                }}>
                                    <Grid item xs={12} md={5} lg={4} xl={3} sx={{borderRight: '0.1px solid grey'}}>
                                        <Box component="div" sx={{p: 2, borderBottom: '0.1px solid grey', pt: 0.4}}>
                                            <Typography variant="h4" sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: 1,
                                            }}>
                                                Chats <Typography variant="caption"
                                                                  fontSize={20}>({chats.length})</Typography>
                                            </Typography>
                                        </Box>
                                        <List sx={{pt: 0, overflowY: 'scroll', height: '750px'}}>
                                            <InfiniteScroll
                                                dataLength={chats.length}
                                                next={fetchMoreChats}
                                                hasMore={hasMore}
                                                loader={<h4>Carregando...</h4>}
                                            >
                                                {(chats && chats.length > 0) && chats.map((chat) => (
                                                    <ChatListItem
                                                        chat={chat}
                                                        key={chat.id}
                                                    />
                                                ))}
                                            </InfiniteScroll>
                                        </List>
                                    </Grid>

                                    <Messages/>
                                </Grid>
                            </>
                        </div>
                    )}
                    {!show && (
                        <CommonLoading color="#553872" size="large"/>
                    )}
                </ChatContext.Provider>
            </PageWrapper>
        </div>
    );
}

export default Chat;