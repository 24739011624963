import React, {useEffect, useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Box, Divider, Stack,} from '@mui/material';
import {Modal, ModalGateway} from "react-images";
import InfiniteScroll from 'react-infinite-scroll-component';

import Subtitle from '../../../../Components/Subtitle/Subtitle';
import Title from '../../../../Components/Title/Title';
import SecondaryButton from '../../../../Components/SecondaryButton/SecondaryButton';
import ExercisePlayer from '../../../../Components/ExercisePlayer/ExercisePlayer';

import ModalNewExercise from './Components/ModalNewExercise/ModalNewExercise';
import ModalNewExerciseLibrary from './Components/ModalNewExerciseLibrary/ModalNewExerciseLibrary';
import ModalNewCardio from './Components/ModalNewCardio/ModalNewCardio';
import DraggableItem from '../DraggableItem/DraggableItem';

import {CreateExerciseLibrary, DeleteExerciseLibrary, ListExercises} from '../../../../Services/Exercises/index'

import './Library.scss';

const Library = ({trainingId, client}) => {
    const APP_PATH = process.env.REACT_APP_PUBLIC_URL;
    const CARDIO_GROUP_ID = Number(process.env.REACT_APP_CARDIO_GROUP_ID);
    const access_token = localStorage.getItem('access_token');
    const expiredPlan = new Date(client.plan.dueDate) < new Date();

    const [search, setSearch] = useState("");
    const [searching, setSearching] = useState(false);
    const [searchedExercises, setSearchedExercises] = useState([]);
    const [groups, setGroups] = useState([]);
    const [libraries, setLibraries] = useState([]);
    const [showModalExercise, setShowModalExercise] = useState(false);
    const [showModalCardio, setShowModalCardio] = useState(false);
    const [showModalExerciseLibrary, setShowModalExerciseLibrary] = useState(false);
    const [currentExercise, setCurrentExercise] = useState();
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);

    const closeLightbox = () => {
        setViewerIsOpen(false);
    };

    const handleSetPhotos = (image) => {
        setCurrentImage(image);
        setViewerIsOpen(true);
    };

    const fetchData = async () => {
        const response = await ListExercises(access_token);
        setLibraries(response.data);
        setGroups(Object.keys(response.data));
    };

    const openNewExerciseModal = (exercise) => {
        if (expiredPlan) return;

        setCurrentExercise(exercise);
        if (exercise.group.id === CARDIO_GROUP_ID) {
            setShowModalCardio(true);
        } else {
            setShowModalExercise(true);
        }
    };

    const handleFilterChange = (e) => {
        const searchedValue = e.target.value;
        setSearch(searchedValue);

        if (searchedValue === "") {
            setLibraries(libraries);
            setSearching(false);
            setSearchedExercises([]);
        } else {
            let filteredExs = [];
            groups.forEach((g) => {
                filteredExs = filteredExs.concat(
                    libraries[g].filter((ex) => ex.name.toLocaleLowerCase().includes(searchedValue.toLocaleLowerCase()))
                );
            });
            setSearchedExercises(filteredExs);
            setSearching(true);
        }
    };

    const createExerciseLibrary = async (exercise) => {
        await CreateExerciseLibrary(access_token, exercise);
        fetchData();
    }

    const deleteExerciseLibrary = async (exercise) => {
        await DeleteExerciseLibrary(access_token, exercise.id);
        fetchData();
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="library">
                <div className='library-header'>
                    <div>
                        <Title title="Biblioteca de exercícios"/>
                        <Subtitle subtitle="Pesquise o exercício que procura"/>
                    </div>

                    <div className="search-container">
                        <div className="search">
                            <img src={APP_PATH + "icon-search.svg"} alt="search"/>
                            <input
                                type="search"
                                placeholder="Pesquise o exercício"
                                value={search}
                                onChange={(e) => handleFilterChange(e)}
                            />
                        </div>
                    </div>
                </div>

                {(searching && searchedExercises) && (
                    <div className="filtered-exercises">
                        <Stack spacing={3} divider={<Divider orientation="horizontal" flexItem/>}>
                            <div
                                id="scrollableDiv"
                                style={{
                                    height: "85vh",
                                    width: "100%",
                                    overflow: "none",
                                    display: 'flex',
                                }}>
                                <InfiniteScroll
                                    dataLength={searchedExercises.length}
                                    // next={handleScroll}
                                    hasMore={false}
                                    height="100%"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        scrollbarColor: "grey white",
                                        borderTop: "0.1px solid grey"
                                    }}
                                    scrollableTarget="scrollableDiv"
                                >
                                    {searchedExercises.map((ex, key) => (
                                        <DraggableItem item={ex} context="all">
                                            <div key={key} className='library-item'>
                                                <div className="item-header">
                                                    <div>
                                                        <h4>{ex.name}</h4>
                                                        <Subtitle subtitle={ex.description}/>
                                                    </div>

                                                    <div className="item-right">
                                                        <button
                                                            onClick={() => openNewExerciseModal(ex)}
                                                            disabled={expiredPlan}
                                                            className={expiredPlan ? 'disabled' : ''}
                                                        >+
                                                        </button>
                                                        {
                                                            ex.custom ? (
                                                                <img src={APP_PATH + "icon-delete.svg"}
                                                                     alt="icon-delete"
                                                                     onClick={() => deleteExerciseLibrary(ex)}/>
                                                            ) : <></>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='item-left'>
                                                    <img
                                                        src={ex.image}
                                                        alt={ex.name}
                                                        width="100%"
                                                        height={200}
                                                        onClick={() => handleSetPhotos(ex.media)}
                                                    />
                                                </div>
                                            </div>
                                        </DraggableItem>
                                    ))}
                                </InfiniteScroll>
                            </div>
                        </Stack>
                    </div>
                )}

                {(!searching && libraries) && (
                    <div
                        id="scrollableDiv"
                        style={{
                            height: window.outerHeight,
                            width: "100%",
                            overflow: "none",
                            display: 'flex',
                        }}>
                        <InfiniteScroll
                            dataLength={searchedExercises.length}
                            hasMore={false}
                            height="100%"
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                scrollbarColor: "grey white",
                                borderTop: "0.1px solid grey"
                            }}
                            scrollableTarget="scrollableDiv"
                        >
                            {groups.map((group, key) => (
                                <Accordion key={key}>
                                    <AccordionSummary
                                        expandIcon={<img src={APP_PATH + "icon-accordion.svg"} alt="icon-accordion"/>}>
                                        <Title title={group}/>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Stack spacing={3} divider={<Divider orientation="horizontal" flexItem/>}>
                                            {libraries[group].map((ex, key) => (
                                                <DraggableItem item={ex} context="all">
                                                    <div key={key} className='library-item'>
                                                        <div className="item-header">
                                                            <div>
                                                                <h4>{ex.name}</h4>
                                                                <Subtitle subtitle={ex.description}/>
                                                            </div>

                                                            <div className="item-right">
                                                                <button
                                                                    onClick={() => openNewExerciseModal(ex)}
                                                                    disabled={expiredPlan}
                                                                >+
                                                                </button>
                                                                {
                                                                    ex.custom ? (
                                                                        <img src={APP_PATH + "icon-delete.svg"}
                                                                             alt="icon-delete"
                                                                             onClick={() => deleteExerciseLibrary(ex)}/>
                                                                    ) : <></>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='item-left'>
                                                            <img
                                                                src={ex.image}
                                                                alt={ex.name}
                                                                width="100%"
                                                                height={200}
                                                                onClick={() => handleSetPhotos(ex.media)}
                                                            />
                                                        </div>
                                                    </div>
                                                </DraggableItem>
                                            ))}
                                        </Stack>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </InfiniteScroll>
                    </div>
                )}
                <Box mb={2}>
                    <SecondaryButton onClick={() => setShowModalExerciseLibrary(true)} text="Customizado"
                                     icon="icon-add"/>
                </Box>
            </div>

            <ModalGateway>
                {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                        <ExercisePlayer
                            src={currentImage}
                            name="Exercicio"
                        />
                    </Modal>
                ) : null}
            </ModalGateway>

            <ModalNewExercise
                exercise={currentExercise}
                trainingId={trainingId}
                open={showModalExercise}
                handleModal={() => setShowModalExercise(!showModalExercise)}
            />
            <ModalNewCardio
                open={showModalCardio}
                exercise={currentExercise}
                trainingId={trainingId}
                handleModal={() => setShowModalCardio(!showModalCardio)}
            />
            <ModalNewExerciseLibrary
                onSubmit={createExerciseLibrary}
                open={showModalExerciseLibrary}
                handleModal={() => setShowModalExerciseLibrary(!showModalExerciseLibrary)}
            />
        </>
    );
};

export default Library;