import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Avatar, Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

import Subtitle from '../Subtitle/Subtitle';
import Title from '../Title/Title';
import { ArrowBackOutlined } from '@mui/icons-material';

import './Header.scss';

const Header = ({ title, subtitle, showReturn = false, showInfo = true }) => {
    const consultant = JSON.parse(sessionStorage.getItem('consultant'));
    const navigate = useNavigate();

    const [timeLeft, setTimeLeft] = useState(null);

    useEffect(() => {
        const checkPlan = () => {
            const createdAt = new Date(consultant.createdAt);
            const expiryDate = new Date(createdAt.getTime() + (5 * 24 * 60 * 60 * 1000));
            const now = new Date();
            const timeLeft = expiryDate - now;

            if (timeLeft <= 0 && !consultant.plan && consultant.referralId !== 'betatesters') {
                navigate('/upgrade', { state: { expired: true } });
                return;
            }

            const days = Math.floor(timeLeft / (24 * 60 * 60 * 1000));
            const hours = Math.floor((timeLeft % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));

            setTimeLeft(`${days > 0 ? `${days}d ` : ''} ${hours}h`);
        };
        checkPlan();
    }, [consultant, navigate]);

    return (
        <>
            <Grid container spacing={1}>
                <Grid size={{ xs: 12 }}>
                    {showReturn && (
                        <Button variant="outlined" onClick={() => navigate(-1)} startIcon={<ArrowBackOutlined />}>
                            Voltar
                        </Button>
                    )}
                </Grid>
                <Grid container size={{ xs: 12, md: 9 }}>
                    <Grid size={{ xs: 8, md: 8 }}>
                        <Title title={title} />
                        <Subtitle subtitle={subtitle} />
                    </Grid>
                    <Grid size={{ xs: 4, md: 4 }} alignItems="flex-end">
                        {showInfo && (
                            <>
                                {(!consultant.plan && consultant.referralId !== 'betatesters') && <>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: { xs: 'column', md: 'row' },
                                        alignItems: 'center',
                                        gap: 1
                                    }}>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center'
                                        }}>
                                            <Typography variant="caption" color="error">
                                                Acesso expira em:
                                            </Typography>
                                            <Typography variant="body2" color="error" fontWeight="bold">
                                                {timeLeft}
                                            </Typography>
                                        </Box>
                                        <Button sx={{
                                            background: 'linear-gradient(76.71deg, rgb(253, 200, 48) -18.89%, rgb(243, 115, 53) 99.03%)',
                                            color: '#fff',
                                            fontWeight: 'bold',
                                            padding: '10px 20px',
                                            borderRadius: '5px',
                                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                                            animation: 'blink 1.5s infinite', // Adiciona a animação
                                            '@keyframes blink': {
                                                '0%': { opacity: 1 },
                                                '50%': { opacity: 0.5 },
                                                '100%': { opacity: 1 },
                                            },
                                        }} onClick={() => navigate('/upgrade')}>ASSINAR</Button>
                                    </Box>
                                </>}
                            </>
                        )}
                    </Grid>
                </Grid>
                <Grid size={{ xs: 6, md: 3 }} sx={{ display: { xs: 'none', sm: 'block' }, alignItems: "flex-end" }}>
                    <Grid container display="flex">
                        <Grid size={{ xs: 2 }}>
                            <Avatar
                                alt={consultant.name}
                                src={consultant?.profileImagePath ? `${process.env.REACT_APP_S3_BUCKET_URL}/${consultant.profileImagePath}?${new Date().getMilliseconds()}`
                                    : "https://img.freepik.com/vetores-premium/icone-de-um-rosto-de-homem-com-pele-clara_238404-798.jpg"} />
                        </Grid>
                        <Grid size={{ xs: 10 }}>
                            <strong>{consultant?.name}</strong> <br></br>
                            <span>{consultant?.occupation}</span>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default Header;