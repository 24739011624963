import React from 'react';
import {LoadingButton} from '@mui/lab';

import './PrimaryButton.scss';

const PrimaryButton = ({
                           text,
                           onClick,
                           disabled,
                           form = "form",
                           type = "submit",
                           className,
                           loading = false,
                           children
                       }) => {
    const handleOnClick = (e) => {
        if (disabled) {
            e.preventDefault();
        } else if (onClick) {
            onClick(e);
        }
    };

    return (
        <>
            {children ? (
                <LoadingButton
                    type={type}
                    form={form}
                    aria-label={text}
                    onClick={handleOnClick}
                    className={`primary-button ${disabled ? 'disabled' : ''} ${className}`}
                    sx={{mt: 3, mb: 2, fontWeight: '500'}}
                    loading={loading}
                    variant="contained"
                > {children}
                </LoadingButton>
            ) : (
                <LoadingButton
                    type={type}
                    form={form}
                    aria-label={text}
                    onClick={handleOnClick}
                    className={`primary-button ${disabled ? 'disabled' : ''} ${className}`}
                    sx={{mt: 3, mb: 2, fontWeight: '500'}}
                    loading={loading}
                    variant="contained"
                > {text}
                </LoadingButton>
            )}
        </>
    );
};

export default PrimaryButton;