import React from 'react';
import {TextField} from '@mui/material';
import {Controller} from 'react-hook-form';

import './TextArea.scss';

const TextArea = ({name, label, control, fullWidth = false, errors, placeholder = undefined}) => {
    return (
        <>
            <Controller
                name={name}
                control={control}
                render={({field}) =>
                    <TextField
                        {...field}
                        name={name}
                        label={label}
                        variant="outlined"
                        fullWidth={fullWidth}
                        rows={4}
                        placeholder={placeholder}
                        multiline={true}
                    />
                }
            />
            {errors && <p className='error'>{errors.message}</p>}
        </>
    );
}

export default TextArea;