/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Checkbox, FormGroup, FormControlLabel, Box, Modal } from '@mui/material';

import Subtitle from '../../../../Components/Subtitle/Subtitle';
import Title from '../../../../Components/Title/Title';
import TextFieldTrainMe from '../../../../Components/TextField/TextField';
import TextArea from '../../../../Components/TextArea/TextArea';
import PrimaryButton from '../../../../Components/PrimaryButton/PrimaryButton';

import { CreateTraining } from "../../../../Services/Trainings/index";

import './ModalAddTraining.scss';

const schema = yup.object().shape({
    name: yup.string(),
    comments: yup.string(),
});

const ModalAddTraining = ({ modal, handleModal, client }) => {
    const weekDays = [
        {
            label: 'Segunda-Feira',
            value: 'SEG',
            checked: false
        },
        {
            label: 'Terça-Feira',
            value: 'TER',
            checked: false
        },
        {
            label: 'Quarta-Feira',
            value: 'QUA',
            checked: false
        },
        {
            label: 'Quinta-Feira',
            value: 'QUI',
            checked: false
        },
        {
            label: 'Sexta-Feira',
            value: 'SEX',
            checked: false
        },
        {
            label: 'Sábado',
            value: 'SAB',
            checked: false
        },
        {
            label: 'Domingo',
            value: 'DOM',
            checked: false
        }
    ];
    const access_token = localStorage.getItem('access_token')

    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            inicialDate: new Date(),
            endDate: new Date(),
        }
    });

    const [days, setDays] = useState(weekDays);
    const [weeks, setWeeks] = useState([]);
    const [showError, setShowError] = useState(false);
    const [showDayError, setShowDayError] = useState(false);
    const [restDay, setRestDay] = useState(false);
    const [loading, setLoading] = useState(false);

    const onSelectWeek = (week, checked) => {
        setWeeks((weeks) => {
            return weeks.map((w, i) => {
                if (w === week) return { ...w, checked: Boolean(checked) };
                return w;
            });
        });
    };

    const onSelectDay = (day, checked) => {
        setDays((days) => {
            return days.map((d, i) => {
                if (d === day) return { ...d, checked: Boolean(checked) };
                return d;
            });
        });
    };

    const handleCloseModal = (updated) => {
        handleModal(updated);
    };

    const onSubmitHandler = async (data) => {
        setLoading(true);
        setShowDayError(false);

        const selectedDays = days.filter((d) => d.checked);
        if ((!selectedDays || selectedDays.length === 0)) {
            setShowDayError(true);
            setLoading(false);
            return;
        }
        const selectedWeeks = !client?.presetId ? weeks.filter((w) => w.checked) : [{ value: 0 }];
        if ((!selectedWeeks || selectedWeeks.length === 0) && !client?.presetId) {
            setShowError(true);
            setLoading(false);
            return;
        }

        const weeksPayload = selectedWeeks.map((w) => ({
            week: w.value,
            days: selectedDays.map((d) => ({ day: d.value, finished: false })),
        }));

        let createPayload = {
            name: restDay ? 'Descanso' : data.name,
            weeks: weeksPayload,
            trainingStart: data.inicialDate,
            trainingEnd: data.endDate,
            restDay: restDay,
            comments: restDay ? 'Descanso' : data.comments || ''
        };

        if (client?.presetId) {
            createPayload = {
                ...createPayload,
                preset: client?.presetId,
            }
        } else {
            createPayload = {
                ...createPayload,
                plansActivity: client.plan.id
            }
        }

        await CreateTraining(access_token, createPayload);

        handleCloseModal(true);
        reset();
        setLoading(false);
    };

    const registerWeeks = () => {
        const weeksElem = [];
        for (let i = 0; i < client.plan.weeks; i++) {
            weeksElem.push({
                label: i + 1,
                value: i + 1,
                checked: true
            });
        }
        setWeeks(weeksElem);
    };


    useEffect(() => {
        setDays(weekDays);

        if (!client.presetId) {
            registerWeeks();
        }

        if (!modal) {
            setLoading(false);
            setShowError(false);
            setShowDayError(false);
            setRestDay(false);
            reset();
        }
    }, [modal]);

    return (
        <Modal
            open={modal}
            onClose={() => handleModal(false)}
            className='modal-plans'
            id="modal-plans"
        >
            <div className='modal-add-training'>
                <form className='modal-add' id="form" onSubmit={handleSubmit(onSubmitHandler)}>

                    <div className='titles'>
                        <div>
                            <div className='div-image'>
                                <img src={process.env.REACT_APP_PUBLIC_URL + "icon-modal-training.svg"}
                                    alt={"icon-modal-training"} />
                            </div>
                            <div className='div-title'>
                                <Title title={'Adicionar treino'} />
                                <p>Insira as informações abaixo </p>
                            </div>
                        </div>

                        <img src={process.env.REACT_APP_PUBLIC_URL + "icon-close.svg"} alt={"icon-close"}
                            onClick={() => handleCloseModal(false)} />
                    </div>

                    {!restDay &&
                        <div className='new-quiz'>
                            <div className='input'>
                                <TextFieldTrainMe
                                    control={control}
                                    defaultValue=""
                                    name={'name'}
                                    label={'Insira o nome do treino'}
                                    fullWidth
                                    errors={errors.name}
                                />
                            </div>
                        </div>
                    }

                    <div>
                        <div className='new-quiz'>
                            <div className='input'>
                                <FormControlLabel style={{ margin: 0 }} control={
                                    <Checkbox
                                        name="restDay"
                                        value={restDay}
                                        id="restDay"
                                        checked={restDay}
                                        onChange={(e) => setRestDay(!restDay)}
                                    />
                                } label="Dia de descanso" />
                            </div>
                            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, overflowY: { xs: 'scroll', sm: 'unset' }, height: { xs: '350px', sm: 'auto' } }}>
                                <div className='new-quiz'>
                                    <Subtitle subtitle="Dia(s):" />
                                    <FormGroup className="days-form-group">
                                        {days.map((day, i) => (
                                            <FormControlLabel style={{ margin: 0 }} key={day.value} control={
                                                <Checkbox
                                                    name={day.label}
                                                    value={day.value}
                                                    id={day.value.toString()}
                                                    checked={day.checked}
                                                    onChange={(e) => onSelectDay(day, !day.checked)}
                                                />
                                            } label={day.label} />
                                        ))}
                                    </FormGroup>
                                    {showDayError && <p className='error'>Selecione pelo menos um dia.</p>}
                                </div>

                                {!client?.presetId && <div className="new-quiz">
                                    <Subtitle subtitle="Semana(s):" />
                                    <FormGroup className="weeks-form-group">
                                        {weeks.map((week, i) => (
                                            <FormControlLabel style={{ margin: 0 }} key={week.value} control={
                                                <Checkbox
                                                    name={week.label.toString()}
                                                    value={week.value}
                                                    id={week.value.toString()}
                                                    checked={week.checked}
                                                    onChange={(e) => onSelectWeek(week, !week.checked)}
                                                />
                                            } label={week.label} />
                                        ))}
                                    </FormGroup>
                                    {showError && <p className='error'>Selecione pelo menos uma semana.</p>}
                                </div>}
                            </Box>
                        </div>
                    </div>

                    {!restDay &&
                        <div className='new-quiz'>
                            <Subtitle subtitle="Observações para o aluno (opcional):" />

                            <div className='textarea'>
                                <TextArea
                                    control={control}
                                    name={'comments'}
                                    label={'Ex: Fazer duas refeições antes do treino.'}
                                    fullWidth
                                    errors={errors.comments}
                                />
                            </div>
                        </div>
                    }

                    <Box className='button' sx={{ display: 'flex', justifyContent: 'center' }}>
                        <PrimaryButton text="Criar treino" loading={loading} />
                    </Box>
                </form>
            </div>
        </Modal>
    );
};

export default ModalAddTraining;