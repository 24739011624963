import React, { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Container, IconButton, Step, StepLabel, Stepper, Typography, } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import ConsultantPlans from "./components/ConsultantPlans/ConsultantPlans";
import CreditCardPayment from "./components/CreditCardPayment/CreditCardPayment";

import "./Upgrade.scss";

const Upgrade = () => {
    const navigate = useNavigate();
    const { expired } = useLocation().state || {};

    const [currentStep, setCurrentStep] = useState(1);
    const [method, setMethod] = useState('A');
    const [plan, setPlan] = useState();

    const onSelectPlan = (step, plan) => {
        setCurrentStep(step);
        setPlan(plan);
        window.dataLayer.push({
            'event': 'select_plan',
            'plan_id': plan.id,
            'plan_name': plan.name, 
            'plan_price': method === 'A' ? plan.anualValue : plan.monthlyValue,
            'plan_period': method === 'A' ? 'annual' : 'monthly'
        });
    };

    return (
        <Box className="upgrade">
            <Box className="content">
                <Box sx={{
                    display: { xs: 'none', sm: "flex" },
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingLeft: 10,
                    paddingRight: 10,
                    marginTop: 2,
                    marginBottom: 2,
                    paddingBottom: 2,
                    boxShadow: "rgb(236, 238, 241) 0px -1px 0px inset"
                }}>
                    <img className="logo" src="/logo-logotipo.png" alt="logo" height={50} />
                    <Stepper activeStep={currentStep} connector=" > ">
                        <Step key={1}>
                            <StepLabel>
                                <Typography className="step-label">Escolha um plano</Typography>
                            </StepLabel>
                        </Step>
                        <Step key={2}>
                            <StepLabel>
                                <Typography className="step-label">Confirmar Pagamento</Typography>
                            </StepLabel>
                        </Step>
                    </Stepper>
                    {!expired && <IconButton onClick={() => navigate(-1)}>
                        <CloseIcon />
                    </IconButton>}
                    {expired && <Box></Box>}
                </Box>
                <Box sx={{
                    display: {
                        xs: 'flex',
                        sm: 'none'
                    },
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    px: 1
                }}>
                    <img className="logo" src="/logo-logotipo.png" alt="logo" height={50} />
                    <IconButton onClick={() => navigate(-1)}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Container>
                    {currentStep === 1 && (
                        <>
                            <Box
                                marginBottom={5}
                                marginTop={5}
                                sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', alignItems: 'center' }}
                            >
                                <Box>
                                    <Typography variant="h3">Escolha um plano</Typography>
                                    <Typography>Transforme sua carreira e assinando o plano que melhor atende suas necessidades!</Typography>
                                    {expired && <Typography variant="subtitle1" fontSize={18} color="error">Seu plano expirou, assine um novo plano para continuar utilizando a plataforma.</Typography>}
                                </Box>

                                <Box>
                                    <Button
                                        onClick={() => setMethod('M')}
                                        sx={{
                                            backgroundColor: method === 'M' ? '#1e306e' : 'rgb(227, 227, 233)',
                                            ":hover": { backgroundColor: method === 'M' ? '#1e306e' : 'rgb(227, 227, 233)' },
                                            color: method === 'M' ? '#FFF' : '#3d3b46',
                                            fontWeight: method === 'M' ? '600' : '300',
                                            width: 150,
                                            cursor: 'pointer'
                                        }}>
                                        MENSAL
                                    </Button>
                                    <Button
                                        onClick={() => setMethod('A')}
                                        sx={{
                                            backgroundColor: method === 'A' ? '#1e306e' : 'rgb(227, 227, 233)',
                                            ":hover": { backgroundColor: method === 'A' ? '#1e306e' : 'rgb(227, 227, 233)' },
                                            ":before": {
                                                content: '"17% OFF"',
                                                fontSize: 10,
                                                color: '#FFF',
                                                position: 'absolute',
                                                top: '38%',
                                                left: '73%',
                                                width: '32%',
                                                height: '40%',
                                                borderRadius: '10px',
                                                backgroundColor: '#3d3b46',
                                                transform: 'translateY(-60%) rotate(35deg)',
                                                pointerEvents: 'none'
                                            },
                                            color: method === 'A' ? '#FFF' : '#3d3b46',
                                            fontWeight: method === 'A' ? '600' : '400',
                                            width: 150,
                                            cursor: 'pointer'
                                        }}>
                                        ANUAL
                                    </Button>
                                </Box>
                            </Box>
                            <ConsultantPlans callback={onSelectPlan} method={method} />
                        </>
                    )}
                    {currentStep === 2 && (
                        <Box
                            marginBottom={5}
                            marginTop={5}
                            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: 8 }}
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Box>
                                    <Typography variant="h3">Melhorando seu negócio.</Typography>
                                    <Typography>Você está próximo de alavancar seu negócio.</Typography>
                                </Box>
                                <Button onClick={() => setCurrentStep(1)} variant="body2">Voltar</Button>
                            </Box>
                            <CreditCardPayment
                                setCurrentStep={setCurrentStep}
                                plan={plan}
                                planName={plan.name}
                                method={method}
                            />
                        </Box>
                    )}
                    {currentStep === 3 && (
                        <Box
                            marginBottom={5}
                            marginTop={5}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: 2
                            }}
                        >
                            <Typography>Parabéns, você está no caminho para alavancar seu negocio!</Typography>
                            <Typography>Assim que seu pagamento for aprovado, você terá acesso completo à
                                plataforma.</Typography>
                            <Typography>O pagamento poderá levar até algumas horas para ser processado.</Typography>
                        </Box>
                    )}
                </Container>
            </Box>
        </Box>
    );
};

export default Upgrade;
