import React from 'react';
import {Avatar, Box, Typography} from "@mui/material";

import "./ChatTopBar.scss";

const ChatTopBar = ({userInfo}) => {
    return (
        <Box sx={{display: "flex", gap: 2}}>
            <Box paddingLeft={2} pt={0}>
                <Avatar
                    src={userInfo?.profileImagePath ? `${process.env.REACT_APP_S3_BUCKET_URL}/${userInfo.profileImagePath}` : ""}
                    sx={{width: 52, height: 52}}
                />
            </Box>

            <Box sx={{display: "flex", alignItems: "center"}}>
                <Typography variant="h3">
                    {userInfo?.name}
                </Typography>
            </Box>
        </Box>
    );
};

export default ChatTopBar;
