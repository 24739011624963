import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { CommonLoading } from 'react-loadingg';
import { Delete, Edit } from "@mui/icons-material";

import Header from "../../Components/Header/Header";
import ModalDelete from "../../Components/ModalDelete/ModalDelete";
import { Box, Button, ButtonGroup, Chip, Typography } from "@mui/material";
import ModalAddPreset from "./Components/ModalAddPreset/ModalAddPreset";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { DeletePreset, GetPresets } from "../../Services/Presets/index";

import PageWrapper from "../../Components/PageWrapper/PageWrapper";
import Feedback from '../../Components/Feedback/Feedback';

import "./Presets.scss";

const Preset = () => {
    const PUBLIC_PATH = process.env.REACT_APP_PUBLIC_URL;
    const consultant = JSON.parse(sessionStorage.getItem('consultant'));
    const navigate = useNavigate();

    const [presets, setPresets] = useState([]);
    const [modal, setModal] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [preset, setPreset] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    const getMaxPeriodizations = () => {
        if (!consultant.plan) {
            return 1;
        }

        if (consultant.plan.name === 'Basic') {
            return 3;
        } else if (consultant.plan.name === 'Pro') {
            return 15;
        }
    };

    const handleModal = () => {
        setModal(!modal);
    };

    const handleModalDelete = () => {
        setModalDelete(!modalDelete);
    };

    const handleSetPreset = (e, preset) => {
        e.stopPropagation();
        setPreset(preset);
        handleModalDelete();
    };

    const handleDeletePreset = async (id) => {
        try {
            await deletePreset(id)
            const newList = presets.filter(p => p.id !== id);
            setPresets(newList);
            
            // GTM - Delete Preset Success
            window.dataLayer.push({
                'event': 'delete_preset_success',
                'preset_id': id,
                'consultant_id': consultant.id
            });
        } catch (ex) {
            // GTM - Delete Preset Error
            window.dataLayer.push({
                'event': 'delete_preset_error',
                'preset_id': id,
                'error_message': ex?.error || 'Erro ao remover preset.',
                'consultant_id': consultant.id
            });
            
            setError(ex?.error || 'Erro ao remover preset.');
            setTimeout(() => {
                setError(undefined);
            }, 10000);
        }
        finally {
            handleModalDelete();
        }
    };

    const deletePreset = async (id) => {
        await DeletePreset(id);
    };

    const fetchData = useCallback(async () => {
        let presetList = await GetPresets(consultant.id);
        presetList = presetList?.data?.rows.map(preset => {
            return {
                id: preset.id,
                name: preset.name
            };
        });
        setPresets(presetList || []);
        
        // GTM - View Presets List
        window.dataLayer.push({
            'event': 'view_presets_list',
            'presets_count': presetList?.length || 0,
            'consultant_id': consultant.id
        });
        
        setLoading(false);
    }, [consultant.id]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div className="preset">
            <PageWrapper menuItem="/preset">
                <div className="content">
                    <Header
                        title="Gerenciar Periodizações"
                        subtitle="Veja as periodizações cadastradas abaixo"
                    />

                    {error && (<Feedback severity="error" text={error} />)}
                    {loading && (<CommonLoading color="#553872" size="large" />)}
                    {!loading && (
                        <div className="preset-container">
                            <Box sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', md: 'row' },
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: 3,
                                p: 2,
                                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)'
                            }}>
                                <Button
                                    className="new-student-btn"
                                    aria-label="Novo aluno"
                                    onClick={handleModal}
                                    startIcon={<AddCircleOutlineIcon />}
                                    sx={{
                                        backgroundColor: '#553872',
                                        color: 'white',
                                        boxShadow: '2px 4px 12px rgba(0, 0, 0, 0.2)',
                                        borderRadius: 2,
                                        width: 250,
                                        height: 40,
                                        ":hover": { backgroundColor: '#553872' }
                                    }}
                                >
                                    <Typography sx={{
                                        color: 'white',
                                        fontWeight: '700',
                                        fontSize: 14,
                                    }}>Nova periodização</Typography>
                                </Button>
                                <Chip
                                    label={<Typography>{`${presets.length}/${getMaxPeriodizations()} Periodizações`}</Typography>}
                                    color="primary"
                                    variant="outlined"
                                />
                                {presets.length === getMaxPeriodizations() && <Typography>Você chegou no limite de periodizações.
                                    <Link to="/upgrade" variant="body2">
                                        Clique aqui para atualizar seu plano.
                                    </Link>
                                </Typography>}
                            </Box>
                            {presets.length > 0 && (
                                presets.map((preset, key) => (
                                    <Box key={key}
                                        className={`preset-item ${key % 2 === 0 && 'background-gray'}`}
                                        sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 1 }}
                                        onClick={() => navigate(`/manage-preset/${preset.id}`, { state: { preset: preset } })}>
                                        <div>
                                            <p className="title">{preset.name}</p>
                                        </div>

                                        <div className="actions">
                                            <ButtonGroup variant="outlined" aria-label="Basic button group">
                                                <Button component="label" startIcon={<Edit />} size="small" onClick={() => navigate(`/manage-preset/${preset.id}`, { state: { preset: preset } })}>
                                                    Editar
                                                </Button>
                                                <Button component="label" onClick={(e) => handleSetPreset(e, preset)}
                                                    startIcon={<Delete />} size="small">
                                                    Apagar
                                                </Button>
                                            </ButtonGroup>
                                        </div>
                                    </Box>
                                ))
                            )}
                            {presets.length === 0 && (
                                <section className="empty-list">
                                    <div>
                                        <div className="images-container">
                                            <img src={PUBLIC_PATH + "group-top.svg"} alt="group-top" className="image-top" />
                                            <img src={PUBLIC_PATH + "group-quite.svg"} alt="group-quite" className="image-quite" />
                                            <img src={PUBLIC_PATH + "group-bottom.svg"} alt="group-bottom" className="image-bottom" />
                                        </div>
                                        <h4>Ainda não há nenhum periodização criado!</h4>
                                        <div>
                                            <p>Clique em criar nova periodização e configure a primeira</p>
                                            <p>periodização do seu aluno.</p>
                                        </div>
                                    </div>
                                </section>
                            )}
                        </div>
                    )}

                    <ModalAddPreset
                        handleModal={handleModal}
                        presets={presets}
                        setPresets={setPresets}
                        reloadPresets={fetchData}
                        open={modal}
                        setModal={setModal}
                    />
                    {modalDelete && (
                        <ModalDelete
                            handleModal={handleModalDelete}
                            title="Excluir periodização"
                            subtitle="Deseja excluir a periodização salva:"
                            text={`${preset.name} ?`}
                            id={preset.id}
                            handleDelete={handleDeletePreset}
                        />
                    )}
                </div>
            </PageWrapper>
        </div>
    );
}

export default Preset;