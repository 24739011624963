import {GetAsync} from '../api'

/**
 * Método responsável por respostas de um aluno
 */
const GetDashboardInfo = (token, id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const response = await GetAsync(`/consultant/${id}/dashboard`, config);
            resolve(response.data);
        } catch (err) {
            reject(err);
        }
    });
}

export {GetDashboardInfo};